import State from './State';
import {
  Action,
  SET_NAME,
  SET_CALCULATION_TITLE,
  SET_PROJECT_NUMBER,
  SET_DONE_BY,
  SET_APPROVED_BY,
  SET_PROJECT_DATE,
  RESET_STATE,
  SET_CALC_ID,
  SET_CHECKED_BY,
  SET_CREATED_BY,
} from './Actions';

const initialState: State = {
  projectId: '',
  calculationTitle: '',
  projectName: '',
  projectNo: '',
  projectDoneBy: '',
  projectCheckedBy: '',
  projectApprovedBy: '',
  createdBy: '',
  projectDate: new Date().toISOString().slice(0, 10),
  data: '',
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_CALC_ID: {
      return {
        ...state,
        projectId: action.payload,
      };
    }
    case SET_NAME: {
      return {
        ...state,
        projectName: action.payload,
      };
    }
    case SET_CALCULATION_TITLE: {
      return {
        ...state,
        calculationTitle: action.payload,
      };
    }
    case SET_PROJECT_NUMBER: {
      return {
        ...state,
        projectNo: action.payload,
      };
    }
    case SET_DONE_BY: {
      return {
        ...state,
        projectDoneBy: action.payload,
      };
    }
    case SET_CHECKED_BY: {
      return {
        ...state,
        projectCheckedBy: action.payload,
      };
    }
    case SET_APPROVED_BY: {
      return {
        ...state,
        projectApprovedBy: action.payload,
      };
    }
    case SET_PROJECT_DATE: {
      return {
        ...state,
        projectDate: action.payload,
      };
    }
    case SET_CREATED_BY: {
      return {
        ...state,
        createdBy: action.payload,
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
