import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getProjectDetails } from './Store/Selectors';
// import { getProject } from '../../Project/Current/Store/Selectors';
import Form from './Form';
import {
  SET_NAME,
  SET_CALCULATION_TITLE,
  SET_PROJECT_NUMBER,
  SET_DONE_BY,
  SET_CHECKED_BY,
  SET_APPROVED_BY,
  SET_PROJECT_DATE,
  RESET_STATE,
  fetchProjectDetails,
} from './Store/Actions';

const mapStateToProps = (state: RootState) => ({
  ...getProjectDetails(state),
  // projectId: getProject(state).projectId,
});

const mapDispatchToProps = {
  setName: (n: string) => ({ type: SET_NAME, payload: n }),
  setCalculationTitle: (t: string) => ({
    type: SET_CALCULATION_TITLE,
    payload: t,
  }),
  setProjectNumber: (n: string) => ({ type: SET_PROJECT_NUMBER, payload: n }),
  setDoneBy: (d: string) => ({ type: SET_DONE_BY, payload: d }),
  setCheckedBy: (d: string) => ({ type: SET_CHECKED_BY, payload: d }),
  setApprovedBy: (d: string) => ({ type: SET_APPROVED_BY, payload: d }),
  setProjectDate: (d: string) => ({ type: SET_PROJECT_DATE, payload: d }),
  resetReduxState: () => ({ type: RESET_STATE }),
  fetchProjectDetails: fetchProjectDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Form);
