import React, { useEffect } from 'react';
import { PropsFromRedux } from './FormContainer';
import { InputField, AccordianForm } from '../../Core';
import MiniAccordianForm from '../../Core/MiniAccordianForm';
import './Form.css';

const ColumnLoadsInput: React.FC<Props> = (PropsFromRedux: Props) => {
  useEffect(() => {
    const { fetchColumnLoads, resetReduxState } = PropsFromRedux;
    const previousProps = PropsFromRedux;
    //@ts-ignore
    const data = JSON.parse(localStorage.getItem('inputs'));
    if (data) {
      if (previousProps.caseOneName !== data.caseOneName) {
        fetchColumnLoads();
      } else {
        resetReduxState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = { ...PropsFromRedux };

  //New Code Below
  const handleCaseOneName = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneName(e.target.value);
  };

  const handleCaseOneAxial = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneAxial(e.target.value);
  };

  const handleCaseOneHx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneHx(e.target.value);
  };

  const handleCaseOneHy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneHy(e.target.value);
  };

  const handleCaseOneMx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneMx(e.target.value);
  };

  const handleCaseOneMy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneMy(e.target.value);
  };

  const handleCaseOneNone = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneType(e.target.value);
  };

  const handleCaseOnePermDead = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneType(e.target.value);
  };

  const handleCaseOneTransient = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseOneType(e.target.value);
  };

  const handleCaseTwoName = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoName(e.target.value);
  };

  const handleCaseTwoAxial = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoAxial(e.target.value);
  };

  const handleCaseTwoHx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoHx(e.target.value);
  };

  const handleCaseTwoHy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoHy(e.target.value);
  };

  const handleCaseTwoMx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoMx(e.target.value);
  };

  const handleCaseTwoMy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoMy(e.target.value);
  };

  const handleCaseTwoNone = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoType(e.target.value);
  };

  const handleCaseTwoPermDead = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoType(e.target.value);
  };

  const handleCaseTwoTransient = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseTwoType(e.target.value);
  };

  const handleCaseThreeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeName(e.target.value);
  };

  const handleCaseThreeAxial = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeAxial(e.target.value);
  };

  const handleCaseThreeHx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeHx(e.target.value);
  };

  const handleCaseThreeHy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeHy(e.target.value);
  };

  const handleCaseThreeMx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeMx(e.target.value);
  };

  const handleCaseThreeMy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeMy(e.target.value);
  };
  const handleCaseThreeNone = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeType(e.target.value);
  };

  const handleCaseThreePermDead = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeType(e.target.value);
  };

  const handleCaseThreeTransient = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseThreeType(e.target.value);
  };

  const handleCaseFourName = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourName(e.target.value);
  };

  const handleCaseFourAxial = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourAxial(e.target.value);
  };

  const handleCaseFourHx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourHx(e.target.value);
  };

  const handleCaseFourHy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourHy(e.target.value);
  };

  const handleCaseFourMx = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourMx(e.target.value);
  };

  const handleCaseFourMy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourMy(e.target.value);
  };
  const handleCaseFourNone = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourType(e.target.value);
  };

  const handleCaseFourPermDead = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourType(e.target.value);
  };

  const handleCaseFourTransient = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCaseFourType(e.target.value);
  };

  const handleDeleteCase1 = () => {
    props.setCaseOneName('Case 1');
    props.setCaseOneAxial('');
    props.setCaseOneHx('');
    props.setCaseOneHy('');
    props.setCaseOneMx('');
    props.setCaseOneMy('');
  };

  const handleDeleteCase2 = () => {
    props.setCaseTwoName('Case 2');
    props.setCaseTwoAxial('');
    props.setCaseTwoHx('');
    props.setCaseTwoHy('');
    props.setCaseTwoMx('');
    props.setCaseTwoMy('');
  };

  const handleDeleteCase3 = () => {
    props.setCaseThreeName('Case 3');
    props.setCaseThreeAxial('');
    props.setCaseThreeHx('');
    props.setCaseThreeHy('');
    props.setCaseThreeMx('');
    props.setCaseThreeMy('');
  };

  const handleDeleteCase4 = () => {
    props.setCaseThreeName('Case 4');
    props.setCaseFourAxial('');
    props.setCaseFourHx('');
    props.setCaseFourHy('');
    props.setCaseFourMx('');
    props.setCaseFourMy('');
  };

  return (
    <AccordianForm
      title="Column Loads"
      children={
        <React.Fragment>
          <MiniAccordianForm
            title={props.caseOneName ? props.caseOneName : 'Case 1'}
            children={
              <React.Fragment>
                <InputField
                  label="Load Name"
                  value={props.caseOneName}
                  onChange={handleCaseOneName}
                />
                <InputField
                  label="Axial Force (kN)"
                  value={props.caseOneAxial}
                  onChange={handleCaseOneAxial}
                />
                <InputField
                  label="Horizontal X (kN)"
                  value={props.caseOneHx}
                  onChange={handleCaseOneHx}
                />
                <InputField
                  label="Horizontal Y (kN)"
                  value={props.caseOneHy}
                  onChange={handleCaseOneHy}
                />
                <InputField
                  label="Moment X (kN)"
                  value={props.caseOneMx}
                  onChange={handleCaseOneMx}
                />
                <InputField
                  label="Moment Y (kN)"
                  value={props.caseOneMy}
                  onChange={handleCaseOneMy}
                />
                <div className="mt-4">
                  <label className="block mb-2">
                    None
                    <input
                      style={{ marginLeft: '130px' }}
                      type="radio"
                      value="None"
                      name="caseOne"
                      checked={props.caseOneType === 'None'}
                      onChange={handleCaseOneNone}
                    />
                  </label>
                  <label className="block mb-2">
                    Dead
                    <input
                      style={{ marginLeft: '130px' }}
                      className="ml-10"
                      type="radio"
                      value="Dead"
                      name="caseOne"
                      checked={props.caseOneType === 'Dead'}
                      onChange={handleCaseOnePermDead}
                    />
                  </label>
                  <label className="block mb-2">
                    Transient
                    <input
                      style={{ marginLeft: '100px' }}
                      className=""
                      type="radio"
                      value="Transient"
                      name="caseOne"
                      checked={props.caseOneType === 'Transient'}
                      onChange={handleCaseOneTransient}
                    />
                  </label>
                </div>

                <button
                  className="bg-gray-100 text-gray-600 p-2 w-48 rounded-full tracking-wide
            font-semibold focus:outline-none focus:shadow-outline mt-4 mb-4
            shadow-lg"
                  onClick={() => handleDeleteCase1()}
                >
                  Clear{' '}
                  {props.caseOneName
                    ? `${props.caseOneName} Load`
                    : 'Case 1 Load'}
                </button>
              </React.Fragment>
            }
          />
          <MiniAccordianForm
            title={props.caseTwoName ? props.caseTwoName : 'Case 2'}
            children={
              <React.Fragment>
                <InputField
                  label="Load Name"
                  value={props.caseTwoName}
                  onChange={handleCaseTwoName}
                />
                <InputField
                  label="Axial Force (kN)"
                  value={props.caseTwoAxial}
                  onChange={handleCaseTwoAxial}
                />
                <InputField
                  label="Horizontal X (kN)"
                  value={props.caseTwoHx}
                  onChange={handleCaseTwoHx}
                />
                <InputField
                  label="Horizontal Y (kN)"
                  value={props.caseTwoHy}
                  onChange={handleCaseTwoHy}
                />
                <InputField
                  label="Moment X (kN)"
                  value={props.caseTwoMx}
                  onChange={handleCaseTwoMx}
                />
                <InputField
                  label="Moment Y (kN)"
                  value={props.caseTwoMy}
                  onChange={handleCaseTwoMy}
                />
                <div className="mt-4">
                  <label className="block mb-2">
                    None
                    <input
                      style={{ marginLeft: '130px' }}
                      type="radio"
                      value="None"
                      name="caseTwo"
                      checked={props.caseTwoType === 'None'}
                      onChange={handleCaseTwoNone}
                    />
                  </label>
                  <label className="block mb-2">
                    Dead
                    <input
                      style={{ marginLeft: '130px' }}
                      className="ml-10"
                      type="radio"
                      value="Dead"
                      name="caseTwo"
                      checked={props.caseTwoType === 'Dead'}
                      onChange={handleCaseTwoPermDead}
                    />
                  </label>
                  <label className="block mb-2">
                    Transient
                    <input
                      style={{ marginLeft: '100px' }}
                      className=""
                      type="radio"
                      value="Transient"
                      name="caseTwo"
                      checked={props.caseTwoType === 'Transient'}
                      onChange={handleCaseTwoTransient}
                    />
                  </label>
                </div>
                <button
                  className="bg-gray-100 text-gray-600 p-2 w-48 rounded-full tracking-wide
            font-semibold focus:outline-none focus:shadow-outline mt-4 mb-4
            shadow-lg"
                  onClick={() => handleDeleteCase2()}
                >
                  Delete {props.caseTwoName ? props.caseTwoName : 'Case 2'}
                </button>
              </React.Fragment>
            }
          />

          <MiniAccordianForm
            title={props.caseThreeName ? props.caseThreeName : 'Case 3'}
            children={
              <React.Fragment>
                <InputField
                  label="Load Name"
                  value={props.caseThreeName}
                  onChange={handleCaseThreeName}
                />
                <InputField
                  label="Axial Force (kN)"
                  value={props.caseThreeAxial}
                  onChange={handleCaseThreeAxial}
                />
                <InputField
                  label="Horizontal X (kN)"
                  value={props.caseThreeHx}
                  onChange={handleCaseThreeHx}
                />
                <InputField
                  label="Horizontal Y (kN)"
                  value={props.caseThreeHy}
                  onChange={handleCaseThreeHy}
                />
                <InputField
                  label="Moment X (kN)"
                  value={props.caseThreeMx}
                  onChange={handleCaseThreeMx}
                />
                <InputField
                  label="Moment Y (kN)"
                  value={props.caseThreeMy}
                  onChange={handleCaseThreeMy}
                />
                <div className="mt-4">
                  <label className="block mb-2">
                    None
                    <input
                      style={{ marginLeft: '130px' }}
                      type="radio"
                      value="None"
                      name="caseThree"
                      checked={props.caseThreeType === 'None'}
                      onChange={handleCaseThreeNone}
                    />
                  </label>
                  <label className="block mb-2">
                    Dead
                    <input
                      style={{ marginLeft: '130px' }}
                      className="ml-10"
                      type="radio"
                      value="Dead"
                      name="caseThree"
                      checked={props.caseThreeType === 'Dead'}
                      onChange={handleCaseThreePermDead}
                    />
                  </label>
                  <label className="block mb-2">
                    Transient
                    <input
                      style={{ marginLeft: '100px' }}
                      className=""
                      type="radio"
                      value="Transient"
                      name="caseThree"
                      checked={props.caseThreeType === 'Transient'}
                      onChange={handleCaseThreeTransient}
                    />
                  </label>
                </div>
                <button
                  className="bg-gray-100 text-gray-600 p-2 w-48 rounded-full tracking-wide
            font-semibold focus:outline-none focus:shadow-outline mt-4 mb-4
            shadow-lg"
                  onClick={() => handleDeleteCase3()}
                >
                  Delete {props.caseThreeName ? props.caseThreeName : 'Case 3'}
                </button>
              </React.Fragment>
            }
          />

          <MiniAccordianForm
            title={props.caseFourName ? props.caseFourName : 'Case 4'}
            children={
              <React.Fragment>
                <InputField
                  label="Load Name"
                  value={props.caseFourName}
                  onChange={handleCaseFourName}
                />
                <InputField
                  label="Axial Force (kN)"
                  value={props.caseFourAxial}
                  onChange={handleCaseFourAxial}
                />
                <InputField
                  label="Horizontal X (kN)"
                  value={props.caseFourHx}
                  onChange={handleCaseFourHx}
                />
                <InputField
                  label="Horizontal Y (kN)"
                  value={props.caseFourHy}
                  onChange={handleCaseFourHy}
                />
                <InputField
                  label="Moment X (kN)"
                  value={props.caseFourMx}
                  onChange={handleCaseFourMx}
                />
                <InputField
                  label="Moment Y (kN)"
                  value={props.caseFourMy}
                  onChange={handleCaseFourMy}
                />
                <div className="mt-4">
                  <label className="block mb-2">
                    None
                    <input
                      style={{ marginLeft: '130px' }}
                      type="radio"
                      value="None"
                      name="caseFour"
                      checked={props.caseFourType === 'None'}
                      onChange={handleCaseFourNone}
                    />
                  </label>
                  <label className="block mb-2">
                    Dead
                    <input
                      style={{ marginLeft: '130px' }}
                      className="ml-10"
                      type="radio"
                      value="Dead"
                      name="caseFour"
                      checked={props.caseFourType === 'Dead'}
                      onChange={handleCaseFourPermDead}
                    />
                  </label>
                  <label className="block mb-2">
                    Transient
                    <input
                      style={{ marginLeft: '100px' }}
                      className=""
                      type="radio"
                      value="Transient"
                      name="caseFour"
                      checked={props.caseFourType === 'Transient'}
                      onChange={handleCaseFourTransient}
                    />
                  </label>
                </div>
                <button
                  className="bg-gray-100 text-gray-600 p-2 w-48 rounded-full tracking-wide
            font-semibold focus:outline-none focus:shadow-outline mt-4 mb-4
            shadow-lg"
                  onClick={() => handleDeleteCase4()}
                >
                  Delete {props.caseFourName ? props.caseFourName : 'Case 4'}
                </button>
              </React.Fragment>
            }
          />

          {/* <button
            className="bg-gray-100 text-gray-600 p-2 w-48 rounded-full tracking-wide
            font-semibold focus:outline-none focus:shadow-outline mt-4 mb-4
            shadow-lg"
            onClick={() => handleAddCase()}
          >
            Add Load Case
          </button> */}
        </React.Fragment>
      }
    />
  );
};

export interface Props extends PropsFromRedux {}

export interface State {}

export default ColumnLoadsInput;
