import React from 'react';
import Props from './Props';

const View: React.FC<Props> = (props: Props) => {
  return (
    <div className="pt-4">
      <table className="table-fixed w-full border-collapse mt-2">
        <thead>
          <tr className="border border-gray-300 ">
            <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
              Soil Properties
            </th>
            <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              Y<sub>soil</sub> - Soil Density
            </td>
            <td className="w-1/5 pl-2 p-1.5">
              {props.soilDensity} kN/m<sup>3</sup>
            </td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5"> φ - Soil Angle of Friction</td>
            <td className="w-1/5 pl-2 p-1.5">
              {props.angleoffriction} <sup>o</sup>
            </td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              P<sub>bearing</sub> - Allowable Bearing Pressure
            </td>
            <td className="w-1/5 pl-2 p-1.5">
              {props.allowableBP} kN/m<sup>2</sup>
            </td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              d<sub>found</sub> - Depth to Foundation
            </td>
            <td className="w-1/5 pl-2 p-1.5">
              {parseFloat(props.foundDepth) * 1000} mm
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default View;
