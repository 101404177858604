import { connect } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getLoadCases } from './Store/Selectors';
import View from './View';
import Props from './Props';
import { getColumnLoads } from '../ColumnLoads/Store/Selectors';

const mapStateToProps = (state: RootState, ownProps: Props): Props => {
  return {
    ...getColumnLoads(state),
    ...getLoadCases(state),
    ...ownProps,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(View);
