import { connect } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getDesignParams } from './Store/Selectors';
import Form, { Props } from './Form';
import { SET_YG, SET_YQ, TOGGLE_ACCORD } from './Store/Actions';

const mapStateToProps = (state: RootState, ownProps: Props): Props => {
  return {
    ...getDesignParams(state),
    ...ownProps,
  };
};

const mapDispatchToProps = {
  setYg: (n: string) => ({ type: SET_YG, payload: n }),
  toggleActive: () => ({ type: TOGGLE_ACCORD }),
  setYq: (t: string) => ({
    type: SET_YQ,
    payload: t,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
