import React from 'react';
import ResultsSummaryView from './ResultsSummaryView';
import PadPlanView from './PadPlanView';
import InputSummary from '../InputSummary';
import SlidingReport from './SlidingReport';
import UpliftReport from './UpliftReport';
import BearingReport from './BearingReport';

import {
  Pad_SW,
  Soil_SW,
  Eccentricity,
  Column_Axial_Load,
  Column_Horizontal_Load,
  Total_Axial_Load,
  Moments_About_Edge,
  Moments_From_Horizontal,
  Column_Load_Input,
  Total_Axial_Load_1,
  Moments_About_Edge2,
  Moments_From_Horizontal2,
  Eccentricity2,
} from '../Calculations/FundamentalCalcs';

import {
  Q1,
  Q2,
  Q3,
  Q4,
  Allowable_Bearing_Pressure,
  Overburden_Pressure,
  Bearing_LHS,
  Bearing_RHS,
  Q1_2,
  Q2_2,
  Q3_2,
  Q4_2,
} from '../Calculations/BearingPressureCalc';

import {
  kp,
  Hfs_A,
  Hfs_B,
  Hfs_,
  Friction,
  Axial_Force,
  Sliding_Resistance,
  Horizontal_Forces,
  Critical_Horizontal,
  Friction2,
} from '../Calculations/SlidingCalc';

import {
  UpliftCondition,
  Uplift_Calculation,
} from '../Calculations/UpliftCalc';

import {
  BearingSummary,
  Critical_Bearing,
  Critical_Bearing2,
  Critical_Sliding2,
  findCritical,
} from '../Calculations/SummaryCalsc';

import {
  CaseSolver,
  DeadSolver,
  nameSolver,
  TransientSolver,
} from '../Calculations/LoadCombinations';

const View = (props) => {
  const soilProperties = { ...props.soilProperties };
  const columnDetails = { ...props.columnDetails };
  const padDetails = { ...props.padDetails };
  const columnLoads = { ...props.columnLoads };
  const loadCases = { ...props.loadCases };

  const inputState = {
    ...soilProperties,
    ...columnDetails,
    ...padDetails,
    ...columnLoads,
    ...loadCases,
  };

  /* Load Cases */

  const case1 = {
    name: columnLoads.caseOneName,
    axial: columnLoads.caseOneAxial,
    hx: columnLoads.caseOneHx,
    hy: columnLoads.caseOneHy,
    mx: columnLoads.caseOneMx,
    my: columnLoads.caseOneMy,
    type: columnLoads.caseOneType,
  };

  const case2 = {
    name: columnLoads.caseTwoName,
    axial: columnLoads.caseTwoAxial,
    hx: columnLoads.caseTwoHx,
    hy: columnLoads.caseTwoHy,
    mx: columnLoads.caseTwoMx,
    my: columnLoads.caseTwoMy,
    type: columnLoads.caseTwoType,
  };

  const case3 = {
    name: columnLoads.caseThreeName,
    axial: columnLoads.caseThreeAxial,
    hx: columnLoads.caseThreeHx,
    hy: columnLoads.caseThreeHy,
    mx: columnLoads.caseThreeMx,
    my: columnLoads.caseThreeMy,
    type: columnLoads.caseThreeType,
  };

  const case4 = {
    name: columnLoads.caseFourName,
    axial: columnLoads.caseFourAxial,
    hx: columnLoads.caseFourHx,
    hy: columnLoads.caseFourHy,
    mx: columnLoads.caseFourMx,
    my: columnLoads.caseFourMy,
    type: columnLoads.caseFourType,
  };

  const CombinationOne = CaseSolver(
    loadCases.case1_a,
    loadCases.case1_b,
    loadCases.case1_c,
    loadCases.case1_d
  );

  const CombinationOneTransient = TransientSolver(
    CombinationOne,
    case1.type,
    case2.type,
    case3.type,
    case4.type
  );

  // const CombinationOneDead = DeadSolver(
  //   CombinationOne,
  //   case1.type,
  //   case2.type,
  //   case3.type,
  //   case4.type
  // );

  const CombinationTwo = CaseSolver(
    loadCases.case2_a,
    loadCases.case2_b,
    loadCases.case2_c,
    loadCases.case2_d
  );

  const CombinationTwoTransient = TransientSolver(
    CombinationTwo,
    case1.type,
    case2.type,
    case3.type,
    case4.type
  );

  const CombinationThree = CaseSolver(
    loadCases.case3_a,
    loadCases.case3_b,
    loadCases.case3_c,
    loadCases.case3_d
  );

  const CombinationThreeTransient = TransientSolver(
    CombinationThree,
    case1.type,
    case2.type,
    case3.type,
    case4.type
  );

  const CombinationFour = CaseSolver(
    loadCases.case4_a,
    loadCases.case4_b,
    loadCases.case4_c,
    loadCases.case4_d
  );

  const CombinationFourTransient = TransientSolver(
    CombinationFour,
    case1.type,
    case2.type,
    case3.type,
    case4.type
  );

  const CaseNameOne = nameSolver(
    loadCases.case1_a,
    loadCases.case1_b,
    loadCases.case1_c,
    loadCases.case1_d,
    case1.name,
    case2.name,
    case3.name,
    case4.name
  );

  const CaseNameTwo = nameSolver(
    loadCases.case2_a,
    loadCases.case2_b,
    loadCases.case2_c,
    loadCases.case2_d,
    case1.name,
    case2.name,
    case3.name,
    case4.name
  );

  const CaseNameThree = nameSolver(
    loadCases.case3_a,
    loadCases.case3_b,
    loadCases.case3_c,
    loadCases.case3_d,
    case1.name,
    case2.name,
    case3.name,
    case4.name
  );

  const CaseNameFour = nameSolver(
    loadCases.case4_a,
    loadCases.case4_b,
    loadCases.case4_c,
    loadCases.case4_d,
    case1.name,
    case2.name,
    case3.name,
    case4.name
  );

  /* 

  GENERAL CALCULATIONS

  */

  const bearingLengthLHS = Bearing_LHS(
    padDetails.padLength,
    columnDetails.eccX,
    columnDetails.baseplateLength,
    padDetails.padDepth
  );

  const bearingLengthRHS = Bearing_RHS(
    padDetails.padLength,
    columnDetails.eccX,
    columnDetails.baseplateLength,
    padDetails.padDepth
  );

  const bearingWidthLHS = Bearing_LHS(
    padDetails.padWidth,
    columnDetails.eccY,
    columnDetails.baseplateWidth,
    padDetails.padDepth
  );

  const bearingWidthRHS = Bearing_RHS(
    padDetails.padWidth,
    columnDetails.eccY,
    columnDetails.baseplateWidth,
    padDetails.padDepth
  );

  const padBearingLength =
    parseFloat(bearingLengthLHS) +
    parseFloat(bearingLengthRHS) +
    parseFloat(columnDetails.baseplateLength) / 1000;

  const padBearingWidth =
    parseFloat(bearingWidthLHS) +
    parseFloat(bearingWidthRHS) +
    parseFloat(columnDetails.baseplateWidth) / 1000;

  /* 

  BEARING PRESSURE CALCULATIONS

  */

  const overBurden = Overburden_Pressure(
    parseFloat(soilProperties.foundDepth),
    parseFloat(padDetails.padDepth),
    parseFloat(soilProperties.soilDensity)
  );

  const allowableBearingPermanent = Allowable_Bearing_Pressure(
    parseFloat(soilProperties.allowableBP),
    overBurden
  );

  const allowableBearingTemporary = Allowable_Bearing_Pressure(
    parseFloat(soilProperties.allowableBP) * 1.25,
    overBurden
  );

  const padSW = Pad_SW(
    padDetails.padLength,
    padDetails.padWidth,
    padDetails.padDepth,
    padDetails.concreteDensity
  );

  const soilSW = Soil_SW(
    padDetails.padLength,
    padDetails.padWidth,
    soilProperties.foundDepth,
    soilProperties.soilDensity
  );

  const case1Axial = Column_Load_Input(
    CombinationOne,
    case1.axial,
    case2.axial,
    case3.axial,
    case4.axial
  );
  const case2Axial = Column_Load_Input(
    CombinationTwo,
    case1.axial,
    case2.axial,
    case3.axial,
    case4.axial
  );

  const case3Axial = Column_Load_Input(
    CombinationThree,
    case1.axial,
    case2.axial,
    case3.axial,
    case4.axial
  );

  const case4Axial = Column_Load_Input(
    CombinationFour,
    case1.axial,
    case2.axial,
    case3.axial,
    case4.axial
  );

  //TO BE REMOVED
  const columnAxialLoad = Column_Axial_Load(
    parseFloat(columnLoads.deadLoad),
    parseFloat(columnLoads.superImposedDeadLoad),
    parseFloat(columnLoads.windLoad),
    parseFloat(columnLoads.imposedLoad),
    parseFloat(columnLoads.snowLoad),
    parseFloat(columnLoads.windUplift)
  );

  const case1hx = Column_Load_Input(
    CombinationOne,
    case1.hx,
    case2.hx,
    case3.hx,
    case4.hx
  );

  const case2hx = Column_Load_Input(
    CombinationTwo,
    case1.hx,
    case2.hx,
    case3.hx,
    case4.hx
  );

  const case3hx = Column_Load_Input(
    CombinationThree,
    case1.hx,
    case2.hx,
    case3.hx,
    case4.hx
  );

  const case4hx = Column_Load_Input(
    CombinationFour,
    case1.hx,
    case2.hx,
    case3.hx,
    case4.hx
  );

  const case1hy = Column_Load_Input(
    CombinationOne,
    case1.hy,
    case2.hy,
    case3.hy,
    case4.hy
  );

  const case2hy = Column_Load_Input(
    CombinationTwo,
    case1.hy,
    case2.hy,
    case3.hy,
    case4.hy
  );

  const case3hy = Column_Load_Input(
    CombinationThree,
    case1.hy,
    case2.hy,
    case3.hy,
    case4.hy
  );

  const case4hy = Column_Load_Input(
    CombinationFour,
    case1.hy,
    case2.hy,
    case3.hy,
    case4.hy
  );

  const case1mx = Column_Load_Input(
    CombinationOne,
    case1.mx,
    case2.mx,
    case3.mx,
    case4.mx
  );

  const case2mx = Column_Load_Input(
    CombinationTwo,
    case1.mx,
    case2.mx,
    case3.mx,
    case4.mx
  );

  const case3mx = Column_Load_Input(
    CombinationThree,
    case1.mx,
    case2.mx,
    case3.mx,
    case4.mx
  );

  const case4mx = Column_Load_Input(
    CombinationFour,
    case1.mx,
    case2.mx,
    case3.mx,
    case4.mx
  );

  const case1my = Column_Load_Input(
    CombinationOne,
    case1.my,
    case2.my,
    case3.my,
    case4.my
  );

  const case2my = Column_Load_Input(
    CombinationTwo,
    case1.my,
    case2.my,
    case3.my,
    case4.my
  );

  const case3my = Column_Load_Input(
    CombinationThree,
    case1.my,
    case2.my,
    case3.my,
    case4.my
  );

  const case4my = Column_Load_Input(
    CombinationFour,
    case1.my,
    case2.my,
    case3.my,
    case4.my
  );

  //TO BE REMOVED
  const columnHorizontalLoadHx = Column_Horizontal_Load(
    parseFloat(columnLoads.deadLoadHx),
    parseFloat(columnLoads.windLoadHx),
    parseFloat(columnLoads.imposedLoadHx),
    parseFloat(columnLoads.snowLoadHx)
  );

  //TO BE REMOVED
  const columnHorizontalLoadHy = Column_Horizontal_Load(
    parseFloat(columnLoads.deadLoadHy),
    parseFloat(columnLoads.windLoadHy),
    parseFloat(columnLoads.imposedLoadHy),
    parseFloat(columnLoads.snowLoadHy)
  );

  //TO BE REMOVED
  const totalAxialLoad = Total_Axial_Load(
    padSW,
    soilSW,
    columnAxialLoad.dead_imposed,
    columnAxialLoad.dead_snow,
    columnAxialLoad.dead_wind_imposed,
    columnAxialLoad.dead_wind_snow,
    columnAxialLoad.minDead_uplift
  );

  const case1totalAxial = Total_Axial_Load_1(padSW, soilSW, case1Axial);

  const case2totalAxial = Total_Axial_Load_1(padSW, soilSW, case2Axial);

  const case3totalAxial = Total_Axial_Load_1(padSW, soilSW, case3Axial);

  const case4totalAxial = Total_Axial_Load_1(padSW, soilSW, case4Axial);

  const mX_case1 = Moments_About_Edge2(
    case1mx,
    padSW,
    soilSW,
    padDetails.padLength,
    columnDetails.eccX,
    case1Axial
  );

  const mX_case2 = Moments_About_Edge2(
    case2mx,
    padSW,
    soilSW,
    padDetails.padLength,
    columnDetails.eccX,
    case2Axial
  );

  const mX_case3 = Moments_About_Edge2(
    case3mx,
    padSW,
    soilSW,
    padDetails.padLength,
    columnDetails.eccX,
    case3Axial
  );

  const mX_case4 = Moments_About_Edge2(
    case4mx,
    padSW,
    soilSW,
    padDetails.padLength,
    columnDetails.eccX,
    case4Axial
  );

  const mY_case1 = Moments_About_Edge2(
    case1my,
    padSW,
    soilSW,
    padDetails.padWidth,
    columnDetails.eccY,
    case1Axial
  );

  const mY_case2 = Moments_About_Edge2(
    case2my,
    padSW,
    soilSW,
    padDetails.padWidth,
    columnDetails.eccY,
    case2Axial
  );

  const mY_case3 = Moments_About_Edge2(
    case3my,
    padSW,
    soilSW,
    padDetails.padWidth,
    columnDetails.eccY,
    case3Axial
  );

  const mY_case4 = Moments_About_Edge2(
    case4my,
    padSW,
    soilSW,
    padDetails.padWidth,
    columnDetails.eccY,
    case4Axial
  );

  //TO BE REMOVED
  const mX = Moments_About_Edge(
    parseFloat(columnLoads.deadLoadMx),
    parseFloat(columnLoads.windLoadMx),
    parseFloat(columnLoads.imposedLoadMx),
    parseFloat(columnLoads.snowLoadMx),
    padSW,
    soilSW,
    padDetails.padLength,
    columnDetails.eccX,
    columnAxialLoad.dead_imposed,
    columnAxialLoad.dead_snow,
    columnAxialLoad.dead_wind_imposed,
    columnAxialLoad.dead_wind_snow,
    columnAxialLoad.minDead_uplift
  );

  //TO BE REMOVED
  const mY = Moments_About_Edge(
    parseFloat(columnLoads.deadLoadMy),
    parseFloat(columnLoads.windLoadMy),
    parseFloat(columnLoads.imposedLoadMy),
    parseFloat(columnLoads.snowLoadMy),
    padSW,
    soilSW,
    padDetails.padWidth,
    columnDetails.eccY,
    columnAxialLoad.dead_imposed,
    columnAxialLoad.dead_snow,
    columnAxialLoad.dead_wind_imposed,
    columnAxialLoad.dead_wind_snow,
    columnAxialLoad.minDead_uplift
  );

  const mxH_case1 = Moments_From_Horizontal2(case1hx, padDetails.padDepth);
  const mxH_case2 = Moments_From_Horizontal2(case2hx, padDetails.padDepth);
  const mxH_case3 = Moments_From_Horizontal2(case3hx, padDetails.padDepth);
  const mxH_case4 = Moments_From_Horizontal2(case4hx, padDetails.padDepth);

  const myH_case1 = Moments_From_Horizontal2(case1hy, padDetails.padDepth);
  const myH_case2 = Moments_From_Horizontal2(case2hy, padDetails.padDepth);
  const myH_case3 = Moments_From_Horizontal2(case3hy, padDetails.padDepth);
  const myH_case4 = Moments_From_Horizontal2(case4hy, padDetails.padDepth);

  //TO BE REMOVED
  const mxH = Moments_From_Horizontal(
    columnHorizontalLoadHx.dead_wind_imposed,
    padDetails.padDepth,
    columnHorizontalLoadHx.dead_wind_snow,
    columnHorizontalLoadHx.dead_imposed,
    columnHorizontalLoadHx.dead_snow,
    columnHorizontalLoadHx.minDead_uplift
  );

  //TO BE REMOVED
  const myH = Moments_From_Horizontal(
    columnHorizontalLoadHy.dead_wind_imposed,
    padDetails.padDepth,
    columnHorizontalLoadHy.dead_wind_snow,
    columnHorizontalLoadHy.dead_imposed,
    columnHorizontalLoadHy.dead_snow,
    columnHorizontalLoadHy.minDead_uplift
  );

  const ecx_case1 = Eccentricity2(
    padDetails.padLength,
    case1totalAxial,
    mX_case1,
    mxH_case1
  );

  const ecx_case2 = Eccentricity2(
    padDetails.padLength,
    case2totalAxial,
    mX_case2,
    mxH_case2
  );

  const ecx_case3 = Eccentricity2(
    padDetails.padLength,
    case3totalAxial,
    mX_case3,
    mxH_case3
  );

  const ecx_case4 = Eccentricity2(
    padDetails.padLength,
    case4totalAxial,
    mX_case4,
    mxH_case4
  );

  const ecy_case1 = Eccentricity2(
    padDetails.padWidth,
    case1totalAxial,
    mY_case1,
    myH_case1
  );

  const ecy_case2 = Eccentricity2(
    padDetails.padWidth,
    case2totalAxial,
    mY_case2,
    myH_case2
  );

  const ecy_case3 = Eccentricity2(
    padDetails.padWidth,
    case3totalAxial,
    mY_case3,
    myH_case3
  );

  const ecy_case4 = Eccentricity2(
    padDetails.padWidth,
    case4totalAxial,
    mY_case4,
    myH_case4
  );

  //TO BE REMOVED
  const ecX = Eccentricity(
    padDetails.padLength,
    totalAxialLoad.dead_imposed,
    totalAxialLoad.dead_snow,
    totalAxialLoad.dead_wind_imposed,
    totalAxialLoad.dead_wind_snow,
    totalAxialLoad.minDead_uplift,
    mX.dead_imposed,
    mX.dead_snow,
    mX.dead_wind_imposed,
    mX.dead_wind_snow,
    mX.minDead_uplift,
    mxH.dead_imposed,
    mxH.dead_snow,
    mxH.dead_wind_imposed,
    mxH.dead_wind_snow,
    mxH.minDead_uplift
  );

  //TO BE REMOVED
  const ecY = Eccentricity(
    padDetails.padWidth,
    totalAxialLoad.dead_imposed,
    totalAxialLoad.dead_snow,
    totalAxialLoad.dead_wind_imposed,
    totalAxialLoad.dead_wind_snow,
    totalAxialLoad.minDead_uplift,
    mY.dead_imposed,
    mY.dead_snow,
    mY.dead_wind_imposed,
    mY.dead_wind_snow,
    mY.minDead_uplift,
    myH.dead_imposed,
    myH.dead_snow,
    myH.dead_wind_imposed,
    myH.dead_wind_snow,
    myH.minDead_uplift
  );

  const q1_case1 = Q1_2(
    case1totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case1,
    ecy_case1,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q2_case1 = Q2_2(
    case1totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case1,
    ecy_case1,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q3_case1 = Q3_2(
    case1totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case1,
    ecy_case1,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q4_case1 = Q4_2(
    case1totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case1,
    ecy_case1,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q1_case2 = Q1_2(
    case2totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case2,
    ecy_case2,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q2_case2 = Q2_2(
    case2totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case2,
    ecy_case2,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q3_case2 = Q3_2(
    case2totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case2,
    ecy_case2,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q4_case2 = Q4_2(
    case2totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case2,
    ecy_case2,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q1_case3 = Q1_2(
    case3totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case3,
    ecy_case3,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q2_case3 = Q2_2(
    case3totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case3,
    ecy_case3,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q3_case3 = Q3_2(
    case3totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case3,
    ecy_case3,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q4_case3 = Q4_2(
    case3totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case3,
    ecy_case3,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q1_case4 = Q1_2(
    case4totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case4,
    ecy_case4,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q2_case4 = Q2_2(
    case4totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case4,
    ecy_case4,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q3_case4 = Q3_2(
    case4totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case4,
    ecy_case4,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const q4_case4 = Q4_2(
    case4totalAxial,
    padBearingLength,
    padBearingWidth,
    ecx_case4,
    ecy_case4,
    parseFloat(padDetails.deadSurcharge),
    parseFloat(padDetails.imposedSurcharge)
  );

  const bearingResultsCase1 = {
    q1: q1_case1,
    q2: q2_case1,
    q3: q3_case1,
    q4: q4_case1,
  };
  const bearingResultsCase2 = { q1_case2, q2_case2, q3_case2, q4_case2 };
  const bearingResultsCase3 = { q1_case3, q2_case3, q3_case3, q4_case3 };
  const bearingResultsCase4 = { q1_case4, q2_case4, q3_case4, q4_case4 };

  //TO BE DELETED
  const q1 = Q1(
    totalAxialLoad.dead_imposed,
    totalAxialLoad.dead_snow,
    totalAxialLoad.dead_wind_imposed,
    totalAxialLoad.dead_wind_snow,
    totalAxialLoad.minDead_uplift,
    padBearingLength,
    padBearingWidth,
    ecX.dead_imposed,
    ecY.dead_imposed,
    ecX.dead_snow,
    ecY.dead_snow,
    ecX.dead_wind_imposed,
    ecY.dead_wind_imposed,
    ecX.dead_wind_snow,
    ecY.dead_wind_snow,
    ecX.minDead_uplift,
    ecY.minDead_uplift
  );

  //TO BE DELETED
  const q2 = Q2(
    totalAxialLoad.dead_imposed,
    totalAxialLoad.dead_snow,
    totalAxialLoad.dead_wind_imposed,
    totalAxialLoad.dead_wind_snow,
    totalAxialLoad.minDead_uplift,
    padBearingLength,
    padBearingWidth,
    ecX.dead_imposed,
    ecY.dead_imposed,
    ecX.dead_snow,
    ecY.dead_snow,
    ecX.dead_wind_imposed,
    ecY.dead_wind_imposed,
    ecX.dead_wind_snow,
    ecY.dead_wind_snow,
    ecX.minDead_uplift,
    ecY.minDead_uplift
  );

  //TO BE DELETED
  const q3 = Q3(
    totalAxialLoad.dead_imposed,
    totalAxialLoad.dead_snow,
    totalAxialLoad.dead_wind_imposed,
    totalAxialLoad.dead_wind_snow,
    totalAxialLoad.minDead_uplift,
    padBearingLength,
    padBearingWidth,
    ecX.dead_imposed,
    ecY.dead_imposed,
    ecX.dead_snow,
    ecY.dead_snow,
    ecX.dead_wind_imposed,
    ecY.dead_wind_imposed,
    ecX.dead_wind_snow,
    ecY.dead_wind_snow,
    ecX.minDead_uplift,
    ecY.minDead_uplift
  );

  //TO BE DELETED
  const q4 = Q4(
    totalAxialLoad.dead_imposed,
    totalAxialLoad.dead_snow,
    totalAxialLoad.dead_wind_imposed,
    totalAxialLoad.dead_wind_snow,
    totalAxialLoad.minDead_uplift,
    padBearingLength,
    padBearingWidth,
    ecX.dead_imposed,
    ecY.dead_imposed,
    ecX.dead_snow,
    ecY.dead_snow,
    ecX.dead_wind_imposed,
    ecY.dead_wind_imposed,
    ecX.dead_wind_snow,
    ecY.dead_wind_snow,
    ecX.minDead_uplift,
    ecY.minDead_uplift
  );

  /* 

  SLIDING CALCULATIONS

  */

  const axialForces = Axial_Force(soilSW, padSW);

  //Design Approach 1 - Partial Factors A1

  const horizontalForces_X = Horizontal_Forces(
    parseFloat(columnLoads.deadLoadHx),
    parseFloat(columnLoads.windLoadHx),
    parseFloat(columnLoads.imposedLoadHx),
    parseFloat(columnLoads.snowLoadHx)
  );

  const horizontalForces_Y = Horizontal_Forces(
    parseFloat(columnLoads.deadLoadHy),
    parseFloat(columnLoads.windLoadHy),
    parseFloat(columnLoads.imposedLoadHy),
    parseFloat(columnLoads.snowLoadHy)
  );

  //Passive Resistance Checks
  const k = kp(soilProperties.angleoffriction);

  const hfs_1 = Hfs_A(k, soilProperties.soilDensity, soilProperties.foundDepth);
  const hfs_2 = Hfs_B(
    k,
    parseFloat(soilProperties.soilDensity),
    parseFloat(soilProperties.foundDepth),
    parseFloat(padDetails.padDepth)
  );

  const hf_x = Hfs_(
    hfs_1,
    hfs_2,
    parseFloat(padDetails.padDepth),
    padBearingWidth
  );
  const hf_y = Hfs_(
    hfs_1,
    hfs_2,
    parseFloat(padDetails.padDepth),
    padBearingLength
  );

  //TO BE DELETED
  const friction = Friction(
    parseFloat(soilProperties.angleoffriction),
    axialForces
  );

  const case1_friction = Friction2(
    parseFloat(soilProperties.angleoffriction),
    case1,
    padSW,
    soilSW
  );

  const case2_friction = Friction2(
    parseFloat(soilProperties.angleoffriction),
    case2,
    padSW,
    soilSW
  );

  const case3_friction = Friction2(
    parseFloat(soilProperties.angleoffriction),
    case3,
    padSW,
    soilSW
  );

  const case4_friction = Friction2(
    parseFloat(soilProperties.angleoffriction),
    case4,
    padSW,
    soilSW
  );

  console.log(case1_friction);
  console.log(case2_friction);

  const slidingResistanceX_case1 = Sliding_Resistance(case1_friction, hf_x);

  const slidingResistanceX_case2 = Sliding_Resistance(case2_friction, hf_x);

  const slidingResistanceX_case3 = Sliding_Resistance(case3_friction, hf_x);

  const slidingResistanceX_case4 = Sliding_Resistance(case4_friction, hf_x);

  const slidingResistanceY_case1 = Sliding_Resistance(case1_friction, hf_y);

  const slidingResistanceY_case2 = Sliding_Resistance(case2_friction, hf_y);

  const slidingResistanceY_case3 = Sliding_Resistance(case3_friction, hf_y);

  const slidingResistanceY_case4 = Sliding_Resistance(case4_friction, hf_y);

  //TO BE DELETED
  const slidingResistanceX = Sliding_Resistance(friction, hf_x);

  const slidingResistanceY = Sliding_Resistance(friction, hf_y);

  /* 

 UPLIFT CALCULATION

 */

  const upliftConditionCheck = UpliftCondition(
    case1Axial,
    CaseNameOne,
    case2Axial,
    CaseNameTwo,
    case3Axial,
    CaseNameThree,
    case4Axial,
    CaseNameFour
  );

  //NOTE - A FACTOR OF SAFETY OF 1.5 IS ADOPTED.

  const upliftResistance = Uplift_Calculation(
    padSW,
    soilSW,
    upliftConditionCheck.case,
    parseFloat(upliftConditionCheck.uplift)
  );

  /*

  CRITICAL VALUE CALCS

  */

  //to be deleted
  const dead_imposed_critical = BearingSummary(
    q1.dead_imposed,
    q2.dead_imposed,
    q3.dead_imposed,
    q4.dead_imposed,
    allowableBearingPermanent,
    'Dead + Imposed',
    ecX.dead_imposed,
    ecY.dead_imposed
  );

  //to be deleted
  const dead_snow_critical = BearingSummary(
    q1.dead_snow,
    q2.dead_snow,
    q3.dead_snow,
    q4.dead_snow,
    allowableBearingPermanent,
    'Dead + Snow',
    ecX.dead_snow,
    ecY.dead_snow
  );

  //to be deleted
  const dead_wind_imposed_critical = BearingSummary(
    q1.dead_wind_imposed,
    q2.dead_wind_imposed,
    q3.dead_wind_imposed,
    q4.dead_wind_imposed,
    allowableBearingTemporary,
    'Dead + Imposed + Wind',
    ecX.dead_wind_imposed,
    ecY.dead_wind_imposed
  );

  //to be deleted
  const minDead_uplift_critical = BearingSummary(
    q1.minDead_uplift,
    q2.minDead_uplift,
    q3.minDead_uplift,
    q4.minDead_uplift,
    allowableBearingTemporary,
    'Min Dead + Uplift',
    ecX.minDead_uplift,
    ecY.minDead_uplift
  );

  const critical = findCritical(
    dead_imposed_critical,
    dead_snow_critical,
    dead_wind_imposed_critical,
    minDead_uplift_critical
  );

  //to be deleted
  const criticalBearing = Critical_Bearing(
    q1.dead_imposed,
    q2.dead_imposed,
    q3.dead_imposed,
    q4.dead_imposed,
    q1.dead_snow,
    q2.dead_snow,
    q3.dead_snow,
    q4.dead_snow,
    q1.dead_wind_imposed,
    q2.dead_wind_imposed,
    q3.dead_wind_imposed,
    q4.dead_wind_imposed,
    q1.dead_wind_snow,
    q2.dead_wind_snow,
    q3.dead_wind_snow,
    q4.dead_wind_snow,
    q1.minDead_uplift,
    q2.minDead_uplift,
    q3.minDead_uplift,
    q4.minDead_uplift,
    ecX.dead_imposed,
    ecY.dead_imposed,
    ecX.dead_snow,
    ecY.dead_snow,
    ecX.dead_wind_imposed,
    ecY.dead_wind_imposed,
    ecX.dead_wind_snow,
    ecY.dead_wind_snow,
    ecX.minDead_uplift,
    ecY.minDead_uplift,
    allowableBearingPermanent,
    allowableBearingTemporary,
    padDetails.padLength,
    padDetails.padWidth,
    padDetails.padDepth
  );

  const criticalBearing_2 = Critical_Bearing2(
    q1_case1,
    q2_case1,
    q3_case1,
    q4_case1,
    q1_case2,
    q2_case2,
    q3_case2,
    q4_case2,
    q1_case3,
    q2_case3,
    q3_case3,
    q4_case3,
    q1_case4,
    q2_case4,
    q3_case4,
    q4_case4,
    ecx_case1,
    ecy_case1,
    ecx_case2,
    ecy_case2,
    ecx_case3,
    ecy_case3,
    ecx_case4,
    ecy_case4,
    allowableBearingPermanent,
    allowableBearingTemporary,
    padDetails.padLength,
    padDetails.padWidth,
    padDetails.padDepth,
    CaseNameOne,
    CaseNameTwo,
    CaseNameThree,
    CaseNameFour,
    CombinationOneTransient,
    CombinationTwoTransient,
    CombinationThreeTransient,
    CombinationFourTransient
  );

  const critSlidingX = Critical_Sliding2(
    case1hx,
    case2hx,
    case3hx,
    case4hx,
    slidingResistanceX_case1,
    slidingResistanceX_case2,
    slidingResistanceX_case3,
    slidingResistanceX_case4,
    CaseNameOne,
    CaseNameTwo,
    CaseNameThree,
    CaseNameFour
  );

  console.log('CRITCAL SLIDING X', critSlidingX);

  const critSlidingY = Critical_Sliding2(
    case1hy,
    case2hy,
    case3hy,
    case4hy,
    slidingResistanceY_case1,
    slidingResistanceY_case2,
    slidingResistanceY_case3,
    slidingResistanceY_case4,
    CaseNameOne,
    CaseNameTwo,
    CaseNameThree,
    CaseNameFour
  );

  console.log('CRITCAL SLIDING Y', critSlidingY);

  //to be deleted
  const criticalSliding_X = Critical_Horizontal(
    horizontalForces_X.dead_imposed_A1,
    horizontalForces_X.dead_snow_A1,
    horizontalForces_X.dead_wind_imposed_A1,
    horizontalForces_X.dead_wind_snow_A1,
    horizontalForces_X.dead_imposed_A2,
    horizontalForces_X.dead_snow_A2,
    horizontalForces_X.dead_wind_imposed_A2,
    horizontalForces_X.dead_wind_snow_A2,
    slidingResistanceX
  );

  //to be deleted
  const criticalSliding_Y = Critical_Horizontal(
    horizontalForces_Y.dead_imposed_A1,
    horizontalForces_Y.dead_snow_A1,
    horizontalForces_Y.dead_wind_imposed_A1,
    horizontalForces_Y.dead_wind_snow_A1,
    horizontalForces_Y.dead_imposed_A2,
    horizontalForces_Y.dead_snow_A2,
    horizontalForces_Y.dead_wind_imposed_A2,
    horizontalForces_Y.dead_wind_snow_A2,
    slidingResistanceY
  );

  /* 
  
  RESULTS SUMMARY

  */

  //Should be renamed to bearing - and only deal with the bearing results
  const Results = {
    ex: ecX,
    ey: ecY,
    exInput: columnDetails.eccX,
    eyInput: columnDetails.eccY,
    ecX: ecX,
    ecY: ecY,
    length: padBearingLength,
    width: padBearingWidth,
    // windUplift: columnLoads.imposedLoad,
    // UpliftResistance: upliftResistance,
    upliftResistance,
    criticalSliding_X,
    criticalSliding_Y,
    criticalBearing,
    criticalBearing_2,
    critical,
    critSlidingX,
    critSlidingY,
  };

  const BearingData = {
    case1totalAxial,
    case2totalAxial,
    case3totalAxial,
    case4totalAxial,
    case1hx,
    case2hx,
    case3hx,
    case4hx,
    case1hy,
    case2hy,
    case3hy,
    case4hy,
    padBearingLength,
    padBearingWidth,
    mX_case1,
    mX_case2,
    mX_case3,
    mX_case4,
    mY_case1,
    mY_case2,
    mY_case3,
    mY_case4,
    mxH_case1,
    mxH_case2,
    mxH_case3,
    mxH_case4,
    myH_case1,
    myH_case2,
    myH_case3,
    myH_case4,
    ecx_case1,
    ecx_case2,
    ecx_case3,
    ecx_case4,
    ecy_case1,
    ecy_case2,
    ecy_case3,
    ecy_case4,
    q1_case1,
    q2_case1,
    q3_case1,
    q4_case1,
    q1_case2,
    q2_case2,
    q3_case2,
    q4_case2,
    q1_case3,
    q2_case3,
    q3_case3,
    q4_case3,
    q1_case4,
    q2_case4,
    q3_case4,
    q4_case4,
    //OLD DETAILS
    totalAxialLoad,
    columnHorizontalLoadHx,
    columnHorizontalLoadHy,
    mX,
    mY,
    mxH,
    myH,
    ecX,
    ecY,
    q1,
    q2,
    q3,
    q4,
    CombinationOneTransient,
    CombinationTwoTransient,
    CombinationThreeTransient,
    CombinationFourTransient,
    allowableBearingPermanent,
    allowableBearingTemporary,
  };

  const SlidingData = {
    k,
    hfs_1,
    hfs_2,
    hf_x,
    hf_y,
    friction,
    horizontalForces_X,
    horizontalForces_Y,
    case1hx,
    case2hx,
    case3hx,
    case4hx,
    case1hy,
    case2hy,
    case3hy,
    case4hy,
  };

  const UpliftData = {
    upliftResistance,
  };

  return (
    <div>
      <div>
        <ResultsSummaryView Results={Results} />
        <PadPlanView
          input={inputState}
          Results={Results}
          case1={bearingResultsCase1}
          q2={bearingResultsCase2}
          q3={bearingResultsCase3}
          q4={bearingResultsCase4}
        />
        <InputSummary />
        <BearingReport
          Results={Results}
          bearingData={BearingData}
          columnDetails={columnDetails}
          padDetails={padDetails}
          columnLoads={columnLoads}
          loadCases={loadCases}
          slidingData={SlidingData}
          upliftData={UpliftData}
        />
        <SlidingReport
          Results={Results}
          bearingData={BearingData}
          columnDetails={columnDetails}
          columnLoads={columnLoads}
          loadCases={loadCases}
          padDetails={padDetails}
          slidingData={SlidingData}
          upliftData={UpliftData}
        />
        <UpliftReport
          Results={Results}
          bearingData={BearingData}
          columnDetails={columnDetails}
          padDetails={padDetails}
          slidingData={SlidingData}
          upliftData={UpliftData}
        />
      </div>
    </div>
  );
};

export default View;
