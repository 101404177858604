import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getSoilProperties } from './Store/Selectors';
// import { getProject } from '../../Project/Current/Store/Selectors';
import Form from './Form';
import {
  SET_SOIL_DENSITY,
  SET_ANGLE_OF_FRICTION,
  SET_ALLOWABLE_BP,
  SET_FOUND_DEPTH,
  RESET_STATE,
  fetchSoilProperties,
} from './Store/Actions';

const mapStateToProps = (state: RootState) => ({
  ...getSoilProperties(state),
  // projectId: getProject(state).projectId,
});

const mapDispatchToProps = {
  setSoilDensity: (n: string) => ({
    type: SET_SOIL_DENSITY,
    payload: n,
  }),
  setAngleOfFriction: (t: string) => ({
    type: SET_ANGLE_OF_FRICTION,
    payload: t,
  }),
  setAllowableBP: (t: string) => ({
    type: SET_ALLOWABLE_BP,
    payload: t,
  }),
  setFoundDepth: (t: string) => ({
    type: SET_FOUND_DEPTH,
    payload: t,
  }),
  resetReduxState: () => ({ type: RESET_STATE }),
  fetchSoilProperties: fetchSoilProperties,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Form);
