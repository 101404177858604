import React, { useState, useEffect } from 'react';

import {
  ContainerLength,
  ContainerWidth,
  svgPadding,
  PadRatio,
  svgSize,
  DistanceFromEdge,
  DistanceToLoad,
  CentroidLocation,
} from '../Calculations/GraphicsCalc';

const PadPlanView = (props) => {
  //Calculate the size of the current window for SVG parameters.
  const [width1, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const length = parseFloat(props.input.padLength);
  const width = parseFloat(props.input.padWidth);

  const bearingLength = parseFloat(props.Results.length);
  const bearingWidth = parseFloat(props.Results.width);

  const Pressures = {
    q1: props.Results.criticalBearing_2.q1,
    q2: props.Results.criticalBearing_2.q2,
    q3: props.Results.criticalBearing_2.q3,
    q4: props.Results.criticalBearing_2.q4,
    ex: props.Results.criticalBearing_2.ex,
    ey: props.Results.criticalBearing_2.ey,
  };

  const ecc = {
    ex: Pressures.ex,
    ey: Pressures.ey,
    exInput: props.Results.exInput / 1000,
    eyInput: props.Results.eyInput / 1000,
  };

  const svgParams = {
    containerLength: 0.875 * width1 > 500 ? 500 : 0.875 * width1,
    containerWidth: 0.875 * width1 > 500 ? 500 : 0.875 * width1,
    initLength: 0.375 * width1 > 300 ? 300 : 0.375 * width1,
    initWidth: 0.375 * width1 > 300 ? 300 : 0.375 * width1,
  };

  //SVG Parameters
  const ratio = PadRatio(length, width);
  const containerLength = ContainerLength(
    length,
    width,
    svgParams.initLength,
    ratio
  );
  const containerWidth = ContainerWidth(
    width,
    length,
    svgParams.initWidth,
    ratio
  );
  const svgLength = svgSize(length, containerLength, svgParams.initLength);
  const svgWidth = svgSize(width, containerWidth, svgParams.initWidth);
  const xPadding = svgPadding(svgParams.containerLength, svgLength);
  const yPadding = svgPadding(svgParams.containerWidth, svgWidth);

  //Plot Helpers
  const distanceFromEdgeX = DistanceFromEdge(ecc.ex, length, svgLength);
  const distanceFromEdgeY = DistanceFromEdge(ecc.ey, width, svgWidth);
  const loadPlacementX = DistanceToLoad(ecc.exInput, length, svgLength);
  const loadPlacementY = DistanceToLoad(ecc.eyInput, width, svgWidth);
  const centroidX = CentroidLocation(ecc.ex, length, svgLength);
  const centroidY = CentroidLocation(ecc.ey, width, svgWidth);

  //Helpers to show the pressure diagrams - maxQ (Max Pressure)
  const maxQ = Math.max(Pressures.q1, Pressures.q2, Pressures.q3, Pressures.q4);
  const RectHeight = 25;

  return maxQ ? (
    <>
      <div>
        {bearingLength >= length && bearingWidth >= width ? null : (
          <div className="mt-4 text-red-500 noprint spec">
            <h1 className="text-xl">
              Due to the geometry, the full extents of the pad foundation is not
              being utilised.
            </h1>
            <ul className="mt-2 text-xl">
              <li>Consider:</li>
              <li>a) Increasing the pad depth</li>
              <li>b) Using steel reinforcement</li>
            </ul>
            <p className="mt-2 text-lg">
              Note: This warning will not be printed
            </p>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexBasis: '1',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <svg
            width={svgParams.containerLength}
            height={svgParams.containerWidth}
          >
            <rect
              x={xPadding}
              y={yPadding}
              width={svgLength}
              height={svgWidth}
              style={{
                stroke: 'rgba(74, 85, 104, 1)',
                strokeWidth: '1',
                fill: '#f9fafb',
              }}
            />
            <line
              x1={svgParams.containerWidth / 2 + loadPlacementX - 15}
              y1={svgParams.containerWidth / 2 - loadPlacementY}
              x2={svgParams.containerWidth / 2 + loadPlacementX + 15}
              y2={svgParams.containerWidth / 2 - loadPlacementY}
              stroke="rgba(74, 85, 104, 1)"
            />
            <line
              x1={svgParams.containerLength / 2 + loadPlacementX}
              y1={svgParams.containerLength / 2 - loadPlacementY - 15}
              x2={svgParams.containerLength / 2 + loadPlacementX}
              y2={svgParams.containerLength / 2 - loadPlacementY + 15}
              stroke="rgba(74, 85, 104, 1)"
            />

            <polygon
              points={`${
                Pressures.q4 > 0 ? xPadding : xPadding + distanceFromEdgeX
              } ${yPadding - 15 - RectHeight * (Pressures.q4 / maxQ)}, ${
                Pressures.q1 > 0
                  ? svgLength + xPadding
                  : svgLength + xPadding + distanceFromEdgeX
              } ${yPadding - 15 - RectHeight * (Pressures.q1 / maxQ)}, ${
                Pressures.q1 > 0
                  ? svgLength + xPadding
                  : svgLength + xPadding + distanceFromEdgeX
              } ${yPadding - 15}, ${
                Pressures.q4 > 0 ? xPadding : xPadding + distanceFromEdgeX
              } ${yPadding - 15}`}
              style={{
                fill: '#f9fafb',
                stroke: 'rgba(74, 85, 104, 1)',
                strokeWidth: 1,
              }}
            />

            <polygon
              points={`${xPadding - 15} ${
                Pressures.q4 > 0 ? yPadding : yPadding + distanceFromEdgeY
              }, ${xPadding - 15 - RectHeight * (Pressures.q4 / maxQ)} ${
                Pressures.q4 > 0 ? yPadding : yPadding + distanceFromEdgeY
              }, ${xPadding - 15 - RectHeight * (Pressures.q3 / maxQ)} ${
                Pressures.q3 > 0
                  ? yPadding + svgWidth
                  : yPadding + svgWidth - distanceFromEdgeY
              }, ${xPadding - 15} ${
                Pressures.q3 > 0
                  ? yPadding + svgWidth
                  : yPadding + svgWidth - distanceFromEdgeY
              }`}
              style={{
                fill: '#f9fafb',
                stroke: 'rgba(74, 85, 104, 1)',
                strokeWidth: 1,
              }}
            />

            <text
              x={xPadding - 85}
              y={yPadding - 15}
              fill="rgba(74, 85, 104, 1)"
              style={{ fontSize: '12' }}
            >
              <tspan>
                {`${Pressures.q4.toFixed(2)}kN/m`}
                <tspan dy="-10" style={{ fontSize: '8' }}>
                  2
                </tspan>
              </tspan>
            </text>
            <text
              x={xPadding - 85}
              y={svgParams.containerWidth - yPadding + 15}
              fill="rgba(74, 85, 104, 1)"
              style={{ fontSize: '12' }}
            >
              <tspan>
                {`${Pressures.q3.toFixed(2)}kN/m`}
                <tspan dy="-10" style={{ fontSize: '8' }}>
                  2
                </tspan>
              </tspan>
            </text>

            <polygon
              points={` ${
                Pressures.q3 > 0 ? xPadding : xPadding + distanceFromEdgeX
              } ${yPadding + svgWidth + 15}, ${
                Pressures.q2 > 0
                  ? xPadding + svgLength
                  : xPadding + svgLength + distanceFromEdgeX
              } ${yPadding + svgWidth + 15}, ${
                Pressures.q2 > 0
                  ? xPadding + svgLength
                  : xPadding + svgLength + distanceFromEdgeX
              } ${
                yPadding + svgWidth + 15 + RectHeight * (Pressures.q2 / maxQ)
              } , ${
                Pressures.q3 > 0 ? xPadding : xPadding + distanceFromEdgeX
              } ${
                yPadding + svgWidth + 15 + RectHeight * (Pressures.q3 / maxQ)
              } `}
              style={{
                fill: '#f9fafb',
                stroke: 'rgba(74, 85, 104, 1)',
                strokeWidth: 1,
              }}
            />

            <polygon
              points={`${xPadding + svgLength + 15} ${
                Pressures.q1 > 0 ? yPadding : yPadding + distanceFromEdgeY
              }, ${
                xPadding + svgLength + 15 + RectHeight * (Pressures.q1 / maxQ)
              } ${
                Pressures.q1 > 0 ? yPadding : yPadding + distanceFromEdgeY
              }, ${
                xPadding + svgLength + 15 + RectHeight * (Pressures.q2 / maxQ)
              } ${
                Pressures.q2 > 0
                  ? yPadding + svgWidth
                  : yPadding + svgWidth - distanceFromEdgeY
              }, ${xPadding + svgLength + 15} ${
                Pressures.q2 > 0
                  ? yPadding + svgWidth
                  : yPadding + svgWidth - distanceFromEdgeY
              }`}
              style={{
                fill: '#f9fafb',
                stroke: 'rgba(74, 85, 104, 1)',
                strokeWidth: 1,
              }}
            />

            <text
              x={svgParams.containerLength - xPadding + 13}
              y={yPadding - 13}
              fill="rgba(74, 85, 104, 1)"
              style={{ fontSize: '12' }}
            >
              <tspan>
                {`${Pressures.q1.toFixed(2)}kN/m`}
                <tspan dy="-10" style={{ fontSize: '8' }}>
                  2
                </tspan>
              </tspan>
            </text>
            <text
              x={svgParams.containerLength - xPadding + 13}
              y={svgParams.containerWidth - yPadding + 13}
              fill="rgba(74, 85, 104, 1)"
              style={{ fontSize: '12' }}
            >
              <tspan>
                {`${Pressures.q2.toFixed(2)}kN/m`}
                <tspan dy="-10" style={{ fontSize: '8' }}>
                  2
                </tspan>
              </tspan>
            </text>

            <circle
              cx={`${svgParams.containerLength / 2 + centroidX}`}
              cy={`${svgParams.containerWidth / 2 - centroidY}`}
              r="5"
              fill="none"
              stroke="red"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
    </>
  ) : null;
};

export default PadPlanView;
