import React, { Component } from 'react';
import InputField from '../../Core/InputField';
import SharedProps from './Props';

export class ProjectDetailsInput extends Component<Props, State> {
  handleYg = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setYg(e.target.value);
  };

  handleYq = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setYq(e.target.value);
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  render() {
    const props = { ...this.props };
    return (
      <div className="ui styled fluid accordion">
        <div className="title active" onClick={props.toggleActive}>
          <i className="dropdown icon"></i>
          Design Params
        </div>
        <div
          className={`content + ${props.designParamsActive ? 'active' : "'"}`}
        >
          <form className="ui form" onSubmit={this.handleSubmit}>
            <div className="field">
              <InputField
                label="Dead Load Factor - Yg"
                value={props.Yg}
                onChange={this.handleYg}
              />
              <InputField
                label="Imposed Load Factor - Yq"
                value={props.Yq}
                onChange={this.handleYq}
              />
            </div>
            <button
              style={{ marginTop: '20px' }}
              className="ui button"
              type="submit"
              value="Submit"
              onClick={props.toggleActive}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export interface Props extends SharedProps {
  designParamsActive: boolean;
  setYg: (n: string) => void;
  toggleActive: () => void;
  setYq: (t: string) => void;
}

export interface State extends SharedProps {
  designParamsActive: boolean;
}

export default ProjectDetailsInput;
