import React from 'react';

const ResultsSummaryView: React.FC<Props> = (props: Props) => {
  const Results = {
    criticalBearing: props.Results.criticalBearing,
    criticalBearing2: props.Results.criticalBearing_2,
    windUplift: props.Results.windUplift,
    UpliftResistance: props.Results.upliftResistance,
    criticalSliding_X: props.Results.criticalSliding_X,
    criticalSliding_Y: props.Results.criticalSliding_Y,
    upliftResistance: props.Results.upliftResistance,
    critSlidingX: props.Results.critSlidingX,
    critSlidingY: props.Results.critSlidingY,
  };

  if (Results.criticalBearing === undefined) {
    Results.criticalBearing = 0;
  }

  return (
    <div style={{ marginLeft: '10px' }}>
      <h1 className="text-xl mt-4 mb-4 underline" style={{ fontSize: '' }}>
        Mass Concrete Foundation Design & Analysis (BS8004) - Presumed Bearing
        Resistance
      </h1>
      {Results.criticalBearing.maxBearing > 0 ? (
        <table className="table-fixed w-full border-collapse">
          <thead>
            <tr className="border border-gray-300">
              <th className="p-1.5 text-left w-2/10 bg-gray-100 pl-2">Check</th>
              <th className="p-1.5 text-left w-2/10 bg-gray-100 pl-2">
                Load Case
              </th>
              <th className="p-1.5 text-left w-2/10 bg-gray-100 pl-2">
                Design Value
              </th>
              <th className="p-1.5 text-left w-2/10 bg-gray-100 pl-2">
                Design Resistance
              </th>
              <th className="p-1.5 text-left w-2/10 bg-gray-100 pl-2">
                Unity Ratio
              </th>
            </tr>
          </thead>
          <tbody>
            {Results.criticalBearing2.criticalCase !== 0 ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Bearing</td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.criticalBearing2.criticalCase}
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {`${Results.criticalBearing2.maxBearing.toFixed(2)}`} kN/m
                  <sup>2</sup>
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {`${Results.criticalBearing2.allowableBP.toFixed(2)}`} kN/m
                  <sup>2</sup>
                </td>

                <td className="w-1/5 pl-3 p-1.5">
                  {Results.criticalBearing2.UnityRatio.toFixed(2)}
                  {Results.criticalBearing2.UnityRatio < 1
                    ? ' / OK'
                    : ' / FAIL'}
                </td>
              </tr>
            ) : null}

            {Results.critSlidingX.maxSliding > 0 ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Sliding - X Axis</td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.critSlidingX.criticalCase}
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.critSlidingX.maxSliding}
                  &nbsp;kN
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.critSlidingX.resistance
                    ? `${Results.critSlidingX.resistance.toFixed(2)} kN`
                    : 'Check Inputs'}{' '}
                </td>
                <td className="w-1/5 pl-3 p-1.5">
                  {Results.critSlidingX.resistance
                    ? Results.critSlidingX.maxSliding <
                      Results.critSlidingX.resistance
                      ? ` ${Results.critSlidingX.UnityRatio.toFixed(2)} / OK`
                      : ` ${Results.critSlidingX.UnityRatio.toFixed(2)} / FAIL`
                    : 'Check Inputs'}
                </td>
              </tr>
            ) : null}
            {Results.critSlidingY.maxSliding ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Sliding - Y Axis</td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.critSlidingY.criticalCase}
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.critSlidingY.maxSliding} kN
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.critSlidingY.resistance
                    ? `${Results.critSlidingY.resistance.toFixed(2)} kN`
                    : 'Check Inputs'}{' '}
                </td>
                <td className="w-1/5 pl-3 p-1.5">
                  {Results.critSlidingY.resistance
                    ? Results.critSlidingY.maxSliding <
                      Results.critSlidingY.resistance
                      ? ` ${Results.critSlidingY.UnityRatio.toFixed(2)} / OK`
                      : ` ${Results.critSlidingY.UnityRatio.toFixed(2)} / FAIL`
                    : 'Check Inputs'}
                </td>
              </tr>
            ) : null}
            {Results.upliftResistance.case !== 0 ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Uplift</td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.UpliftResistance.case}
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.upliftResistance.uplift} kN
                </td>
                <td className="w-1/5 pl-2 p-1.5">
                  {Results.upliftResistance.upliftResistance.toFixed(2)} kN
                </td>
                <td className="w-1/5 pl-3 p-1.5">
                  {Results.upliftResistance.upliftResistance >
                  Results.UpliftResistance.uplift
                    ? ` ${Results.upliftResistance.unitRatio.toFixed(2)} / OK`
                    : ` ${Results.upliftResistance.unitRatio.toFixed(
                        2
                      )} / FAIL`}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

interface Props {
  Results: Results;
}

interface Results {
  critSlidingX: any;
  critSlidingY: any;
  SlidingCombinations_X: any;
  criticalSliding_X: any;
  criticalSliding_Y: any;
  SlidingCombinations_Y: any;
  upliftResistance: any;
  ecX: any;
  ecY: any;
  ex: any;
  ey: any;
  criticalBearing: any;
  criticalBearing_2: any;
  windUplift: any;
  critical: any;
}

export default ResultsSummaryView;
