import React from 'react';
import { UpwardAccordian, DownwardAccordian } from '.';

const MiniAccordianFormComponent: React.FC<Props> = (props: Props) => {
  const [active, setActive] = React.useState(false);
  const toggleActive = () => setActive(!active);

  return (
    <div className=" w-54 mt-4">
      <div className="">
        <DownwardAccordian onClick={toggleActive} label={props.title} />
        {!active ? (
          ''
        ) : (
          <React.Fragment>
            {props.children}
            {props.onSubmit ? (
              <button
                className="mt-10 cursor-pointer border-0 inline-block"
                type="submit"
                value="Submit"
                onClick={() => {
                  toggleActive();
                  props.onSubmit?.();
                }}
              >
                Submit
              </button>
            ) : (
              <div className="">
                <UpwardAccordian onClick={toggleActive} label="Hide" />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export interface Props {
  title: string;
  children: React.ReactNode;
  onSubmit?: () => void;
}

export default MiniAccordianFormComponent;
