import { Dispatch } from 'redux';

//new Code here
export const SET_CASE_ONE_NAME =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_ONE_NAME';
export const SET_CASE_ONE_AXIAL =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_ONE_AXIAL';
export const SET_CASE_ONE_HX = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_ONE_HX';
export const SET_CASE_ONE_HY = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_ONE_HY';
export const SET_CASE_ONE_MX = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_ONE_MX';
export const SET_CASE_ONE_MY = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_ONE_MY';
export const SET_CASE_ONE_TYPE =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_ONE_TYPE';
export const SET_CASE_TWO_NAME =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_TWO_NAME';
export const SET_CASE_TWO_AXIAL =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_TWO_AXIAL';
export const SET_CASE_TWO_HX = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_TWO_HX';
export const SET_CASE_TWO_HY = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_TWO_HY';
export const SET_CASE_TWO_MX = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_TWO_MX';
export const SET_CASE_TWO_MY = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_TWO_MY';
export const SET_CASE_TWO_TYPE =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_TWO_TYPE';
export const SET_CASE_THREE_NAME =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_THREE_NAME';
export const SET_CASE_THREE_AXIAL =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_THREE_AXIAL';
export const SET_CASE_THREE_HX =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_THREE_HX';
export const SET_CASE_THREE_HY =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_THREE_HY';
export const SET_CASE_THREE_MX =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_THREE_MX';
export const SET_CASE_THREE_MY =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_THREE_MY';
export const SET_CASE_THREE_TYPE =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_THREE_TYPE';

export const SET_CASE_FOUR_NAME =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_FOUR_NAME';
export const SET_CASE_FOUR_AXIAL =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_FOUR_AXIAL';
export const SET_CASE_FOUR_HX = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_FOUR_HX';
export const SET_CASE_FOUR_HY = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_FOUR_HY';
export const SET_CASE_FOUR_MX = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_FOUR_MX';
export const SET_CASE_FOUR_MY = 'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_FOUR_MY';
export const SET_CASE_FOUR_TYPE =
  'CURRENT_PROJECT/COLUMN_LOADS/SET_CASE_FOUR_TYPE';

export const RESET_STATE = 'CURRENT_PROJECT/COLUMN_LOADS/RESET_STATE';
export const FETCH = 'CURRENT_PROJECT/COLUMN_LOADS/FETCH';

interface SetCaseOneNameAction {
  type: typeof SET_CASE_ONE_NAME;
  payload: string;
}
interface SetCaseOneAxialAction {
  type: typeof SET_CASE_ONE_AXIAL;
  payload: string;
}
interface SetCaseOneHxAction {
  type: typeof SET_CASE_ONE_HX;
  payload: string;
}
interface SetCaseOneHyAction {
  type: typeof SET_CASE_ONE_HY;
  payload: string;
}
interface SetCaseOneMxAction {
  type: typeof SET_CASE_ONE_MX;
  payload: string;
}
interface SetCaseOneMyAction {
  type: typeof SET_CASE_ONE_MY;
  payload: string;
}
interface SetCaseOneTypeAction {
  type: typeof SET_CASE_ONE_TYPE;
  payload: string;
}

interface SetCaseTwoNameAction {
  type: typeof SET_CASE_TWO_NAME;
  payload: string;
}
interface SetCaseTwoAxialAction {
  type: typeof SET_CASE_TWO_AXIAL;
  payload: string;
}
interface SetCaseTwoHxAction {
  type: typeof SET_CASE_TWO_HX;
  payload: string;
}
interface SetCaseTwoHyAction {
  type: typeof SET_CASE_TWO_HY;
  payload: string;
}
interface SetCaseTwoMxAction {
  type: typeof SET_CASE_TWO_MX;
  payload: string;
}
interface SetCaseTwoMyAction {
  type: typeof SET_CASE_TWO_MY;
  payload: string;
}
interface SetCaseTwoTypeAction {
  type: typeof SET_CASE_TWO_TYPE;
  payload: string;
}

interface SetCaseThreeNameAction {
  type: typeof SET_CASE_THREE_NAME;
  payload: string;
}
interface SetCaseThreeAxialAction {
  type: typeof SET_CASE_THREE_AXIAL;
  payload: string;
}
interface SetCaseThreeHxAction {
  type: typeof SET_CASE_THREE_HX;
  payload: string;
}
interface SetCaseThreeHyAction {
  type: typeof SET_CASE_THREE_HY;
  payload: string;
}
interface SetCaseThreeMxAction {
  type: typeof SET_CASE_THREE_MX;
  payload: string;
}
interface SetCaseThreeMyAction {
  type: typeof SET_CASE_THREE_MY;
  payload: string;
}
interface SetCaseThreeTypeAction {
  type: typeof SET_CASE_THREE_TYPE;
  payload: string;
}

interface SetCaseFourNameAction {
  type: typeof SET_CASE_FOUR_NAME;
  payload: string;
}
interface SetCaseFourAxialAction {
  type: typeof SET_CASE_FOUR_AXIAL;
  payload: string;
}
interface SetCaseFourHxAction {
  type: typeof SET_CASE_FOUR_HX;
  payload: string;
}
interface SetCaseFourHyAction {
  type: typeof SET_CASE_FOUR_HY;
  payload: string;
}
interface SetCaseFourMxAction {
  type: typeof SET_CASE_FOUR_MX;
  payload: string;
}
interface SetCaseFourMyAction {
  type: typeof SET_CASE_FOUR_MY;
  payload: string;
}
interface SetCaseFourTypeAction {
  type: typeof SET_CASE_FOUR_TYPE;
  payload: string;
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

export const fetchColumnLoads = () => {
  return (dispatch: Dispatch) => {
    //@ts-ignore
    const json = JSON.parse(localStorage.getItem('inputs'));
    //@ts-ignore
    //New Code Here
    dispatch({
      type: SET_CASE_ONE_NAME,
      payload: json.caseOneName ?? '',
    });
    dispatch({
      type: SET_CASE_ONE_AXIAL,
      payload: json.caseOneAxial ?? '',
    });
    dispatch({
      type: SET_CASE_ONE_HX,
      payload: json.caseOneHx ?? '',
    });
    dispatch({
      type: SET_CASE_ONE_HY,
      payload: json.caseOneHy ?? '',
    });
    dispatch({
      type: SET_CASE_ONE_MX,
      payload: json.caseOneMx ?? '',
    });
    dispatch({
      type: SET_CASE_ONE_MY,
      payload: json.caseOneMy ?? '',
    });
    dispatch({
      type: SET_CASE_ONE_TYPE,
      payload: json.caseOneType ?? '',
    });

    dispatch({
      type: SET_CASE_TWO_NAME,
      payload: json.caseTwoName ?? '',
    });
    dispatch({
      type: SET_CASE_TWO_AXIAL,
      payload: json.caseTwoAxial ?? '',
    });
    dispatch({
      type: SET_CASE_TWO_HX,
      payload: json.caseTwoHx ?? '',
    });
    dispatch({
      type: SET_CASE_TWO_HY,
      payload: json.caseTwoHy ?? '',
    });
    dispatch({
      type: SET_CASE_TWO_MX,
      payload: json.caseTwoMx ?? '',
    });
    dispatch({
      type: SET_CASE_TWO_MY,
      payload: json.caseTwoMy ?? '',
    });
    dispatch({
      type: SET_CASE_TWO_TYPE,
      payload: json.caseTwoType ?? '',
    });
    dispatch({
      type: SET_CASE_THREE_NAME,
      payload: json.caseThreeName ?? '',
    });
    dispatch({
      type: SET_CASE_THREE_AXIAL,
      payload: json.caseThreeAxial ?? '',
    });
    dispatch({
      type: SET_CASE_THREE_HX,
      payload: json.caseThreeHx ?? '',
    });
    dispatch({
      type: SET_CASE_THREE_HY,
      payload: json.caseThreeHy ?? '',
    });
    dispatch({
      type: SET_CASE_THREE_MX,
      payload: json.caseThreeMx ?? '',
    });
    dispatch({
      type: SET_CASE_THREE_MY,
      payload: json.caseThreeMy ?? '',
    });
    dispatch({
      type: SET_CASE_THREE_TYPE,
      payload: json.caseThreeType ?? '',
    });
    dispatch({
      type: SET_CASE_FOUR_NAME,
      payload: json.caseFourName ?? '',
    });
    dispatch({
      type: SET_CASE_FOUR_AXIAL,
      payload: json.caseFourAxial ?? '',
    });
    dispatch({
      type: SET_CASE_FOUR_HX,
      payload: json.caseFourHx ?? '',
    });
    dispatch({
      type: SET_CASE_FOUR_HY,
      payload: json.caseFourHy ?? '',
    });
    dispatch({
      type: SET_CASE_FOUR_MX,
      payload: json.caseFourMx ?? '',
    });
    dispatch({
      type: SET_CASE_FOUR_MY,
      payload: json.caseFourMy ?? '',
    });
    dispatch({
      type: SET_CASE_FOUR_TYPE,
      payload: json.caseFourType ?? '',
    });

    return json;
  };
};

export type Action =
  | SetCaseOneNameAction
  | SetCaseOneAxialAction
  | SetCaseOneHxAction
  | SetCaseOneHyAction
  | SetCaseOneMxAction
  | SetCaseOneMyAction
  | SetCaseOneTypeAction
  | SetCaseTwoNameAction
  | SetCaseTwoAxialAction
  | SetCaseTwoHxAction
  | SetCaseTwoHyAction
  | SetCaseTwoMxAction
  | SetCaseTwoMyAction
  | SetCaseTwoTypeAction
  | SetCaseThreeNameAction
  | SetCaseThreeAxialAction
  | SetCaseThreeHxAction
  | SetCaseThreeHyAction
  | SetCaseThreeMxAction
  | SetCaseThreeMyAction
  | SetCaseThreeTypeAction
  | SetCaseFourNameAction
  | SetCaseFourAxialAction
  | SetCaseFourHxAction
  | SetCaseFourHyAction
  | SetCaseFourMxAction
  | SetCaseFourMyAction
  | SetCaseFourTypeAction
  | ResetStateAction;
