import React, { useEffect } from 'react';
import { PropsFromRedux } from './FormContainer';
import { InputField, AccordianForm } from '../../Core';

const SoilPropertiesInput: React.FC<Props> = (PropsFromRedux: Props) => {
  // const [change, setChange] = useState(false);

  useEffect(() => {
    const { fetchSoilProperties, resetReduxState } = PropsFromRedux;
    const previousProps = PropsFromRedux;
    //@ts-ignore
    const data = JSON.parse(localStorage.getItem('inputs'));
    if (data) {
      if (previousProps.soilDensity !== data.soilDensity) {
        fetchSoilProperties();
        // setChange(!change);
      } else {
        resetReduxState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = { ...PropsFromRedux };

  const handleSoilDensityInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSoilDensity(e.target.value);
  };

  const handleAngleOfFictionInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setAngleOfFriction(e.target.value);
  };

  const handleAllowableBPInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setAllowableBP(e.target.value);
  };

  const handleFoundDepthInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setFoundDepth(e.target.value);
  };

  // useEffect(() => {}, [[change]]);

  return (
    <AccordianForm
      title="Soil Properties"
      children={
        <React.Fragment>
          <InputField
            label="Soil Density"
            value={props.soilDensity}
            onChange={handleSoilDensityInput}
          />
          <InputField
            label="Angle of Friction"
            value={props.angleoffriction}
            onChange={handleAngleOfFictionInput}
          />
          <InputField
            label="Allowable Bearing Pressure"
            value={props.allowableBP}
            onChange={handleAllowableBPInput}
          />
          <InputField
            label="Depth to Foundation"
            value={props.foundDepth}
            onChange={handleFoundDepthInput}
          />
        </React.Fragment>
      }
    />
  );
};

export interface Props extends PropsFromRedux {}

export interface State {}

export default SoilPropertiesInput;
