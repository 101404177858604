import State from './State';
import { Action, SET_YG, TOGGLE_ACCORD, SET_YQ } from './Actions';

const initialState: State = {
  Yg: '1.35',
  Yq: '1.5',
  designParamsActive: false,
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_YG: {
      return {
        ...state,
        Yg: action.payload,
      };
    }
    case TOGGLE_ACCORD: {
      return {
        ...state,
        designParamsActive: !state.designParamsActive,
      };
    }
    case SET_YQ: {
      return {
        ...state,
        Yq: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
