import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AccordianForm } from '../Core';
import Modal from './modal';
import './View.css';

const View = (props) => {
  const soilProperties = { ...props.soilProperties };
  const ColumnProperties = { ...props.columnDetails };
  const padDetails = { ...props.padDetails };
  const columnLoads = { ...props.columnLoads };
  const projectDetails = { ...props.projectDetails };
  const loadCombinations = { ...props.loadCases };

  const requestBody = JSON.stringify({
    //Project Details
    calculation_title: projectDetails.calculationTitle,
    project_name: projectDetails.projectName,
    project_number: projectDetails.projectNo,
    done_by: projectDetails.projectDoneBy,
    checked_by: projectDetails.projectCheckedBy,
    approved_by: projectDetails.projectApprovedBy,
    project_date: projectDetails.projectDate,
    //Soil Properties
    soil_density: soilProperties.soilDensity,
    angleoffriction: soilProperties.angleoffriction,
    allowable_bearing: soilProperties.allowableBP,
    found_depth: soilProperties.foundDepth,
    //Pad Properties
    concrete_density: padDetails.concreteDensity,
    pad_length: padDetails.padLength,
    pad_width: padDetails.padWidth,
    pad_depth: padDetails.padDepth,
    dead_surcharge: padDetails.deadSurcharge,
    imposed_surcharge: padDetails.imposedSurcharge,
    //Column Properties
    baseplate_length: ColumnProperties.baseplateLength,
    baseplate_width: ColumnProperties.baseplateWidth,
    ecc_x: ColumnProperties.eccX,
    ecc_y: ColumnProperties.eccY,
    //Column Loads
    dead_load: columnLoads.deadLoad,
    superimposed_dead_load: columnLoads.superImposedDeadLoad,
    imposed_load: columnLoads.imposedLoad,
    snow_load: columnLoads.snowLoad,
    wind_load: columnLoads.windLoad,
    wind_uplift: columnLoads.windUplift,
    dead_load_hx: columnLoads.deadLoadHx,
    imposed_load_hx: columnLoads.imposedLoadHx,
    snow_load_hx: columnLoads.snowLoadHx,
    wind_load_hx: columnLoads.windLoadHx,
    dead_load_hy: columnLoads.deadLoadHy,
    imposed_load_hy: columnLoads.imposedLoadHy,
    snow_load_hy: columnLoads.snowLoadHy,
    wind_load_hy: columnLoads.windLoadHy,
    dead_load_mx: columnLoads.deadLoadMx,
    imposed_load_mx: columnLoads.imposedLoadMx,
    snow_load_mx: columnLoads.snowLoadMx,
    wind_load_mx: columnLoads.windLoadMx,
    dead_load_my: columnLoads.deadLoadMy,
    imposed_load_my: columnLoads.imposedLoadMy,
    snow_load_my: columnLoads.snowLoadMy,
    wind_load_my: columnLoads.windLoadMy,
    //New Ones Here
    caseOneName: columnLoads.caseOneName,
    caseOneAxial: columnLoads.caseOneAxial,
    caseOneHx: columnLoads.caseOneHx,
    caseOneHy: columnLoads.caseOneHy,
    caseOneMx: columnLoads.caseOneMx,
    caseOneMy: columnLoads.caseOneMy,
    caseOneType: columnLoads.caseOneType,
    caseTwoName: columnLoads.caseTwoName,
    caseTwoAxial: columnLoads.caseTwoAxial,
    caseTwoHx: columnLoads.caseTwoHx,
    caseTwoHy: columnLoads.caseTwoHy,
    caseTwoMx: columnLoads.caseTwoMx,
    caseTwoMy: columnLoads.caseTwoMy,
    caseTwoType: columnLoads.caseTwoType,
    caseThreeName: columnLoads.caseThreeName,
    caseThreeAxial: columnLoads.caseThreeAxial,
    caseThreeHx: columnLoads.caseThreeHx,
    caseThreeHy: columnLoads.caseThreeHy,
    caseThreeMx: columnLoads.caseThreeMx,
    caseThreeMy: columnLoads.caseThreeMy,
    caseThreeType: columnLoads.caseThreeType,
    caseFourName: columnLoads.caseFourName,
    caseFourAxial: columnLoads.caseFourAxial,
    caseFourHx: columnLoads.caseFourHx,
    caseFourHy: columnLoads.caseFourHy,
    caseFourMx: columnLoads.caseFourMx,
    caseFourMy: columnLoads.caseFourMy,
    caseFourType: columnLoads.caseFourType,

    //Load Combinations
    case1_a: loadCombinations.case1_a,
    case1_b: loadCombinations.case1_b,
    case1_c: loadCombinations.case1_c,
    case1_d: loadCombinations.case1_d,
    case2_a: loadCombinations.case2_a,
    case2_b: loadCombinations.case2_b,
    case2_c: loadCombinations.case2_c,
    case2_d: loadCombinations.case2_d,
    case3_a: loadCombinations.case3_a,
    case3_b: loadCombinations.case3_b,
    case3_c: loadCombinations.case3_c,
    case3_d: loadCombinations.case3_d,
    case4_a: loadCombinations.case4_a,
    case4_b: loadCombinations.case4_b,
    case4_c: loadCombinations.case4_c,
    case4_d: loadCombinations.case4_d,
  });

  const [show, setShow] = useState();

  const showModal = (e) => {
    setShow(!show);
  };

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      requestBody
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${props.projectDetails.projectNo} - ${props.projectDetails.calculationTitle}.sub`;

    link.click();
  };

  const [file, setFile] = useState();

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    window.location.reload();

    if (file) {
      fileReader.onload = function (event) {
        const output = event.target.result;
        localStorage.setItem('inputs', output);
      };

      fileReader.readAsText(file);
    }
  };

  document.title = props.projectDetails.projectNo
    ? `${props.projectDetails.projectNo} - ${props.projectDetails.calculationTitle}`
    : 'SubSolve - Foundation Solutions';

  const printCalculation = (e) => {
    e.preventDefault();
    window.print();
  };

  const newProject = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <div className="noprint">
        <div className="mt-4 ml-4 mb-4 text-xl">Import / Export</div>
        <AccordianForm
          title="File Management"
          children={
            <>
              <div className="mt-4">
                <Link
                  to="#"
                  onClick={newProject}
                  className="mt-4 hover:text-green-400"
                >
                  New Calculation
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  onClick={exportData}
                  className="mt-4 hover:text-green-400"
                >
                  Save
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  children="Print"
                  onClick={printCalculation}
                  className="mt-4 hover:text-green-400"
                />
              </div>
              <div>
                <button
                  className="hover:text-green-400"
                  onClick={(e) => {
                    showModal(e);
                  }}
                >
                  Open File
                </button>

                <Modal onClose={showModal} show={show}>
                  <input
                    type="file"
                    accept={'.sub'}
                    onChange={handleOnChange}
                    className="text-xl mt-4 hover:text-green-400"
                  />
                  {file ? (
                    <>
                      <div className="file-custom"></div>
                      <Link
                        to="#"
                        type="button"
                        className="text-xl mt-4 hover:text-green-400"
                        onClick={(e) => {
                          handleOnSubmit(e);
                        }}
                      >
                        <div>Open</div>
                      </Link>
                    </>
                  ) : (
                    ''
                  )}
                </Modal>
              </div>
            </>
          }
        />
      </div>
    </div>
  );
};

export default View;
