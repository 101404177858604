import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getColumnLoads } from './Store/Selectors';
// import { getProject } from '../../Project/Current/Store/Selectors';
import Form from './Form';
import {
  RESET_STATE,
  SET_CASE_ONE_NAME,
  SET_CASE_ONE_AXIAL,
  SET_CASE_ONE_HX,
  SET_CASE_ONE_HY,
  SET_CASE_ONE_MX,
  SET_CASE_ONE_MY,
  SET_CASE_ONE_TYPE,
  SET_CASE_TWO_NAME,
  SET_CASE_TWO_AXIAL,
  SET_CASE_TWO_HX,
  SET_CASE_TWO_HY,
  SET_CASE_TWO_MX,
  SET_CASE_TWO_MY,
  SET_CASE_TWO_TYPE,
  SET_CASE_THREE_NAME,
  SET_CASE_THREE_AXIAL,
  SET_CASE_THREE_HX,
  SET_CASE_THREE_HY,
  SET_CASE_THREE_MX,
  SET_CASE_THREE_MY,
  SET_CASE_THREE_TYPE,
  SET_CASE_FOUR_NAME,
  SET_CASE_FOUR_AXIAL,
  SET_CASE_FOUR_HX,
  SET_CASE_FOUR_HY,
  SET_CASE_FOUR_MX,
  SET_CASE_FOUR_MY,
  SET_CASE_FOUR_TYPE,
  fetchColumnLoads,
} from './Store/Actions';

const mapStateToProps = (state: RootState) => ({
  ...getColumnLoads(state),
  // projectId: getProject(state).projectId,
});

const mapDispatchToProps = {
  setCaseOneName: (q: string) => ({ type: SET_CASE_ONE_NAME, payload: q }),
  setCaseOneAxial: (q: string) => ({ type: SET_CASE_ONE_AXIAL, payload: q }),
  setCaseOneHx: (q: string) => ({ type: SET_CASE_ONE_HX, payload: q }),
  setCaseOneHy: (q: string) => ({ type: SET_CASE_ONE_HY, payload: q }),
  setCaseOneMx: (q: string) => ({ type: SET_CASE_ONE_MX, payload: q }),
  setCaseOneMy: (q: string) => ({ type: SET_CASE_ONE_MY, payload: q }),
  setCaseOneType: (q: string) => ({
    type: SET_CASE_ONE_TYPE,
    payload: q,
  }),

  setCaseTwoName: (q: string) => ({ type: SET_CASE_TWO_NAME, payload: q }),
  setCaseTwoAxial: (q: string) => ({ type: SET_CASE_TWO_AXIAL, payload: q }),
  setCaseTwoHx: (q: string) => ({ type: SET_CASE_TWO_HX, payload: q }),
  setCaseTwoHy: (q: string) => ({ type: SET_CASE_TWO_HY, payload: q }),
  setCaseTwoMx: (q: string) => ({ type: SET_CASE_TWO_MX, payload: q }),
  setCaseTwoMy: (q: string) => ({ type: SET_CASE_TWO_MY, payload: q }),
  setCaseTwoType: (q: string) => ({
    type: SET_CASE_TWO_TYPE,
    payload: q,
  }),

  setCaseThreeName: (q: string) => ({ type: SET_CASE_THREE_NAME, payload: q }),
  setCaseThreeAxial: (q: string) => ({
    type: SET_CASE_THREE_AXIAL,
    payload: q,
  }),
  setCaseThreeHx: (q: string) => ({ type: SET_CASE_THREE_HX, payload: q }),
  setCaseThreeHy: (q: string) => ({ type: SET_CASE_THREE_HY, payload: q }),
  setCaseThreeMx: (q: string) => ({ type: SET_CASE_THREE_MX, payload: q }),
  setCaseThreeMy: (q: string) => ({ type: SET_CASE_THREE_MY, payload: q }),
  setCaseThreeType: (q: string) => ({
    type: SET_CASE_THREE_TYPE,
    payload: q,
  }),

  setCaseFourName: (q: string) => ({ type: SET_CASE_FOUR_NAME, payload: q }),
  setCaseFourAxial: (q: string) => ({
    type: SET_CASE_FOUR_AXIAL,
    payload: q,
  }),
  setCaseFourHx: (q: string) => ({ type: SET_CASE_FOUR_HX, payload: q }),
  setCaseFourHy: (q: string) => ({ type: SET_CASE_FOUR_HY, payload: q }),
  setCaseFourMx: (q: string) => ({ type: SET_CASE_FOUR_MX, payload: q }),
  setCaseFourMy: (q: string) => ({ type: SET_CASE_FOUR_MY, payload: q }),
  setCaseFourType: (q: string) => ({
    type: SET_CASE_FOUR_TYPE,
    payload: q,
  }),

  resetReduxState: () => ({ type: RESET_STATE }),
  fetchColumnLoads: fetchColumnLoads,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Form);
