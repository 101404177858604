import React from 'react';
import Props from './Store/State';
import './View.css';

const View: React.FC<Props> = (props: Props) => {
  return (
    <div style={{ marginLeft: '10px' }}>
      <div className="pt-2"></div>
      <table className="tg w-full border-collapse mt-4 border border-gray-300">
        <thead>
          <tr className="border border-gray-300">
            <td className="tg-0pky" colSpan={6}>
              <b className="pl-2">Project: </b> &nbsp; {props.projectName}
            </td>
            <td className="tg-0pky" colSpan={2}>
              <b className="pl-2">Job No.: </b>
              &nbsp; {props.projectNo}
            </td>
            <td className="tg-0pky" colSpan={4}>
              <b className="pl-2">Date:</b>
              &nbsp; {props.projectDate.slice(2)}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tg-0pky" colSpan={8}>
              <b className="pl-2">Calculation Title: </b> &nbsp;
              {props.calculationTitle}
            </td>
            <td className="tg-0pky" colSpan={4}>
              <b className="pl-2">Rev: </b> &nbsp; 01
            </td>
          </tr>
          <tr>
            <td className="tg-0pky" colSpan={4}>
              <b className="pl-2">Calcs by: </b> &nbsp; {props.projectDoneBy}
            </td>
            <td className="tg-0pky" colSpan={4}>
              <b className="pl-2">Checked by: </b> &nbsp;{' '}
              {props.projectCheckedBy}
            </td>
            <td className="tg-0pky" colSpan={4}>
              <b className="pl-2">Approved by: </b> &nbsp;{' '}
              {props.projectApprovedBy}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default View;
