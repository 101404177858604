import React from 'react';

const ReportDetails: React.FC<Props> = (props: Props) => {
  return (
    <React.Fragment>
      {props.displayCheck ? (
        <tr>
          <td style={{ verticalAlign: 'baseline' }}>{props.calcName}</td>
          <td>
            {props.formula}
            <span className="fw-800 underline">{props.answer}</span>
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  );
};

export interface Props {
  displayCheck: string;
  calcName: string;
  formula: string;
  answer: string;
}

export default ReportDetails;
