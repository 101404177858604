import { Dispatch } from 'redux';

export const SET_BP_LENGTH = 'CURRENT_PROJECT/COLUMN_DETAILS/SET_BP_LENGTH';
export const SET_BP_WIDTH = 'CURRENT_PROJECT/COLUMN_DETAILS/SET_BP_WIDTH';
export const SET_ECCX = 'CURRENT_PROJECT/COLUMN_DETAILS/SET_ECCX';
export const SET_ECCY = 'CURRENT_PROJECT/COLUMN_DETAILS/SET_ECCY';
export const TOGGLE_ACCORD = 'CURRENT_PROJECT/COLUMN_DETAILS/TOGGLE_ACCORD';
export const RESET_STATE = 'CURRENT_PROJECT/COLUMN_DETAILS/RESET_STATE';
export const FETCH = 'CURRENT_PROJECT/COLUMN_DETAILS/FETCH';

interface SetBpLength {
  type: typeof SET_BP_LENGTH;
  payload: string;
}
interface SetBpWidth {
  type: typeof SET_BP_WIDTH;
  payload: string;
}
interface SetEccX {
  type: typeof SET_ECCX;
  payload: string;
}
interface SetEccY {
  type: typeof SET_ECCY;
  payload: string;
}
interface ToggleActiveAction {
  type: typeof TOGGLE_ACCORD;
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

export const fetchColumnProperties = () => {
  return (dispatch: Dispatch) => {
    //@ts-ignore
    const json = JSON.parse(localStorage.getItem('inputs'));
    //@ts-ignore
    dispatch({ type: SET_BP_LENGTH, payload: json.baseplate_length ?? '' });
    dispatch({
      type: SET_BP_WIDTH,
      payload: json.baseplate_width ?? '',
    });
    dispatch({
      type: SET_ECCX,
      payload: json.ecc_x ?? '',
    });
    dispatch({ type: SET_ECCY, payload: json.ecc_y ?? '' });
    return json;
  };
};

export type Action =
  | SetBpLength
  | SetBpWidth
  | SetEccX
  | SetEccY
  | ToggleActiveAction
  | ResetStateAction;
