import React from 'react';
import './ReportDetails.css';

const ReportTable: React.FC<Props> = (props: Props) => {
  return (
    <table className="table-setup">
      <tbody>
        <React.Fragment>{props.children}</React.Fragment>
      </tbody>
    </table>
  );
};

export interface Props {
  children: React.ReactNode;
}

export default ReportTable;
