export const PadRatio = (length, width) => {
  if (length > width) {
    return length / width;
  } else {
    return width / length;
  }
};

export const ContainerLength = (length, width, initLength, ratio) => {
  if (length > width) {
    return initLength;
  } else {
    return initLength / ratio;
  }
};

export const ContainerWidth = (width, length, initWidth, ratio) => {
  if (width > length) {
    return initWidth;
  } else {
    return initWidth / ratio;
  }
};

export const svgSize = (param, containerSize, initSize) => {
  if (param) {
    return containerSize;
  } else {
    return initSize;
  }
};

export const svgPadding = (containerSize, svgSize) => {
  return (containerSize - svgSize) / 2;
};

export const DistanceFromEdge = (ecc, length, svgSize) => {
  return (ecc / length) * svgSize;
};

export const DistanceToLoad = (ecc, orientation, svgSize) => {
  return (ecc / orientation) * svgSize;
};

export const CentroidLocation = (ecc, orientation, svgSize) => {
  return (ecc / orientation) * svgSize;
};

//NEED TO FIGURE HOW TO FIND THE MAX
export const FindMaxBearing = (
  q1a,
  q2a,
  q3a,
  q4a,
  q1b,
  q2b,
  q3b,
  q4b,
  exa,
  eya,
  exb,
  eyb,
  allowableBP
) => {
  const case_one = Math.max(q1a, q2a, q3a, q4a);
  const case_two = Math.max(q1b, q2b, q3b, q4b);
  if (case_one > case_two) {
    return {
      q1: q1a > 0 ? q1a : 0,
      q2: q2a > 0 ? q2a : 0,
      q3: q3a > 0 ? q3a : 0,
      q4: q4a > 0 ? q4a : 0,
      ex: exa,
      ey: eya,
      allowableBP: allowableBP,
    };
  } else {
    return {
      q1: q1b > 0 ? q1b : 0,
      q2: q2b > 0 ? q2b : 0,
      q3: q3b > 0 ? q3b : 0,
      q4: q4b > 0 ? q4b : 0,
      ex: exb,
      ey: eyb,
      allowableBP: allowableBP,
    };
  }
};

export const CriticalBearingCase = (
  windCaseUR,
  NonWindCaseUR,
  calculatedBearingWind,
  calculatedBearingNonWind,
  allowableWindBearing,
  allowableNonWindBearing
) => {
  if (windCaseUR > NonWindCaseUR) {
    return {
      worstCase: 'Wind Case',
      calculatedBearing: calculatedBearingWind,
      UR: windCaseUR,
      AllowableBP: allowableWindBearing,
    };
  } else {
    return {
      worstCase: 'Non Wind Case',
      calculatedBearing: calculatedBearingNonWind,
      UR: NonWindCaseUR,
      AllowableBP: allowableNonWindBearing,
    };
  }
};
