//Calculate Bearing Lengths
export const Bearing_LHS = (padDim, ecc, baseplateDim, depth) => {
  const x = padDim / 2 + ecc / 1000 - baseplateDim / 1000 / 2;
  if (x < depth) {
    return x;
  } else {
    return depth;
  }
};

export const Bearing_RHS = (padDim, ecc, baseplateDim, depth) => {
  const x = padDim / 2 - ecc / 1000 - baseplateDim / 1000 / 2;
  if (x < depth) {
    return x;
  } else {
    return depth;
  }
};

export const Q1_2 = (
  totalAxialLoad,
  length,
  width,
  Ecx,
  Ecy,
  deadSurcharge,
  imposedSurcharge
) => {
  const ans =
    totalAxialLoad / (length * width) +
    (totalAxialLoad * Ecx) / ((length * length * width) / 6) +
    (totalAxialLoad * Ecy) / ((length * width * width) / 6) +
    (deadSurcharge ? deadSurcharge : 0) +
    (imposedSurcharge ? imposedSurcharge : 0);

  return ans;
};

export const Q2_2 = (
  totalAxialLoad,
  length,
  width,
  Ecx,
  Ecy,
  deadSurcharge,
  imposedSurcharge
) => {
  const ans =
    totalAxialLoad / (length * width) +
    (totalAxialLoad * Ecx) / ((length * length * width) / 6) -
    (totalAxialLoad * Ecy) / ((length * width * width) / 6) +
    (deadSurcharge ? deadSurcharge : 0) +
    (imposedSurcharge ? imposedSurcharge : 0);
  return ans;
};

export const Q3_2 = (
  totalAxialLoad,
  length,
  width,
  Ecx,
  Ecy,
  deadSurcharge,
  imposedSurcharge
) => {
  const ans =
    totalAxialLoad / (length * width) -
    (totalAxialLoad * Ecx) / ((length * length * width) / 6) -
    (totalAxialLoad * Ecy) / ((length * width * width) / 6) +
    (deadSurcharge ? deadSurcharge : 0) +
    (imposedSurcharge ? imposedSurcharge : 0);
  return ans;
};

export const Q4_2 = (
  totalAxialLoad,
  length,
  width,
  Ecx,
  Ecy,
  deadSurcharge,
  imposedSurcharge
) => {
  const ans =
    totalAxialLoad / (length * width) -
    (totalAxialLoad * Ecx) / ((length * length * width) / 6) +
    (totalAxialLoad * Ecy) / ((length * width * width) / 6) +
    (deadSurcharge ? deadSurcharge : 0) +
    (imposedSurcharge ? imposedSurcharge : 0);
  return ans;
};

//NEED TO ADD A CHECK FOR OUTSIDE THE MIDDLE THIRD AND THEN USE THE DIFFERENT BEARING PRESSURE CALC FOR IT
export const Q1 = (
  T_dead_imposed,
  T_dead_snow,
  T_dead_wind_imposed,
  T_dead_wind_snow,
  T_minDead_uplift,
  length,
  width,
  Ecx_dead_imposed,
  Ecy_dead_imposed,
  Ecx_dead_snow,
  Ecy_dead_snow,
  Ecx_dead_wind_imposed,
  Ecy_dead_wind_imposed,
  Ecx_dead_wind_snow,
  Ecy_dead_wind_snow,
  Ecx_minDead_uplift,
  Ecy_minDead_uplift
) => {
  //dead + imposed
  const dead_imposed =
    T_dead_imposed / (length * width) +
    (T_dead_imposed * Ecx_dead_imposed) / ((length * length * width) / 6) +
    (T_dead_imposed * Ecy_dead_imposed) / ((length * width * width) / 6);

  //dead + snow
  const dead_snow =
    T_dead_snow / (length * width) +
    (T_dead_snow * Ecx_dead_snow) / ((length * length * width) / 6) +
    (T_dead_snow * Ecy_dead_snow) / ((length * width * width) / 6);
  //dead + wind + imposed
  const dead_wind_imposed =
    T_dead_wind_imposed / (length * width) +
    (T_dead_wind_imposed * Ecx_dead_wind_imposed) /
      ((length * length * width) / 6) +
    (T_dead_wind_imposed * Ecy_dead_wind_imposed) /
      ((length * width * width) / 6);

  //dead + wind + snow
  const dead_wind_snow =
    T_dead_wind_snow / (length * width) +
    (T_dead_wind_snow * Ecx_dead_wind_snow) / ((length * length * width) / 6) +
    (T_dead_wind_snow * Ecy_dead_wind_snow) / ((length * width * width) / 6);

  const minDead_uplift =
    T_minDead_uplift / (length * width) +
    (T_minDead_uplift * Ecx_minDead_uplift) / ((length * length * width) / 6) +
    (T_minDead_uplift * Ecy_minDead_uplift) / ((length * width * width) / 6);

  return {
    dead_imposed: dead_imposed > 0 ? dead_imposed : 0,
    dead_snow: dead_snow > 0 ? dead_snow : 0,
    dead_wind_imposed: dead_wind_imposed > 0 ? dead_wind_imposed : 0,
    dead_wind_snow: dead_wind_snow > 0 ? dead_wind_snow : 0,
    minDead_uplift: minDead_uplift > 0 ? minDead_uplift : 0,
  };
};

export const Q2 = (
  T_dead_imposed,
  T_dead_snow,
  T_dead_wind_imposed,
  T_dead_wind_snow,
  T_minDead_uplift,
  length,
  width,
  Ecx_dead_imposed,
  Ecy_dead_imposed,
  Ecx_dead_snow,
  Ecy_dead_snow,
  Ecx_dead_wind_imposed,
  Ecy_dead_wind_imposed,
  Ecx_dead_wind_snow,
  Ecy_dead_wind_snow,
  Ecx_minDead_uplift,
  Ecy_minDead_uplift
) => {
  //dead  imposed
  const dead_imposed =
    T_dead_imposed / (length * width) +
    (T_dead_imposed * Ecx_dead_imposed) / ((length * length * width) / 6) -
    (T_dead_imposed * Ecy_dead_imposed) / ((length * width * width) / 6);
  //dead + snow
  const dead_snow =
    T_dead_snow / (length * width) +
    (T_dead_snow * Ecx_dead_snow) / ((length * length * width) / 6) -
    (T_dead_snow * Ecy_dead_snow) / ((length * width * width) / 6);
  //dead + wind + imposed
  const dead_wind_imposed =
    T_dead_wind_imposed / (length * width) +
    (T_dead_wind_imposed * Ecx_dead_wind_imposed) /
      ((length * length * width) / 6) -
    (T_dead_wind_imposed * Ecy_dead_wind_imposed) /
      ((length * width * width) / 6);
  //dead + wind + snow
  const dead_wind_snow =
    T_dead_wind_snow / (length * width) +
    (T_dead_wind_snow * Ecx_dead_wind_snow) / ((length * length * width) / 6) -
    (T_dead_wind_snow * Ecy_dead_wind_snow) / ((length * width * width) / 6);

  const minDead_uplift =
    T_minDead_uplift / (length * width) +
    (T_minDead_uplift * Ecx_minDead_uplift) / ((length * length * width) / 6) -
    (T_minDead_uplift * Ecy_minDead_uplift) / ((length * width * width) / 6);
  return {
    dead_imposed: dead_imposed > 0 ? dead_imposed : 0,
    dead_snow: dead_snow > 0 ? dead_snow : 0,
    dead_wind_imposed: dead_wind_imposed > 0 ? dead_wind_imposed : 0,
    dead_wind_snow: dead_wind_snow > 0 ? dead_wind_snow : 0,
    minDead_uplift: minDead_uplift > 0 ? minDead_uplift : 0,
  };
};

export const Q3 = (
  T_dead_imposed,
  T_dead_snow,
  T_dead_wind_imposed,
  T_dead_wind_snow,
  T_minDead_uplift,
  length,
  width,
  Ecx_dead_imposed,
  Ecy_dead_imposed,
  Ecx_dead_snow,
  Ecy_dead_snow,
  Ecx_dead_wind_imposed,
  Ecy_dead_wind_imposed,
  Ecx_dead_wind_snow,
  Ecy_dead_wind_snow,
  Ecx_minDead_uplift,
  Ecy_minDead_uplift
) => {
  //dead +  imposed
  const dead_imposed =
    T_dead_imposed / (length * width) -
    (T_dead_imposed * Ecx_dead_imposed) / ((length * length * width) / 6) -
    (T_dead_imposed * Ecy_dead_imposed) / ((length * width * width) / 6);
  //dead + snow
  const dead_snow =
    T_dead_snow / (length * width) -
    (T_dead_snow * Ecx_dead_snow) / ((length * length * width) / 6) -
    (T_dead_snow * Ecy_dead_snow) / ((length * width * width) / 6);
  //dead + wind + imposed
  const dead_wind_imposed =
    T_dead_wind_imposed / (length * width) -
    (T_dead_wind_imposed * Ecx_dead_wind_imposed) /
      ((length * length * width) / 6) -
    (T_dead_wind_imposed * Ecy_dead_wind_imposed) /
      ((length * width * width) / 6);
  //dead + wind + snow
  const dead_wind_snow =
    T_dead_wind_snow / (length * width) -
    (T_dead_wind_snow * Ecx_dead_wind_snow) / ((length * length * width) / 6) -
    (T_dead_wind_snow * Ecy_dead_wind_snow) / ((length * width * width) / 6);

  const minDead_uplift =
    T_minDead_uplift / (length * width) -
    (T_minDead_uplift * Ecx_minDead_uplift) / ((length * length * width) / 6) -
    (T_minDead_uplift * Ecy_minDead_uplift) / ((length * width * width) / 6);
  return {
    dead_imposed: dead_imposed > 0 ? dead_imposed : 0,
    dead_snow: dead_snow > 0 ? dead_snow : 0,
    dead_wind_imposed: dead_wind_imposed > 0 ? dead_wind_imposed : 0,
    dead_wind_snow: dead_wind_snow > 0 ? dead_wind_snow : 0,
    minDead_uplift: minDead_uplift > 0 ? minDead_uplift : 0,
  };
};

export const Q4 = (
  T_dead_imposed,
  T_dead_snow,
  T_dead_wind_imposed,
  T_dead_wind_snow,
  T_minDead_uplift,
  length,
  width,
  Ecx_dead_imposed,
  Ecy_dead_imposed,
  Ecx_dead_snow,
  Ecy_dead_snow,
  Ecx_dead_wind_imposed,
  Ecy_dead_wind_imposed,
  Ecx_dead_wind_snow,
  Ecy_dead_wind_snow,
  Ecx_minDead_uplift,
  Ecy_minDead_uplift
) => {
  //dead  + imposed
  const dead_imposed =
    T_dead_imposed / (length * width) -
    (T_dead_imposed * Ecx_dead_imposed) / ((length * length * width) / 6) +
    (T_dead_imposed * Ecy_dead_imposed) / ((length * width * width) / 6);
  //dead + snow
  const dead_snow =
    T_dead_snow / (length * width) -
    (T_dead_snow * Ecx_dead_snow) / ((length * length * width) / 6) +
    (T_dead_snow * Ecy_dead_snow) / ((length * width * width) / 6);
  //dead + wind + imposed
  const dead_wind_imposed =
    T_dead_wind_imposed / (length * width) -
    (T_dead_wind_imposed * Ecx_dead_wind_imposed) /
      ((length * length * width) / 6) +
    (T_dead_wind_imposed * Ecy_dead_wind_imposed) /
      ((length * width * width) / 6);
  //dead + wind + snow
  const dead_wind_snow =
    T_dead_wind_snow / (length * width) -
    (T_dead_wind_snow * Ecx_dead_wind_snow) / ((length * length * width) / 6) +
    (T_dead_wind_snow * Ecy_dead_wind_snow) / ((length * width * width) / 6);
  const minDead_uplift =
    T_minDead_uplift / (length * width) -
    (T_minDead_uplift * Ecx_minDead_uplift) / ((length * length * width) / 6) +
    (T_minDead_uplift * Ecy_minDead_uplift) / ((length * width * width) / 6);
  return {
    dead_imposed: dead_imposed > 0 ? dead_imposed : 0,
    dead_snow: dead_snow > 0 ? dead_snow : 0,
    dead_wind_imposed: dead_wind_imposed > 0 ? dead_wind_imposed : 0,
    dead_wind_snow: dead_wind_snow > 0 ? dead_wind_snow : 0,
    minDead_uplift: minDead_uplift > 0 ? minDead_uplift : 0,
  };
};

export const Overburden_Pressure = (foundDepth, padDepth, soilDensity) => {
  const ans = (foundDepth + padDepth) * (soilDensity ? soilDensity : 0);
  return ans;
};

export const Allowable_Bearing_Pressure = (allowableBP, overBurdenPressure) => {
  const ans = allowableBP + overBurdenPressure;
  return ans;
};
