import State from './State';
import {
  Action,
  RESET_STATE,
  TOGGLE_ACCORD,
  SET_CASE1_A,
  SET_CASE1_B,
  SET_CASE1_C,
  SET_CASE1_D,
  SET_CASE2_A,
  SET_CASE2_B,
  SET_CASE2_C,
  SET_CASE2_D,
  SET_CASE3_A,
  SET_CASE3_B,
  SET_CASE3_C,
  SET_CASE3_D,
  SET_CASE4_A,
  SET_CASE4_B,
  SET_CASE4_C,
  SET_CASE4_D,
  SET_DEFAULT,
} from './Actions';

const initialState: State = {
  case_default: false,
  loadCases: 1,

  case1_a: false,
  case1_b: false,
  case1_c: false,
  case1_d: false,

  case2_a: false,
  case2_b: false,
  case2_c: false,
  case2_d: false,

  case3_a: false,
  case3_b: false,
  case3_c: false,
  case3_d: false,

  case4_a: false,
  case4_b: false,
  case4_c: false,
  case4_d: false,

  loadCasesInputActive: false,
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_DEFAULT: {
      return {
        ...state,
        case_default: action.payload,
      };
    }
    case SET_CASE1_A: {
      return {
        ...state,
        case1_a: action.payload,
      };
    }
    case SET_CASE1_B: {
      return {
        ...state,
        case1_b: action.payload,
      };
    }
    case SET_CASE1_C: {
      return {
        ...state,
        case1_c: action.payload,
      };
    }
    case SET_CASE1_D: {
      return {
        ...state,
        case1_d: action.payload,
      };
    }
    case SET_CASE2_A: {
      return {
        ...state,
        case2_a: action.payload,
      };
    }
    case SET_CASE2_B: {
      return {
        ...state,
        case2_b: action.payload,
      };
    }
    case SET_CASE2_C: {
      return {
        ...state,
        case2_c: action.payload,
      };
    }
    case SET_CASE2_D: {
      return {
        ...state,
        case2_d: action.payload,
      };
    }
    case SET_CASE3_A: {
      return {
        ...state,
        case3_a: action.payload,
      };
    }
    case SET_CASE3_B: {
      return {
        ...state,
        case3_b: action.payload,
      };
    }
    case SET_CASE3_C: {
      return {
        ...state,
        case3_c: action.payload,
      };
    }
    case SET_CASE3_D: {
      return {
        ...state,
        case3_d: action.payload,
      };
    }
    case SET_CASE4_A: {
      return {
        ...state,
        case4_a: action.payload,
      };
    }
    case SET_CASE4_B: {
      return {
        ...state,
        case4_b: action.payload,
      };
    }
    case SET_CASE4_C: {
      return {
        ...state,
        case4_c: action.payload,
      };
    }
    case SET_CASE4_D: {
      return {
        ...state,
        case4_d: action.payload,
      };
    }
    case TOGGLE_ACCORD: {
      return {
        ...state,
        loadCasesInputActive: !state.loadCasesInputActive,
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
