import Header from './Components/Views/Header';
import './Components/App.css';
import MainView from './Components/Project/ProjectViewControllerContainer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Components
import Home from './Components/Views/Home';

function App() {
  return (
    <Router>
      <div>
        <div className="noprint">
          <Header />
        </div>

        <Switch>
          <Route path="/massfill">
            <MainView />
          </Route>
          <Route path="">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
