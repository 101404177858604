import React from 'react';
import Props from './Props';

const View: React.FC<Props> = (props: Props) => {
  const case1 = {
    name: props.caseOneName,
    axial: props.caseOneAxial,
    hx: props.caseOneHx,
    hy: props.caseOneHy,
    mx: props.caseOneMx,
    my: props.caseOneMy,
    type: props.caseOneType,
  };

  const case2 = {
    name: props.caseTwoName,
    axial: props.caseTwoAxial,
    hx: props.caseTwoHx,
    hy: props.caseTwoHy,
    mx: props.caseTwoMx,
    my: props.caseTwoMy,
  };

  const case3 = {
    name: props.caseThreeName,
    axial: props.caseThreeAxial,
    hx: props.caseThreeHx,
    hy: props.caseThreeHy,
    mx: props.caseThreeMx,
    my: props.caseThreeMy,
  };

  const case4 = {
    name: props.caseFourName,
    axial: props.caseFourAxial,
    hx: props.caseFourHx,
    hy: props.caseFourHy,
    mx: props.caseFourMx,
    my: props.caseFourMy,
  };

  return (
    <div>
      {case1.name !== '' ? (
        <table className="table-fixed w-full border-collapse mt-2">
          <thead>
            <tr className="border border-gray-300">
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
                Case One - {case1.name}
              </th>
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2"></th>
            </tr>
          </thead>
          <tbody>
            {case1.type !== 'None' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Load Classification:</td>
                <td>
                  <b>{case1.type}</b>
                </td>
              </tr>
            ) : null}
          </tbody>

          <tbody>
            {case1.axial && case1.axial !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Axial Force</td>
                <td className="w-1/5 pl-2 p-1.5">{case1.axial} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case1.hx && case1.hx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case1.hx} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case1.hy && case1.hy !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case1.hy} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case1.mx && case1.mx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case1.mx} kNm</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case1.my && case1.my !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case1.my} kNm</td>
              </tr>
            ) : null}
          </tbody>
          <tbody></tbody>
        </table>
      ) : (
        ''
      )}

      {case2.name !== '' ? (
        <table className="table-fixed w-full border-collapse mt-8">
          <thead>
            <tr className="border border-gray-300">
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
                Case Two - {case2.name}
              </th>
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2"></th>
            </tr>
          </thead>
          <tbody>
            {case2.axial && case2.axial !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Axial Force</td>
                <td className="w-1/5 pl-2 p-1.5">{case2.axial} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case2.hx && case2.hx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case2.hx} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case2.hy && case2.hy !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case2.hy} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case2.mx && case2.mx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case2.mx} kNm</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case2.my && case2.my !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case2.my} kNm</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      ) : (
        ''
      )}

      {case3.name !== '' ? (
        <table className="table-fixed w-full border-collapse mt-8">
          <thead>
            <tr className="border border-gray-300">
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
                Case Three - {case3.name}
              </th>
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2"></th>
            </tr>
          </thead>
          <tbody>
            {case3.axial && case3.axial !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Axial Force</td>
                <td className="w-1/5 pl-2 p-1.5">{case3.axial} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case3.hx && case3.hx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case3.hx} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case3.hy && case3.hy !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case3.hy} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case3.mx && case3.mx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case3.mx} kNm</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case3.my && case3.my !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case3.my} kNm</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      ) : (
        ''
      )}

      {case3.name !== '' ? (
        <table className="table-fixed w-full border-collapse mt-8">
          <thead>
            <tr className="border border-gray-300">
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
                Case Four - {case4.name}
              </th>
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2"></th>
            </tr>
          </thead>
          <tbody>
            {case4.axial && case4.axial !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Axial Force</td>
                <td className="w-1/5 pl-2 p-1.5">{case4.axial} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case4.hx && case4.hx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case4.hx} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case4.hy && case4.hy !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Horizontal Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case4.hy} kN</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case4.mx && case4.mx !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force X</td>
                <td className="w-1/5 pl-2 p-1.5">{case4.mx} kNm</td>
              </tr>
            ) : null}
          </tbody>
          <tbody>
            {case4.my && case3.my !== '0' ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Moment Force Y</td>
                <td className="w-1/5 pl-2 p-1.5">{case4.my} kNm</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      ) : (
        ''
      )}
    </div>
  );
};

export default View;
