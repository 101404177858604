import { connect } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getProjectDetails } from './Store/Selectors';
import View from './View';

const mapStateToProps = (state: RootState) => ({
  ...getProjectDetails(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(View);
