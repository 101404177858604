import React from 'react';
import ReportDetails from '../Core/ReportDetails';
import TableHeader from '../Core/TableHeader';
import ReportTable from '../Core/ReportTable';
import './ReportTable.css';

const UpliftReport = (props) => {
  const upliftData = props.upliftData.upliftResistance;

  return (
    <div>
      {upliftData.uplift ? (
        <div style={{ marginLeft: '10px' }}>
          <h2 className="fs-525 fw-800 top-margin underline">
            Pad Uplift Calculation
          </h2>
          <TableHeader
            title="A factor of safety of 1.5 is used in the uplift resistance
              calculation."
          />
          <ReportTable>
            <ReportDetails
              displayCheck={true}
              calcName="Uplift Resistance"
              formula={
                <>
                  F<sub>z</sub> = G<sub>k</sub> + (L * W) * (Y<sub>c</sub> + Y
                  <sub>s</sub>) / 1.5 = &nbsp;
                </>
              }
              answer={`${upliftData.upliftResistance.toFixed(2)} kN`}
            />
            <ReportDetails
              displayCheck={true}
              calcName="Uplift Force"
              formula={
                <>
                  W<sub>k,up</sub>= &nbsp;
                </>
              }
              answer={`${upliftData.uplift.toFixed(2)} kN`}
            />
          </ReportTable>
          <p className="noprint">
            NOTE AT PRESENT, COLUMN DEAD LOAD IS NOT INCLUDED IN THE RESISTANCE.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default UpliftReport;
