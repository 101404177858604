import React from 'react';

const TableHeader: React.FC<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <tr>
        <th className="fw-600 fs-500" style={{ textAlign: 'left' }}>
          {props.title}
        </th>
      </tr>
    </React.Fragment>
  );
};

export interface Props {
  title: string;
}

export default TableHeader;
