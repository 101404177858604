import { connect } from 'react-redux';
import { RootState } from '../Redux/Index';
import { getDesignParams } from '../Inputs/DesignParams/Store/Selectors';
import { getColumnLoads } from '../Inputs/ColumnLoads/Store/Selectors';
import { getSoilProperties } from '../Inputs/SoilProperties/Store/Selectors';
import { getColumnDetails } from '../Inputs/ColumnDetails/Store/Selectors';
import { getPadDetails } from '../Inputs/PadDetails/Store/Selectors';
import { getProjectDetails } from '../Inputs/ProjectDetails/Store/Selectors';
import { getLoadCases } from '../Inputs/LoadCases/Store/Selectors';

import View from './View';
import Props from './Props';

const mapStateToProps = (state: RootState): Props => {
  return {
    loadCases: { ...getLoadCases(state) },
    soilProperties: { ...getSoilProperties(state) },
    columnDetails: { ...getColumnDetails(state) },
    padDetails: { ...getPadDetails(state) },
    designParams: { ...getDesignParams(state) },
    columnLoads: { ...getColumnLoads(state) },
    projectDetails: { ...getProjectDetails(state) },
  };
};

export default connect(mapStateToProps)(View);
