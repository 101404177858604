import React, { useEffect } from 'react';
import { InputField, AccordianForm } from '../../Core';
import { PropsFromRedux } from './FormContainer';

const PadPropertiesInput: React.FC<Props> = (PropsFromRedux: Props) => {
  // const [change, setChange] = useState(false);

  useEffect(() => {
    const { fetchPadProperties, resetReduxState } = PropsFromRedux;
    const previousProps = PropsFromRedux;
    //@ts-ignore
    const data = JSON.parse(localStorage.getItem('inputs'));
    if (data) {
      if (previousProps.padLength !== data.padLength) {
        fetchPadProperties();
        // setChange(!change);
      } else {
        resetReduxState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {}, [[change]]);

  const props = { ...PropsFromRedux };

  const handleConcreteDensityInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setConcreteDensity(e.target.value);
  };

  const handleLengthInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setPadLength(e.target.value);
  };

  const handleWidthInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setPadWidth(e.target.value);
  };

  const handleDepthInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setPadDepth(e.target.value);
  };

  const handleDeadSurcharge = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setDeadSurcharge(e.target.value);
  };

  const handleImposedSurcharge = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setImposedSurcharge(e.target.value);
  };

  return (
    <AccordianForm
      title="Pad Properties"
      children={
        <React.Fragment>
          <InputField
            label="Concrete Density"
            value={props.concreteDensity}
            onChange={handleConcreteDensityInput}
          />
          <InputField
            label="Pad Length"
            value={props.padLength}
            onChange={handleLengthInput}
          />
          <InputField
            label="Pad Width"
            value={props.padWidth}
            onChange={handleWidthInput}
          />
          <InputField
            label="Pad Depth"
            value={props.padDepth}
            onChange={handleDepthInput}
          />
          <InputField
            label="Gk Surcharge (kN/m2)"
            value={props.deadSurcharge}
            onChange={handleDeadSurcharge}
          />
          <InputField
            label="Qk Surcharge (kN/m2)"
            value={props.imposedSurcharge}
            onChange={handleImposedSurcharge}
          />
        </React.Fragment>
      }
    />
  );
};

export interface Props extends PropsFromRedux {}

export interface State {}

export default PadPropertiesInput;
