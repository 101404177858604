import React from 'react';
import './modal.css';
export default class Modal extends React.Component {
  onClose = (e) => {
    this.props.onClose && this.props.onClose(e);
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div class="modal" id="modal">
        <h2 className="text-xl mt-4">Open File</h2>
        <div class="content">{this.props.children}</div>
        <div class="actions">
          <button className="text-xl mt-4" onClick={this.onClose}>
            Close
          </button>
        </div>
      </div>
    );
  }
}
