//Self-weight of the Pad Foundation
export const Pad_SW = (length, width, depth, concreteDensity) => {
  const ans = length * width * depth * concreteDensity;
  return ans;
};

//Self-weight of the Soil
export const Soil_SW = (length, width, foundDepth, soilDensity) => {
  const ans = length * width * foundDepth * soilDensity;
  return ans;
};

/*
Calculates the maximum axial load, based on set events happening: 
Dead Load +  Wind Load + Imposed Load
Dead Load + Wind Load + Snow Load

Find the maximum of the above combinations.
For rerefence, Imposed Load & Snow Loads seldom happen together.
Could add a logic check for the user to allow co-exisitng combinations though.
*/

export const Column_Axial_Load = (
  deadLoad,
  superImposedDeadLoad,
  windLoad,
  imposedLoad,
  snowLoad,
  uplift
) => {
  //dead + imposed
  const dead_imposed =
    (deadLoad ? parseFloat(deadLoad) : 0) +
    (superImposedDeadLoad ? parseFloat(superImposedDeadLoad) : 0) +
    (imposedLoad ? parseFloat(imposedLoad) : 0);
  //dead + snow
  const dead_snow =
    (deadLoad ? deadLoad : 0) +
    (superImposedDeadLoad ? superImposedDeadLoad : 0) +
    (snowLoad ? snowLoad : 0);
  //dead + wind + imposed
  const dead_wind_imposed =
    (deadLoad ? deadLoad : 0) +
    (superImposedDeadLoad ? superImposedDeadLoad : 0) +
    (windLoad ? windLoad : 0) +
    (imposedLoad ? imposedLoad : 0);
  //dead + wind + snow
  const dead_wind_snow =
    (deadLoad ? deadLoad : 0) +
    (superImposedDeadLoad ? superImposedDeadLoad : 0) +
    (windLoad ? windLoad : 0) +
    (snowLoad ? snowLoad : 0);
  const minDead_uplift = (deadLoad ? deadLoad : 0) - (uplift ? uplift : 0);

  return {
    dead_imposed,
    dead_snow,
    dead_wind_imposed,
    dead_wind_snow,
    minDead_uplift,
  };
};

//Revised method to calculate it based on every loadcase. We should only need one case for them all.

export const Column_Load_Input = (
  CombinationOne,
  case1,
  case2,
  case3,
  case4
) => {
  //Load Factors for Combinations
  const load1factor = CombinationOne.a;
  const load2factor = CombinationOne.b;
  const load3factor = CombinationOne.c;
  const load4factor = CombinationOne.d;

  const Result = parseFloat(
    load1factor * case1 +
      load2factor * case2 +
      load3factor * case3 +
      load4factor * case4
  );

  return Result;
};

export const Column_Horizontal_Load = (
  deadLoad,
  windLoad,
  imposedLoad,
  snowLoad
) => {
  //dead + imposed
  const dead_imposed =
    (deadLoad ? deadLoad : 0) + (imposedLoad ? imposedLoad : 0);
  const dead_snow = (deadLoad ? deadLoad : 0) + (snowLoad ? snowLoad : 0);

  //dead + wind + imposed
  const dead_wind_imposed =
    (deadLoad ? deadLoad : 0) +
    (windLoad ? windLoad : 0) +
    (imposedLoad ? imposedLoad : 0);
  //dead + wind + snow
  const dead_wind_snow =
    (deadLoad ? deadLoad : 0) +
    (windLoad ? windLoad : 0) +
    (snowLoad ? snowLoad : 0);
  const minDead_uplift = (deadLoad ? deadLoad : 0) + (windLoad ? windLoad : 0);
  return {
    dead_imposed,
    dead_snow,
    dead_wind_imposed,
    dead_wind_snow,
    minDead_uplift,
  };
};

//Revised method to calculate it based on every loadcase.

export const Column_Horizontal_Load_Rev1 = (
  CombinationOne,
  case1,
  case2,
  case3
) => {
  //Load Factors for Combinations
  const load1factor = CombinationOne.dead;
  const load2factor = CombinationOne.imposed;
  const load3factor = CombinationOne.wind;

  const Result =
    load1factor * case1 + load2factor * case2 + load3factor * case3;

  return {
    Result,
  };
};

//Total Axial Force
export const Total_Axial_Load = (
  swPad,
  swSoil,
  axial_deadimposed,
  axial_deadsnow,
  axial_deadwindimposed,
  axial_deadwindsnow,
  axial_minDead
) => {
  const dead_imposed = swPad + swSoil + axial_deadimposed;
  const dead_snow = swPad + swSoil + axial_deadsnow;
  const dead_wind_imposed = swPad + swSoil + axial_deadwindimposed;
  const dead_wind_snow = swPad + swSoil + axial_deadwindsnow;
  const minDead_uplift = swPad + swSoil + axial_minDead;
  return {
    dead_imposed,
    dead_snow,
    dead_wind_imposed,
    dead_wind_snow,
    minDead_uplift,
  };
};

//REVISED TOTAL AXIAL LOAD
//Total Axial Force
export const Total_Axial_Load_1 = (swPad, swSoil, axialLoad) => {
  const ans = swPad + swSoil + axialLoad;
  return ans;
};

//Moments due to Horizontal Forces in X-direction
export const Moments_From_Horizontal2 = (horizontalLoad, depth) => {
  const ans = (horizontalLoad ? horizontalLoad : 0) * depth;
  return ans;
};

//TO BE REMOVED
//Moments due to Horizontal Forces in X-direction
export const Moments_From_Horizontal = (
  columnHorizontalLoad_dead_wind_imposed,
  depth,
  columnHorizontalLoad_dead_wind_snow,
  columnHorizontalLoad_dead_imposed,
  columnHorizontalLoad_dead_snow,
  columnHorizontalLoad_minDead_uplift
) => {
  const dead_imposed = columnHorizontalLoad_dead_imposed * depth;
  const dead_snow = columnHorizontalLoad_dead_snow * depth;
  const dead_wind_imposed = columnHorizontalLoad_dead_wind_imposed * depth;
  const dead_wind_snow = columnHorizontalLoad_dead_wind_snow * depth;
  const minDead_uplift = columnHorizontalLoad_minDead_uplift * depth;
  return {
    dead_imposed,
    dead_snow,
    dead_wind_imposed,
    dead_wind_snow,
    minDead_uplift,
  };
};

export const Moments_About_Edge2 = (
  moment,
  swPad,
  swSoil,
  padSize,
  ecc,
  axialLoad
) => {
  const ans =
    (moment ? moment : 0) +
    (swPad + swSoil) * (padSize / 2) +
    axialLoad * (padSize / 2 + ecc / 1000);
  return ans;
};

//TO BE REMOVED
export const Moments_About_Edge = (
  momentDead,
  momentWind,
  momentImposed,
  momentSnow,
  swPad,
  swSoil,
  padSize,
  ecc,
  columnAxialLoad_dead_imposed,
  columnAxialLoad_dead_snow,
  columnAxialLoad_dead_wind_imposed,
  columnAxialLoad_dead_wind_snow,
  columnAxialLoad_minDead_uplift
) => {
  //dead + imposed
  const dead_imposed =
    (momentDead ? momentDead : 0) +
    (momentImposed ? momentImposed : 0) +
    (swPad + swSoil) * (padSize / 2) +
    columnAxialLoad_dead_imposed * (padSize / 2 + ecc / 1000);
  //dead + snow
  const dead_snow =
    (momentDead ? momentDead : 0) +
    (momentSnow ? momentSnow : 0) +
    (swPad + swSoil) * (padSize / 2) +
    columnAxialLoad_dead_snow * (padSize / 2 + ecc / 1000);
  //dead + wind + imposed
  const dead_wind_imposed =
    (momentDead ? momentDead : 0) +
    (momentWind ? momentWind : 0) +
    (momentImposed ? momentImposed : 0) +
    (swPad + swSoil) * (padSize / 2) +
    columnAxialLoad_dead_wind_imposed * (padSize / 2 + ecc / 1000);
  //dead + wind + snow
  const dead_wind_snow =
    (momentDead ? momentDead : 0) +
    (momentWind ? momentWind : 0) +
    (momentSnow ? momentSnow : 0) +
    (swPad + swSoil) * (padSize / 2) +
    columnAxialLoad_dead_wind_snow * (padSize / 2 + ecc / 1000);
  const minDead_uplift =
    (momentDead ? momentDead : 0) +
    (momentWind ? momentWind : 0) +
    (swPad + swSoil) * (padSize / 2) +
    columnAxialLoad_minDead_uplift * (padSize / 2 + ecc / 1000);
  return {
    dead_imposed,
    dead_snow,
    dead_wind_imposed,
    dead_wind_snow,
    minDead_uplift,
  };
};

export const Eccentricity2 = (
  length,
  totalAxialForce,
  columnMoment,
  horizontalMoment
) => {
  const ans = (columnMoment + horizontalMoment) / totalAxialForce - length / 2;
  return ans;
};

//TO BE REMOVED
export const Eccentricity = (
  length,
  totalAxialForce_dead_imposed,
  totalAxialForce_dead_snow,
  totalAxialForce_dead_wind_imposed,
  totalAxialForce_dead_wind_snow,
  totalAxialForce_minDead_uplift,
  columnMoment_dead_imposed,
  columnMoment_dead_snow,
  columnMoment_dead_wind_imposed,
  columnMoment_dead_wind_snow,
  columnMoment_minDead_uplift,
  horizontalMoment_dead_imposed,
  horizontalMoment_dead_snow,
  horizontalMoment_dead_wind_imposed,
  horizontalMoment_dead_wind_snow,
  horizontalMoment_minDead_uplift
) => {
  //dead + imposed
  const dead_imposed =
    (columnMoment_dead_imposed + horizontalMoment_dead_imposed) /
      totalAxialForce_dead_imposed -
    length / 2;
  //dead + snow
  const dead_snow =
    (columnMoment_dead_snow + horizontalMoment_dead_snow) /
      totalAxialForce_dead_snow -
    length / 2;
  //dead + wind + imposed
  const dead_wind_imposed =
    (columnMoment_dead_wind_imposed + horizontalMoment_dead_wind_imposed) /
      totalAxialForce_dead_wind_imposed -
    length / 2;
  //dead + wind + snow
  const dead_wind_snow =
    (columnMoment_dead_wind_snow + horizontalMoment_dead_wind_snow) /
      totalAxialForce_dead_wind_snow -
    length / 2;
  const minDead_uplift =
    (columnMoment_minDead_uplift + horizontalMoment_minDead_uplift) /
      totalAxialForce_minDead_uplift -
    length / 2;
  return {
    dead_imposed,
    dead_snow,
    dead_wind_imposed,
    dead_wind_snow,
    minDead_uplift,
  };
};
