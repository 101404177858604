import State from './State';
import {
  Action,
  SET_SOIL_DENSITY,
  SET_ANGLE_OF_FRICTION,
  SET_ALLOWABLE_BP,
  SET_FOUND_DEPTH,
  RESET_STATE,
  TOGGLE_ACCORD,
} from './Actions';

const initialState: State = {
  soilDensity: '18',
  angleoffriction: '30',
  allowableBP: '150',
  foundDepth: '0.63',
  soilInputActive: false,
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_SOIL_DENSITY: {
      return {
        ...state,
        soilDensity: action.payload,
      };
    }
    case SET_ANGLE_OF_FRICTION: {
      return {
        ...state,
        angleoffriction: action.payload,
      };
    }
    case SET_ALLOWABLE_BP: {
      return {
        ...state,
        allowableBP: action.payload,
      };
    }
    case SET_FOUND_DEPTH: {
      return {
        ...state,
        foundDepth: action.payload,
      };
    }
    case TOGGLE_ACCORD: {
      return {
        ...state,
        soilInputActive: !state.soilInputActive,
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
