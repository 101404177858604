import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getColumnDetails } from './Store/Selectors';
// import { getProject } from '../../Project/Current/Store/Selectors';
import Form from './Form';
import {
  SET_BP_LENGTH,
  SET_BP_WIDTH,
  SET_ECCX,
  SET_ECCY,
  TOGGLE_ACCORD,
  RESET_STATE,
  fetchColumnProperties,
} from './Store/Actions';

const mapStateToProps = (state: RootState) => ({
  ...getColumnDetails(state),
  // projectId: getProject(state).projectId,
});

const mapDispatchToProps = {
  setBpLength: (n: string) => ({ type: SET_BP_LENGTH, payload: n }),
  setBpWidth: (t: string) => ({
    type: SET_BP_WIDTH,
    payload: t,
  }),
  setEccX: (n: string) => ({ type: SET_ECCX, payload: n }),
  setEccY: (n: string) => ({ type: SET_ECCY, payload: n }),
  toggleActive: () => ({ type: TOGGLE_ACCORD }),
  resetReduxState: () => ({ type: RESET_STATE }),
  fetchColumnProperties: fetchColumnProperties,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Form);
