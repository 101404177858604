//Passive Coefficent
export const kp = (angleOfFriction) => {
  const ans =
    (1 + Math.sin(angleOfFriction * (Math.PI / 180))) /
    (1 - Math.sin(angleOfFriction * (Math.PI / 180)));
  return ans;
};

//Passive Resistance from Soil Above Foundation
export const Hfs_A = (kp, soilDensity, foundDepth) => {
  const ans = kp * soilDensity * foundDepth;
  return ans;
};

//Passive Resistance from Soil to base of Foundation
export const Hfs_B = (kp, soilDensity, foundDepth, depth) => {
  const ans = kp * soilDensity * (depth + foundDepth);
  return ans;
};

//Passive Resistance in X-Direction
export const Hfs_ = (Hfsa, Hfsb, depth, length) => {
  const ans = ((Hfsa + Hfsb) * depth * length) / 2;
  return ans;
};

/* 

DA1 	- Combination 1 - A1 + M1 + R1
	- Combination 2 - A2 + M2 + R1

Design Approach 1 - Combination 1
A1 - perm unfav 1.35 and variable unfav 1.5 - all fav 1
A2 - perm unfav 1 and variable unfav 1.3 - all fav 1
For Pads & Shallow Pads - M1 & M2 = 1, R1 = 1

*/

export const Axial_Force = (swSoil, swPad, deadLoad) => {
  return 1 * (swSoil + swPad + (deadLoad ? deadLoad : 0));
};

//TO BE DELETED
export const Horizontal_Forces = (
  deadLoad,
  windLoad,
  imposedLoad,
  snowLoad
) => {
  //dead + imposed_A1
  const dead_imposed_A1 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.5 * (imposedLoad ? parseFloat(imposedLoad) : 0);
  //dead + wind + imposed_A1
  const dead_snow_A1 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.5 * (snowLoad ? parseFloat(snowLoad) : 0);
  //dead + wind + imposed_A1
  const dead_wind_imposed_A1 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.5 *
      ((windLoad ? parseFloat(windLoad) : 0) +
        (imposedLoad ? parseFloat(imposedLoad) : 0));
  //dead + wind + snow_A1
  const dead_wind_snow_A1 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.5 *
      ((windLoad ? parseFloat(windLoad) : 0) +
        (snowLoad ? parseFloat(snowLoad) : 0));
  //dead + imposed_A1
  const dead_imposed_A2 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.3 * (imposedLoad ? parseFloat(imposedLoad) : 0);
  //dead + wind + imposed_A1
  const dead_snow_A2 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.3 * (snowLoad ? parseFloat(snowLoad) : 0);
  //dead + wind + imposed_A2
  const dead_wind_imposed_A2 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.3 *
      ((windLoad ? parseFloat(windLoad) : 0) +
        (imposedLoad ? parseFloat(imposedLoad) : 0));
  //dead + wind + snow_A2
  const dead_wind_snow_A2 =
    1 * (deadLoad ? parseFloat(deadLoad) : 0) +
    1.3 *
      ((windLoad ? parseFloat(windLoad) : 0) +
        (snowLoad ? parseFloat(snowLoad) : 0));
  return {
    dead_imposed_A1,
    dead_snow_A1,
    dead_wind_imposed_A1,
    dead_wind_snow_A1,
    dead_imposed_A2,
    dead_snow_A2,
    dead_wind_imposed_A2,
    dead_wind_snow_A2,
  };
};

//TO BE DELETED
export const Friction = (angleOfFriction, permanentDead) => {
  return permanentDead * Math.tan(angleOfFriction * (Math.PI / 180));
};

export const Friction2 = (angleOfFriction, loadCase, padSW, soilSW) => {
  return loadCase.type === 'Dead'
    ? (parseFloat(loadCase.axial) + padSW + soilSW) *
        Math.tan(angleOfFriction * (Math.PI / 180))
    : (padSW + soilSW) * Math.tan(angleOfFriction * (Math.PI / 180));
};

export const Sliding_Resistance = (Friction, Passive) => {
  return Friction + Passive;
};

export const Max_Horizontal = (
  horizontal_dead_wind_imposed,
  horizontal_dead_wind_snow
) => {
  if (horizontal_dead_wind_imposed > horizontal_dead_wind_snow) {
    return {
      MaxH: horizontal_dead_wind_imposed,
      CriticalCase: 'Dead + Imposed + Wind',
    };
  } else {
    return {
      MaxH: horizontal_dead_wind_snow,
      CriticalCase: 'Dead + Snow + Wind',
    };
  }
};

export const Critical_Horizontal = (
  dead_imposed_A1,
  dead_snow_A1,
  dead_wind_imposed_A1,
  dead_wind_snow_A1,
  dead_imposed_A2,
  dead_snow_A2,
  dead_wind_imposed_A2,
  dead_wind_snow_A2,
  Resistance
) => {
  const max_A1 = Math.max(
    dead_imposed_A1,
    dead_snow_A1,
    dead_wind_imposed_A1,
    dead_wind_snow_A1
  );
  const max_A2 = Math.max(
    dead_imposed_A2,
    dead_snow_A2,
    dead_wind_imposed_A2,
    dead_wind_snow_A2
  );

  if (max_A1 >= max_A2) {
    if (
      dead_imposed_A1 >=
      (dead_snow_A1 && dead_wind_imposed_A1 && dead_wind_snow_A1)
    ) {
      return {
        MaxHorizontal: dead_imposed_A1,
        Resistance,
        CriticalCase: 'Dead + Imposed (DA1-1)',
      };
    } else if (
      dead_snow_A1 >=
      (dead_imposed_A1 && dead_wind_imposed_A1 && dead_wind_snow_A1)
    ) {
      return {
        MaxHorizontal: dead_snow_A1,
        Resistance,
        CriticalCase: 'Dead + Snow (DA1-1)',
      };
    } else if (
      dead_wind_imposed_A1 >=
      (dead_imposed_A1 && dead_snow_A1 && dead_wind_snow_A1)
    ) {
      return {
        MaxHorizontal: dead_wind_imposed_A1,
        Resistance,
        CriticalCase: 'Dead + Wind + Imposed (DA1-1)',
      };
    } else if (
      dead_wind_snow_A1 >=
      (dead_imposed_A1 && dead_snow_A1 && dead_wind_imposed_A1)
    ) {
      return {
        MaxHorizontal: dead_wind_snow_A1,
        Resistance,
        CriticalCase: 'Dead + Snow + Imposed (DA1-1)',
      };
    }
  } else {
    if (
      dead_imposed_A2 >=
      (dead_snow_A2 && dead_wind_imposed_A2 && dead_wind_snow_A2)
    ) {
      return {
        MaxHorizontal: dead_imposed_A2,
        Resistance,
        CriticalCase: 'Dead + Imposed (DA1-2)',
      };
    } else if (
      dead_snow_A2 >=
      (dead_imposed_A2 && dead_wind_imposed_A2 && dead_wind_snow_A2)
    ) {
      return {
        MaxHorizontal: dead_snow_A2,
        Resistance,
        CriticalCase: 'Dead + Snow (DA1-2)',
      };
    } else if (
      dead_wind_imposed_A2 >=
      (dead_imposed_A2 && dead_snow_A2 && dead_wind_snow_A2)
    ) {
      return {
        MaxHorizontal: dead_wind_imposed_A2,
        Resistance,
        CriticalCase: 'Dead + Wind + Imposed (DA1-2)',
      };
    } else if (
      dead_wind_snow_A2 >=
      (dead_imposed_A2 && dead_snow_A2 && dead_wind_imposed_A2)
    ) {
      return {
        MaxHorizontal: dead_wind_snow_A2,
        Resistance,
        CriticalCase: 'Dead + Snow + Imposed (DA1-2)',
      };
    }
  }
};
