import React from 'react';
import { Link } from 'react-router-dom';
import PadFoundationAboveFoldImage from '../Assets/AboveFoldImg.png';
import cloudStorageImg from '../Assets/CloudStorage.png';
import resultsSummaryImg from '../Assets/ResultsSummary.png';
import comprehensiveOutputImg from '../Assets/ComprehensiveOutputs.png';
import './Home.css';

export default function Home() {
  document.title = `SubSolve - Foundation Solutions`;

  return (
    <>
      <h1 className="home-title title-padding ff-sans-normal text-dark uppercase text-center">
        Cloud based foundation <br></br>Solutions
      </h1>
      <div className="grid-container text-center">
        <p className="home-text">
          Structural calculations in accordance with Eurocodes and British
          Standards.<br></br> <br></br>Optimal, accurate solutions for pad
          foundations. Available on desktop, table and mobile. Completely Free.
        </p>

        <img alt="pad-foundation-result" src={PadFoundationAboveFoldImage} />
      </div>
      <div className="flex-space-evenly">
        <Link to="/demo" children="New Calculation">
          <button className="bg-green text-dark large-button button-border button-hover">
            Try It Out
          </button>
        </Link>

        <Link to="/register" children="Sign up">
          <button className="bg-green text-dark large-button button-border button-hover">
            Sign Up
          </button>
        </Link>
      </div>

      <div>
        <h1 className="home-title features-margin features-text ff-sans-normal text-dark uppercase">
          Features
        </h1>
      </div>

      <div className="">
        <h1 className="home-title features-margin">Cloud Based Storage</h1>
        <div className="grid-container">
          <img className="" src={cloudStorageImg} alt="Cloud Storage" />
          <p className="home-text">
            Calculations are stored in the {''}
            <span className="fw-800  small-features-text">cloud</span> for security
            and ease of access across any devices. Mobile, tablet or desktop.
          </p>
        </div>

        <h1 className="home-title features-margin">Results Summary</h1>
        <div className="grid-container">
          <img className="" src={resultsSummaryImg} alt="Results Summary" />
          <p className="home-text">
            <span className="fw-800 small-features-text">Results summaries</span>{' '}
            for the calculations. Increase efficiency for all parties reviewing
            the calculations.
          </p>
        </div>

        <h1 className="home-title features-margin">Comprehensive Outputs</h1>
        <div className="grid-container">
          <img className="" src={comprehensiveOutputImg} alt="Cloud Storage" />
          <p className="home-text">
            <span className="fw-800 small-features-text">
              Comprehensive outputs {''}
            </span>
            that show you every step computed for the design of the foundations.
          </p>
        </div>
      </div>

      <div className="footer footer-border">
        <Link to="/about" className="footer-text hover-items">
          About
        </Link>
        <Link to="/about" className="footer-text hover-items">
          T&C&ensp;&copy;&ensp;2022 SubSolve.
        </Link>
      </div>
    </>
  );
}
