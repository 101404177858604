import State from './State';
import {
  Action,
  SET_CASE_ONE_NAME,
  SET_CASE_ONE_AXIAL,
  SET_CASE_ONE_HX,
  SET_CASE_ONE_HY,
  SET_CASE_ONE_MX,
  SET_CASE_ONE_MY,
  SET_CASE_ONE_TYPE,
  SET_CASE_TWO_NAME,
  SET_CASE_TWO_AXIAL,
  SET_CASE_TWO_HX,
  SET_CASE_TWO_HY,
  SET_CASE_TWO_MX,
  SET_CASE_TWO_MY,
  SET_CASE_TWO_TYPE,
  SET_CASE_THREE_NAME,
  SET_CASE_THREE_AXIAL,
  SET_CASE_THREE_HX,
  SET_CASE_THREE_HY,
  SET_CASE_THREE_MX,
  SET_CASE_THREE_MY,
  SET_CASE_THREE_TYPE,
  SET_CASE_FOUR_NAME,
  SET_CASE_FOUR_AXIAL,
  SET_CASE_FOUR_HX,
  SET_CASE_FOUR_HY,
  SET_CASE_FOUR_MX,
  SET_CASE_FOUR_MY,
  SET_CASE_FOUR_TYPE,
  RESET_STATE,
} from './Actions';

const initialState: State = {
  caseOneName: '',
  caseOneAxial: '',
  caseOneHx: '',
  caseOneHy: '',
  caseOneMx: '',
  caseOneMy: '',
  caseOneType: 'None',
  caseTwoName: '',
  caseTwoAxial: '',
  caseTwoHx: '',
  caseTwoHy: '',
  caseTwoMx: '',
  caseTwoMy: '',
  caseTwoType: 'None',
  caseThreeName: '',
  caseThreeAxial: '',
  caseThreeHx: '',
  caseThreeHy: '',
  caseThreeMx: '',
  caseThreeMy: '',
  caseThreeType: 'None',
  caseFourName: '',
  caseFourAxial: '',
  caseFourHx: '',
  caseFourHy: '',
  caseFourMx: '',
  caseFourMy: '',
  caseFourType: 'None',

  // columnLoadsActive: false,
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_CASE_ONE_NAME: {
      return {
        ...state,
        caseOneName: action.payload,
      };
    }
    case SET_CASE_ONE_AXIAL: {
      return {
        ...state,
        caseOneAxial: action.payload,
      };
    }
    case SET_CASE_ONE_HX: {
      return {
        ...state,
        caseOneHx: action.payload,
      };
    }
    case SET_CASE_ONE_HY: {
      return {
        ...state,
        caseOneHy: action.payload,
      };
    }
    case SET_CASE_ONE_MX: {
      return {
        ...state,
        caseOneMx: action.payload,
      };
    }
    case SET_CASE_ONE_MY: {
      return {
        ...state,
        caseOneMy: action.payload,
      };
    }
    case SET_CASE_ONE_TYPE: {
      return {
        ...state,
        caseOneType: action.payload,
      };
    }
    case SET_CASE_TWO_NAME: {
      return {
        ...state,
        caseTwoName: action.payload,
      };
    }
    case SET_CASE_TWO_AXIAL: {
      return {
        ...state,
        caseTwoAxial: action.payload,
      };
    }
    case SET_CASE_TWO_HX: {
      return {
        ...state,
        caseTwoHx: action.payload,
      };
    }
    case SET_CASE_TWO_HY: {
      return {
        ...state,
        caseTwoHy: action.payload,
      };
    }
    case SET_CASE_TWO_MX: {
      return {
        ...state,
        caseTwoMx: action.payload,
      };
    }
    case SET_CASE_TWO_MY: {
      return {
        ...state,
        caseTwoMy: action.payload,
      };
    }
    case SET_CASE_TWO_TYPE: {
      return {
        ...state,
        caseTwoType: action.payload,
      };
    }

    case SET_CASE_THREE_NAME: {
      return {
        ...state,
        caseThreeName: action.payload,
      };
    }
    case SET_CASE_THREE_AXIAL: {
      return {
        ...state,
        caseThreeAxial: action.payload,
      };
    }
    case SET_CASE_THREE_HX: {
      return {
        ...state,
        caseThreeHx: action.payload,
      };
    }
    case SET_CASE_THREE_HY: {
      return {
        ...state,
        caseThreeHy: action.payload,
      };
    }
    case SET_CASE_THREE_MX: {
      return {
        ...state,
        caseThreeMx: action.payload,
      };
    }
    case SET_CASE_THREE_MY: {
      return {
        ...state,
        caseThreeMy: action.payload,
      };
    }
    case SET_CASE_THREE_TYPE: {
      return {
        ...state,
        caseThreeType: action.payload,
      };
    }

    case SET_CASE_FOUR_NAME: {
      return {
        ...state,
        caseFourName: action.payload,
      };
    }
    case SET_CASE_FOUR_AXIAL: {
      return {
        ...state,
        caseFourAxial: action.payload,
      };
    }
    case SET_CASE_FOUR_HX: {
      return {
        ...state,
        caseFourHx: action.payload,
      };
    }
    case SET_CASE_FOUR_HY: {
      return {
        ...state,
        caseFourHy: action.payload,
      };
    }
    case SET_CASE_FOUR_MX: {
      return {
        ...state,
        caseFourMx: action.payload,
      };
    }
    case SET_CASE_FOUR_MY: {
      return {
        ...state,
        caseFourMy: action.payload,
      };
    }
    case SET_CASE_FOUR_TYPE: {
      return {
        ...state,
        caseFourType: action.payload,
      };
    }

    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
