//WIP - Still really unsure about how best to do it.

/* 
DA1 	- Combination 1 - A1 + M1 + R1
	- Combination 2 - A2 + M2 + R1

Design Approach 1 - Combination 1
A1 - perm unfav 1.35 and variable unfav 1.5 - all fav 1
A2 - perm unfav 1 and variable unfav 1.3 - all fav 1
For Pads & Shallow Pads - M1 & M2 = 1, R1 = 1

*/

export const CaseSolver = (case_a, case_b, case_c, case_d) => {
  const a = case_a ? 1 : 0;
  const b = case_b ? 1 : 0;
  const c = case_c ? 1 : 0;
  const d = case_d ? 1 : 0;

  return {
    a,
    b,
    c,
    d,
  };
};
export const transientTypeSolver = (case_a, case_b, case_c, case_d) => {
  const a = case_a === 'Transient' ? 1 : 0;
  const b = case_b === 'Transient' ? 1 : 0;
  const c = case_c === 'Transient' ? 1 : 0;
  const d = case_d === 'Transient' ? 1 : 0;
  return {
    a,
    b,
    c,
    d,
  };
};

export const TransientSolver = (
  combination,
  case_a,
  case_b,
  case_c,
  case_d
) => {
  //take the combinations, find if either contains transient
  const a = combination.a ? (case_a === 'Transient' ? 1 : 0) : 0;
  const b = combination.b ? (case_b === 'Transient' ? 1 : 0) : 0;
  const c = combination.c ? (case_c === 'Transient' ? 1 : 0) : 0;
  const d = combination.d ? (case_d === 'Transient' ? 1 : 0) : 0;
  return {
    transient: a || b || c || d ? 'True' : 'False',
  };
};


export const nameSolver = (
  case_a,
  case_b,
  case_c,
  case_d,
  case_a_name,
  case_b_name,
  case_c_name,
  case_d_name
) => {
  const case1Array = [];
  if (case_a) {
    case1Array.push(` ${case_a_name}`);
  }
  if (case_b) {
    case1Array.push(` ${case_b_name}`);
  }
  if (case_c) {
    case1Array.push(` ${case_c_name}`);
  }
  if (case_d) {
    case1Array.push(` ${case_d_name}`);
  }

  const ans = case1Array.toString();
  return ans;
};

export const Variable_Combined = (imposed1, imposed2) => {
  return imposed1 + imposed2;
};
export const DA1_Comb1_Z_Axis = (deadLoad, imposedLoad) => {
  return 1.35 * deadLoad + 1.5 * imposedLoad;
};

export const DA1_Comb1_XY_Axis = (deadLoadH, imposedLoadH) => {
  return 1.35 * deadLoadH + 1.5(imposedLoadH);
};

export const DA_2_Comb2_Z_Axis = (
  deadLoad,
  superDead,
  swSoil,
  swPad,
  imposedLoad
) => {
  return deadLoad + superDead + swSoil + swPad + 1.3 * imposedLoad;
};

export const DA2_Comb2_XY_Axis = (deadLoadH, imposedLoadH) => {
  return deadLoadH + 1.3 * imposedLoadH;
};
