import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../Redux/Index';
// import { getProject } from './Current/Store/Selectors';
// import { SET_ID } from './Current/Store/Actions';
import Controller from './ProjectViewController';

const mapStateToProps = (state: RootState) => ({
  // ...getProject(state),
});

const mapDispatchToProps = {
  // clearId: () => ({ type: SET_ID, payload: undefined }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Controller);
