import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PropsFromRedux } from './ProjectViewControllerContainer';
import View from './Current/ViewContainer';

const Controller: React.FC<Props> = (props: Props) => {
  return (
    <Switch>
      <Route
        exact
        path="/massfill/"
        render={() => {
          return <View />;
        }}
      />
    </Switch>
  );
};

interface Props extends PropsFromRedux {}

export default Controller;
