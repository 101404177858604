import React, { useEffect } from 'react';
import { InputField, AccordianForm } from '../../Core';
import { PropsFromRedux } from './FormContainer';

const ProjectDetailsInput: React.FC<Props> = (PropsFromRedux: Props) => {
  // const [change, setChange] = useState(false);

  useEffect(() => {
    const { fetchProjectDetails, resetReduxState } = PropsFromRedux;
    const previousProps = PropsFromRedux;
    //@ts-ignore
    const data = JSON.parse(localStorage.getItem('inputs'));
    if (data) {
      if (previousProps.calculationTitle !== data.calculationTitle) {
        fetchProjectDetails();
        // setChange(!change);
      } else {
        resetReduxState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {}, [[change]]);

  const props = { ...PropsFromRedux };

  const handleCalculationTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCalculationTitle(e.target.value);
  };

  const handleProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setName(e.target.value);
  };

  const handleProjectNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setProjectNumber(e.target.value);
  };

  const handleProjectDoneBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setDoneBy(e.target.value);
  };

  const handleProjectCheckedBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setCheckedBy(e.target.value);
  };

  const handleprojectApprovedBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setApprovedBy(e.target.value);
  };

  const handleProjectDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setProjectDate(e.target.value);
  };

  return (
    <>
      <div className="text-xl mb-4 ml-6 mt-4">Inputs</div>
      <AccordianForm
        title="Project Details"
        children={
          <React.Fragment>
            <InputField
              label="Project"
              value={props.projectName}
              onChange={handleProjectName}
            />
            <InputField
              label="Calculation Title"
              value={props.calculationTitle}
              onChange={handleCalculationTitle}
            />
            <InputField
              label="Project No."
              value={props.projectNo}
              onChange={handleProjectNo}
            />
            <InputField
              label="Done by:"
              value={props.projectDoneBy}
              onChange={handleProjectDoneBy}
            />
            <InputField
              label="Checked by:"
              value={props.projectCheckedBy}
              onChange={handleProjectCheckedBy}
            />
            <InputField
              label="Approved by:"
              value={props.projectApprovedBy}
              onChange={handleprojectApprovedBy}
            />
            <InputField
              label="Date:"
              value={props.projectDate}
              onChange={handleProjectDate}
            />
          </React.Fragment>
        }
      />
    </>
  );
};

export interface Props extends PropsFromRedux {}

export interface State {}

export default ProjectDetailsInput;
