import React from 'react';
import ReportDetails from '../Core/ReportDetails';
import TableHeader from '../Core/TableHeader';
import ReportTable from '../Core/ReportTable';
import './ReportTable.css';
import { nameSolver } from '../Calculations/LoadCombinations';

const BearingReport = (props) => {
  const padDetails = props.padDetails;
  const ReportData = props.bearingData;
  const columnDetails = props.columnDetails;
  const columnLoads = props.columnLoads;
  const loadCases = props.loadCases;

  const Results = {
    criticalBearing: props.Results.criticalBearing,
    criticalBearing2: props.Results.criticalBearing_2,
    windUplift: props.Results.windUplift,
    UpliftResistance: props.Results.upliftResistance,
    criticalSliding_X: props.Results.criticalSliding_X,
    criticalSliding_Y: props.Results.criticalSliding_Y,
    upliftResistance: props.Results.upliftResistance,
  };

  const CaseNameOne = nameSolver(
    loadCases.case1_a,
    loadCases.case1_b,
    loadCases.case1_c,
    loadCases.case1_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  const CaseNameTwo = nameSolver(
    loadCases.case2_a,
    loadCases.case2_b,
    loadCases.case2_c,
    loadCases.case2_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  const CaseNameThree = nameSolver(
    loadCases.case3_a,
    loadCases.case3_b,
    loadCases.case3_c,
    loadCases.case3_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  const CaseNameFour = nameSolver(
    loadCases.case4_a,
    loadCases.case4_b,
    loadCases.case4_c,
    loadCases.case4_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  return (
    <div>
      {Results.criticalBearing2.maxBearing > 0 ? (
        /* Bearing Report Calcs */
        <div style={{ marginLeft: '10px' }}>
          <div>
            <h2 className="fs-525 fw-800 top-margin underline">
              Bearing Resistance Calculations
            </h2>

            <ReportTable>
              <TableHeader title="Pad Properties" />
              <ReportDetails
                displayCheck="true"
                calcName="X Axis Distance to Edge"
                formula="x = L / 2 + Ex = "
                answer={
                  columnDetails.eccX
                    ? `${
                        parseFloat(columnDetails.eccX) +
                        (padDetails.padLength * 1000) / 2
                      }
                mm`
                    : `${(padDetails.padLength * 1000) / 2}
                  mm`
                }
              />
              <ReportDetails
                displayCheck="true"
                calcName="Y Axis Distance to Edge"
                formula="y = W / 2 + Ey = "
                answer={
                  columnDetails.eccY
                    ? `${
                        parseFloat(columnDetails.eccY) +
                        (padDetails.padWidth * 1000) / 2
                      }
                mm`
                    : `${(padDetails.padWidth * 1000) / 2}
                  mm`
                }
              />

              <ReportDetails
                displayCheck="true"
                calcName="Pad Effective Length"
                formula={
                  <>
                    L<sub>eff</sub> = min(d, L / 2 + Off<sub>x</sub> - L
                    <sub>bp</sub>) + min(d, L / 2 - Off<sub>x</sub> - L
                    <sub>bp</sub>) = &nbsp;
                  </>
                }
                answer={`${ReportData.padBearingLength * 1000} mm`}
              />
              <ReportDetails
                displayCheck="true"
                calcName="Pad Effective Width"
                formula={
                  <>
                    W<sub>eff</sub> = min(d, W / 2 + Off<sub>y</sub> - W
                    <sub>bp</sub>) + min(d, W / 2 - Off<sub>y</sub> - W
                    <sub>bp</sub>) = &nbsp;
                  </>
                }
                answer={`${ReportData.padBearingLength * 1000} mm`}
              />
            </ReportTable>
          </div>
          {/* First Div */}

          {/* Below Here is next par */}

          {CaseNameOne ? (
            <div>
              <h2 className="font-bold mt-2 text-lg">
                {`Load Combination 1 - ${CaseNameOne}`}
              </h2>

              <ReportTable>
                <TableHeader title="Forces on Base" />

                <ReportDetails
                  displayCheck={ReportData.case1hx}
                  calcName="Forces in X"
                  formula={
                    <>
                      F<sub>x</sub> = G<sub>hx</sub> + Q<sub>hx</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case1hx.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case1hy}
                  calcName="Forces in Y"
                  formula={
                    <>
                      F<sub>y</sub> = G<sub>hy</sub> + Q<sub>hy</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case1hy.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case1totalAxial}
                  calcName="Forces in Z"
                  formula={
                    <>
                      {' '}
                      F<sub>z</sub> = G<sub>k</sub> + G<sub>k,q</sub> + Q
                      <sub>k</sub>
                      &nbsp; + (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>) =
                      &nbsp;
                    </>
                  }
                  answer={`${ReportData.case1totalAxial.toFixed(2)} kN`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Moments on Base" />
                <ReportDetails
                  displayCheck={ReportData.mX_case1}
                  calcName="Moments in X"
                  formula={
                    <>
                      F<sub>x</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * L / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * x + M
                      <sub>gx</sub> + M<sub>qx</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mX_case1 + ReportData.mxH_case1
                  ).toFixed(2)} kNm`}
                />
                <ReportDetails
                  displayCheck={ReportData.mY_case1}
                  calcName="Moments in Y"
                  formula={
                    <>
                      F<sub>y</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * W / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * y + M
                      <sub>gy</sub> + M<sub>qy</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mY_case1 + ReportData.myH_case1
                  ).toFixed(2)} kNm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Eccentricty of Load" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity X"
                  formula={
                    <>
                      e<sub>x</sub> = M<sub>x</sub> / F<sub>z</sub> - L / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecx_case1 * 1000).toFixed(2)} mm`}
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity Y"
                  formula={
                    <>
                      e<sub>y</sub> = M<sub>y</sub> / F<sub>z</sub> - W / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecy_case1 * 1000).toFixed(2)} mm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Pad Base Pressures" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q1"
                  formula={
                    <>
                      q1 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + ((F<sub>z</sub> *
                      e<sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q1_case1.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q2"
                  formula={
                    <>
                      q2 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q2_case1.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q3"
                  formula={
                    <>
                      q3 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q3_case1.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q4"
                  formula={
                    <>
                      q4 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q4_case1.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck="true"
                  calcName="Allowable Bearing Pressure"
                  formula={
                    <>
                      q<sub>allowable</sub> = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.CombinationOneTransient.transient === 'True'
                        ? ReportData.allowableBearingTemporary
                        : ReportData.allowableBearingPermanent}
                      kN/m
                      <sup>2</sup>
                    </>
                  }
                />
              </ReportTable>
            </div>
          ) : null}

          {CaseNameTwo ? (
            <div>
              <h2 className="font-bold mt-2 text-lg">
                {`Load Combination 2 - ${CaseNameTwo}`}
              </h2>

              <ReportTable>
                <TableHeader title="Forces on Base" />

                <ReportDetails
                  displayCheck={ReportData.case2hx}
                  calcName="Forces in X"
                  formula={
                    <>
                      F<sub>x</sub> = G<sub>hx</sub> + Q<sub>hx</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case2hx.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case2hy}
                  calcName="Forces in Y"
                  formula={
                    <>
                      F<sub>y</sub> = G<sub>hy</sub> + Q<sub>hy</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case2hy.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case2totalAxial}
                  calcName="Forces in Z"
                  formula={
                    <>
                      {' '}
                      F<sub>z</sub> = G<sub>k</sub> + G<sub>k,q</sub> + Q
                      <sub>k</sub>
                      &nbsp; + (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>) =
                      &nbsp;
                    </>
                  }
                  answer={`${ReportData.case2totalAxial.toFixed(2)} kN`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Moments on Base" />
                <ReportDetails
                  displayCheck={ReportData.mX_case2}
                  calcName="Moments in X"
                  formula={
                    <>
                      F<sub>x</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * L / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * x + M
                      <sub>gx</sub> + M<sub>qx</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mX_case2 + ReportData.mxH_case2
                  ).toFixed(2)} kNm`}
                />
                <ReportDetails
                  displayCheck={ReportData.mY_case2}
                  calcName="Moments in Y"
                  formula={
                    <>
                      F<sub>y</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * W / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * y + M
                      <sub>gy</sub> + M<sub>qy</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mY_case2 + ReportData.myH_case2
                  ).toFixed(2)} kNm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Eccentricty of Load" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity X"
                  formula={
                    <>
                      e<sub>x</sub> = M<sub>x</sub> / F<sub>z</sub> - L / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecx_case2 * 1000).toFixed(2)} mm`}
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity Y"
                  formula={
                    <>
                      e<sub>y</sub> = M<sub>y</sub> / F<sub>z</sub> - W / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecy_case2 * 1000).toFixed(2)} mm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Pad Base Pressures" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q1"
                  formula={
                    <>
                      q1 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + ((F<sub>z</sub> *
                      e<sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q1_case2.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q2"
                  formula={
                    <>
                      q2 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q2_case2.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q3"
                  formula={
                    <>
                      q3 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q3_case2.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q4"
                  formula={
                    <>
                      q4 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q4_case2.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck="true"
                  calcName="Allowable Bearing Pressure"
                  formula={
                    <>
                      q<sub>allowable</sub> = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.CombinationTwoTransient.transient === 'True'
                        ? ReportData.allowableBearingTemporary
                        : ReportData.allowableBearingPermanent}
                      kN/m
                      <sup>2</sup>
                    </>
                  }
                />
              </ReportTable>
            </div>
          ) : null}

          {CaseNameThree ? (
            <div>
              <h2 className="font-bold mt-2 text-lg">
                {`Load Combination 3 - ${CaseNameThree}`}
              </h2>

              <ReportTable>
                <TableHeader title="Forces on Base" />

                <ReportDetails
                  displayCheck={ReportData.case3hx}
                  calcName="Forces in X"
                  formula={
                    <>
                      F<sub>x</sub> = G<sub>hx</sub> + Q<sub>hx</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case3hx.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case3hy}
                  calcName="Forces in Y"
                  formula={
                    <>
                      F<sub>y</sub> = G<sub>hy</sub> + Q<sub>hy</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case3hy.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case3totalAxial}
                  calcName="Forces in Z"
                  formula={
                    <>
                      {' '}
                      F<sub>z</sub> = G<sub>k</sub> + G<sub>k,q</sub> + Q
                      <sub>k</sub>
                      &nbsp; + (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>) =
                      &nbsp;
                    </>
                  }
                  answer={`${ReportData.case3totalAxial.toFixed(2)} kN`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Moments on Base" />
                <ReportDetails
                  displayCheck={ReportData.mX_case3}
                  calcName="Moments in X"
                  formula={
                    <>
                      F<sub>x</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * L / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * x + M
                      <sub>gx</sub> + M<sub>qx</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mX_case3 + ReportData.mxH_case3
                  ).toFixed(2)} kNm`}
                />
                <ReportDetails
                  displayCheck={ReportData.mY_case3}
                  calcName="Moments in Y"
                  formula={
                    <>
                      F<sub>y</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * W / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * y + M
                      <sub>gy</sub> + M<sub>qy</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mY_case3 + ReportData.myH_case3
                  ).toFixed(2)} kNm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Eccentricty of Load" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity X"
                  formula={
                    <>
                      e<sub>x</sub> = M<sub>x</sub> / F<sub>z</sub> - L / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecx_case3 * 1000).toFixed(2)} mm`}
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity Y"
                  formula={
                    <>
                      e<sub>y</sub> = M<sub>y</sub> / F<sub>z</sub> - W / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecy_case3 * 1000).toFixed(2)} mm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Pad Base Pressures" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q1"
                  formula={
                    <>
                      q1 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + ((F<sub>z</sub> *
                      e<sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q1_case3.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q2"
                  formula={
                    <>
                      q2 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q2_case3.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q3"
                  formula={
                    <>
                      q3 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q3_case3.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q4"
                  formula={
                    <>
                      q4 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q4_case3.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck="true"
                  calcName="Allowable Bearing Pressure"
                  formula={
                    <>
                      q<sub>allowable</sub> = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.CombinationThreeTransient.transient === 'True'
                        ? ReportData.allowableBearingTemporary
                        : ReportData.allowableBearingPermanent}
                      kN/m
                      <sup>2</sup>
                    </>
                  }
                />
              </ReportTable>
            </div>
          ) : null}

          {CaseNameFour ? (
            <div>
              <h2 className="font-bold mt-2 text-lg">
                {`Load Combination 4 - ${CaseNameFour}`}
              </h2>

              <ReportTable>
                <TableHeader title="Forces on Base" />

                <ReportDetails
                  displayCheck={ReportData.case4hx}
                  calcName="Forces in X"
                  formula={
                    <>
                      F<sub>x</sub> = G<sub>hx</sub> + Q<sub>hx</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case4hx.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case4hy}
                  calcName="Forces in Y"
                  formula={
                    <>
                      F<sub>y</sub> = G<sub>hy</sub> + Q<sub>hy</sub> = &nbsp;
                    </>
                  }
                  answer={`${ReportData.case4hy.toFixed(2)} kN`}
                />

                <ReportDetails
                  displayCheck={ReportData.case4totalAxial}
                  calcName="Forces in Z"
                  formula={
                    <>
                      {' '}
                      F<sub>z</sub> = G<sub>k</sub> + G<sub>k,q</sub> + Q
                      <sub>k</sub>
                      &nbsp; + (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>) =
                      &nbsp;
                    </>
                  }
                  answer={`${ReportData.case4totalAxial.toFixed(2)} kN`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Moments on Base" />
                <ReportDetails
                  displayCheck={ReportData.mX_case4}
                  calcName="Moments in X"
                  formula={
                    <>
                      F<sub>x</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * L / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * x + M
                      <sub>gx</sub> + M<sub>qx</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mX_case4 + ReportData.mxH_case4
                  ).toFixed(2)} kNm`}
                />
                <ReportDetails
                  displayCheck={ReportData.mY_case4}
                  calcName="Moments in Y"
                  formula={
                    <>
                      F<sub>y</sub> = (L * W) * (Y<sub>c</sub> + Y<sub>s</sub>)
                      * W / 2 + (G<sub>k</sub> + G<sub>k,q</sub>) * y + M
                      <sub>gy</sub> + M<sub>qy</sub> = &nbsp;
                    </>
                  }
                  answer={`${(
                    ReportData.mY_case4 + ReportData.myH_case4
                  ).toFixed(2)} kNm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Eccentricty of Load" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity X"
                  formula={
                    <>
                      e<sub>x</sub> = M<sub>x</sub> / F<sub>z</sub> - L / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecx_case4 * 1000).toFixed(2)} mm`}
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Eccentricity Y"
                  formula={
                    <>
                      e<sub>y</sub> = M<sub>y</sub> / F<sub>z</sub> - W / 2 =
                      &nbsp;
                    </>
                  }
                  answer={`${(ReportData.ecy_case4 * 1000).toFixed(2)} mm`}
                />
              </ReportTable>

              <ReportTable>
                <TableHeader title="Pad Base Pressures" />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q1"
                  formula={
                    <>
                      q1 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + ((F<sub>z</sub> *
                      e<sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q1_case4.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q2"
                  formula={
                    <>
                      q2 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      + ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q2_case4.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q3"
                  formula={
                    <>
                      q3 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) - (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q3_case4.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck={true}
                  calcName="Pressure at q4"
                  formula={
                    <>
                      q4 = F<sub>z</sub> / (L<sub>eff</sub> * W<sub>eff</sub> )
                      - ((F
                      <sub>z</sub> * e<sub>x</sub>) / (L<sub>eff</sub>
                      <sup>2</sup> * W<sub>eff</sub> ) / 6) + (F<sub>z</sub> * e
                      <sub>y</sub>) / (W<sub>eff</sub>
                      <sup>2</sup> * L<sub>eff</sub> ) / 6) = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.q4_case4.toFixed(2)} kN/m<sup>2</sup>
                    </>
                  }
                />
                <ReportDetails
                  displayCheck="true"
                  calcName="Allowable Bearing Pressure"
                  formula={
                    <>
                      q<sub>allowable</sub> = &nbsp;
                    </>
                  }
                  answer={
                    <>
                      {ReportData.CombinationFourTransient.transient === 'True'
                        ? ReportData.allowableBearingTemporary
                        : ReportData.allowableBearingPermanent}
                      kN/m
                      <sup>2</sup>
                    </>
                  }
                />
              </ReportTable>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default BearingReport;
