import { Dispatch } from 'redux';

export const SET_CONCRETE_DENSITY =
  'CURRENT_PROJECT/PAD_INPUTS/SET_CONCRETE_DENSITY';
export const SET_PAD_LENGTH = 'CURRENT_PROJECT/PAD_INPUTS/SET_PAD_LENGTH';
export const SET_PAD_WIDTH = 'CURRENT_PROJECT/PAD_INPUTS/SET_PAD_WIDTH';
export const SET_PAD_DEPTH = 'CURRENT_PROJECT/PAD_INPUTS/SET_PAD_DEPTH';
export const TOGGLE_ACCORD = 'CURRENT_PROJECT/PAD_INPUTS/TOGGLE_ACCORD';
export const RESET_STATE = 'CURRENT_PROJECT/PAD_INPUTS/RESET_STATE';
export const FETCH = 'CURRENT_PROJECT/PAD_PROPERTIES/FETCH';
export const SET_DEAD_SURCHARGE =
  'CURRENT_PROJECT/PAD_PROPERTIES/DEAD_SURCHARGE';
export const SET_IMPOSED_SURCHARGE =
  'CURRENT_PROJECT/PAD_PROPERTIES/IMPOSED_SURCHARGE';

interface SetConcreteDensityAction {
  type: typeof SET_CONCRETE_DENSITY;
  payload: string;
}
interface SetPadLength {
  type: typeof SET_PAD_LENGTH;
  payload: string;
}
interface SetPadWidth {
  type: typeof SET_PAD_WIDTH;
  payload: string;
}
interface SetPadDepth {
  type: typeof SET_PAD_DEPTH;
  payload: string;
}
interface SetDeadSurcharge {
  type: typeof SET_DEAD_SURCHARGE;
  payload: string;
}
interface SetImposedSurcharge {
  type: typeof SET_IMPOSED_SURCHARGE;
  payload: string;
}
interface ToggleActiveAction {
  type: typeof TOGGLE_ACCORD;
}
interface ResetStateAction {
  type: typeof RESET_STATE;
}

export const fetchPadProperties = () => {
  return (dispatch: Dispatch) => {
    //@ts-ignore
    const json = JSON.parse(localStorage.getItem('inputs'));
    //@ts-ignore
    dispatch({
      type: SET_CONCRETE_DENSITY,
      payload: json.concrete_density ?? '',
    });
    dispatch({
      type: SET_PAD_LENGTH,
      payload: json.pad_length ?? '',
    });
    dispatch({
      type: SET_PAD_WIDTH,
      payload: json.pad_width ?? '',
    });
    dispatch({
      type: SET_DEAD_SURCHARGE,
      payload: json.dead_surcharge ?? '',
    });
    dispatch({
      type: SET_IMPOSED_SURCHARGE,
      payload: json.imposed_surcharge ?? '',
    });
    dispatch({ type: SET_PAD_DEPTH, payload: json.pad_depth ?? '' });
    return json;
  };
};

export type Action =
  | SetConcreteDensityAction
  | SetPadLength
  | SetPadWidth
  | SetPadDepth
  | SetDeadSurcharge
  | SetImposedSurcharge
  | ToggleActiveAction
  | ResetStateAction;
