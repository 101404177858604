export const BearingSummary = (
  a,
  b,
  c,
  d,
  allowableBearing,
  loadCombination,
  ex,
  ey
) => {
  const maxBearing = Math.max(a, b, c, d);
  const criticalUtilisation = maxBearing / allowableBearing;

  return {
    Combination: loadCombination,
    MaximumBearing: maxBearing,
    Utilisation: criticalUtilisation,
    AllowableBearing: allowableBearing,
    Ecc_x: ex,
    Ecc_y: ey,
  };
};

export const findCritical = (a, b, c, d) => {
  if (a.Utilisation >= Math.max(b.Utilisation, c.Utilisation, d.Utilisation)) {
    return {
      maxBearing: a.MaximumBearing,
      UnityRatio: a.Utilisation,
      criticalCase: a.Combination,
      ex: a.Ecc_x,
      ey: a.Ecc_y,
      allowableBP: a.AllowableBearing,
    };
  }
  if (b.Utilisation >= Math.max(a.Utilisation, c.Utilisation, d.Utilisation)) {
    return {
      maxBearing: b.MaximumBearing,
      UnityRatio: b.Utilisation,
      criticalCase: b.Combination,
      ex: b.Ecc_x,
      ey: b.Ecc_y,
      allowableBP: b.AllowableBearing,
    };
  }
  if (c.Utilisation >= Math.max(a.Utilisation, b.Utilisation, d.Utilisation)) {
    return {
      maxBearing: c.MaximumBearing,
      UnityRatio: c.Utilisation,
      criticalCase: c.Combination,
      ex: c.Ecc_x,
      ey: c.Ecc_y,
      allowableBP: c.AllowableBearing,
    };
  }
  if (d.Utilisation >= Math.max(a.Utilisation, b.Utilisation, c.Utilisation)) {
    return {
      maxBearing: d.MaximumBearing,
      UnityRatio: d.Utilisation,
      criticalCase: d.Combination,
      ex: d.Ecc_x,
      ey: d.Ecc_y,
      allowableBP: d.AllowableBearing,
    };
  }
};

export const Critical_Bearing = (
  q1a,
  q2a,
  q3a,
  q4a,
  q1b,
  q2b,
  q3b,
  q4b,
  q1c,
  q2c,
  q3c,
  q4c,
  q1d,
  q2d,
  q3d,
  q4d,
  q1e,
  q2e,
  q3e,
  q4e,
  exa,
  eya,
  exb,
  eyb,
  exc,
  eyc,
  exd,
  eyd,
  exe,
  eye,
  allowableBP,
  allowableBP_Temp,
  length,
  width,
  depth
) => {
  if (!length || !width || !depth || !allowableBP) {
    return {
      UnityRatio: 0,
      maxBearing: 0,
      criticalCase: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
      ex: 0,
      ey: 0,
      allowableBP: 0,
    };
  } else {
    if (allowableBP && length && width && depth) {
      const dead_imposed_UR = Math.max(q1a, q2a, q3a, q4a) / allowableBP;
      const dead_snow_UR = Math.max(q1b, q2b, q3b, q4b) / allowableBP;
      const dead_wind_imposed_UR =
        Math.max(q1c, q2c, q3c, q4c) / allowableBP_Temp;
      const dead_wind_snow_UR = Math.max(q1d, q2d, q3d, q4d) / allowableBP_Temp;
      const minDead_uplift_UR = Math.max(q1e, q2e, q3e, q4e) / allowableBP_Temp;

      if (
        dead_imposed_UR >=
        (dead_snow_UR &&
          dead_wind_imposed_UR &&
          dead_wind_snow_UR &&
          minDead_uplift_UR)
      ) {
        return {
          UnityRatio: dead_imposed_UR,
          maxBearing: Math.max(q1a, q2a, q3a, q4a),
          criticalCase: 'Dead + Imposed',
          q1: q1a > 0 ? q1a : 0,
          q2: q2a > 0 ? q2a : 0,
          q3: q3a > 0 ? q3a : 0,
          q4: q4a > 0 ? q4a : 0,
          ex: exa,
          ey: eya,
          allowableBP: allowableBP,
        };
      } else if (
        dead_snow_UR >=
        (dead_imposed_UR &&
          dead_wind_imposed_UR &&
          dead_wind_snow_UR &&
          minDead_uplift_UR)
      ) {
        return {
          UnityRatio: dead_snow_UR,
          maxBearing: Math.max(q1b, q2b, q3b, q4b),
          criticalCase: 'Dead + Snow',
          q1: q1b > 0 ? q1b : 0,
          q2: q2b > 0 ? q2b : 0,
          q3: q3b > 0 ? q3b : 0,
          q4: q4b > 0 ? q4b : 0,
          ex: exb,
          ey: eyb,
          allowableBP: allowableBP,
        };
      } else if (
        dead_wind_imposed_UR >=
        (dead_imposed_UR &&
          dead_snow_UR &&
          dead_wind_snow_UR &&
          minDead_uplift_UR)
      ) {
        return {
          UnityRatio: dead_wind_imposed_UR,
          maxBearing: Math.max(q1c, q2c, q3c, q4c),
          criticalCase: 'Dead + Wind + Imposed',
          q1: q1c > 0 ? q1c : 0,
          q2: q2c > 0 ? q2c : 0,
          q3: q3c > 0 ? q3c : 0,
          q4: q4c > 0 ? q4c : 0,
          ex: exc,
          ey: eyc,
          allowableBP: allowableBP_Temp,
        };
      } else if (
        dead_wind_snow_UR >=
        (dead_imposed_UR &&
          dead_snow_UR &&
          dead_wind_imposed_UR &&
          minDead_uplift_UR)
      ) {
        return {
          UnityRatio: dead_wind_snow_UR,
          maxBearing: Math.max(q1d, q2d, q3d, q4d),
          criticalCase: 'Dead + Wind + Snow',
          q1: q1d > 0 ? q1d : 0,
          q2: q2d > 0 ? q2d : 0,
          q3: q3d > 0 ? q3d : 0,
          q4: q4d > 0 ? q4d : 0,
          ex: exd,
          ey: eyd,
          allowableBP: allowableBP_Temp,
        };
      } else if (
        minDead_uplift_UR >=
        (dead_imposed_UR &&
          dead_snow_UR &&
          dead_wind_imposed_UR &&
          dead_wind_snow_UR)
      ) {
        return {
          UnityRatio: minDead_uplift_UR,
          maxBearing: Math.max(q1e, q2e, q3e, q4e),
          criticalCase: 'Min Dead + Uplift',
          q1: q1e > 0 ? q1e : 0,
          q2: q2e > 0 ? q2e : 0,
          q3: q3e > 0 ? q3e : 0,
          q4: q4e > 0 ? q4e : 0,
          ex: exe,
          ey: eye,
          allowableBP: allowableBP_Temp,
        };
      } else {
        return {
          UnityRatio: 0,
          maxBearing: 0,
          criticalCase: 0,
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          ex: 0,
          ey: 0,
          allowableBP: 0,
        };
      }
    }
  }
};

export const Critical_Bearing2 = (
  q1a,
  q2a,
  q3a,
  q4a,
  q1b,
  q2b,
  q3b,
  q4b,
  q1c,
  q2c,
  q3c,
  q4c,
  q1d,
  q2d,
  q3d,
  q4d,
  exa,
  eya,
  exb,
  eyb,
  exc,
  eyc,
  exd,
  eyd,
  allowableBP,
  allowableBP_Temp,
  length,
  width,
  depth,
  caseNameOne,
  caseNameTwo,
  caseNameThree,
  caseNameFour,
  CombinationOneTransient,
  CombinationTwoTransient,
  CombinationThreeTransient,
  CombinationFourTransient
) => {
  if (!length || !width || !depth || !allowableBP) {
    return {
      UnityRatio: 0,
      maxBearing: 0,
      criticalCase: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
      ex: 0,
      ey: 0,
      allowableBP: 0,
    };
  } else {
    if (allowableBP && length && width && depth) {
      const case1 = Math.max(q1a, q2a, q3a, q4a);
      const case2 = Math.max(q1b, q2b, q3b, q4b);
      const case3 = Math.max(q1c, q2c, q3c, q4c);
      const case4 = Math.max(q1d, q2d, q3d, q4d);

      if (case1 >= Math.max(case2, case3, case4)) {
        return {
          UnityRatio:
            case1 /
            (CombinationOneTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP),
          maxBearing: Math.max(q1a, q2a, q3a, q4a),
          criticalCase: caseNameOne,
          q1: q1a > 0 ? q1a : 0,
          q2: q2a > 0 ? q2a : 0,
          q3: q3a > 0 ? q3a : 0,
          q4: q4a > 0 ? q4a : 0,
          ex: exa,
          ey: eya,
          allowableBP:
            CombinationOneTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP,
        };
      } else if (case2 >= Math.max(case1, case3, case4)) {
        return {
          UnityRatio:
            case2 /
            (CombinationTwoTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP),
          maxBearing: Math.max(q1b, q2b, q3b, q4b),
          criticalCase: caseNameTwo,
          q1: q1b > 0 ? q1b : 0,
          q2: q2b > 0 ? q2b : 0,
          q3: q3b > 0 ? q3b : 0,
          q4: q4b > 0 ? q4b : 0,
          ex: exb,
          ey: eyb,
          allowableBP:
            CombinationTwoTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP,
        };
      } else if (case3 >= Math.max(case1, case2, case4)) {
        return {
          UnityRatio:
            case3 /
            (CombinationThreeTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP),
          maxBearing: Math.max(q1c, q2c, q3c, q4c),
          criticalCase: caseNameThree,
          q1: q1c > 0 ? q1c : 0,
          q2: q2c > 0 ? q2c : 0,
          q3: q3c > 0 ? q3c : 0,
          q4: q4c > 0 ? q4c : 0,
          ex: exc,
          ey: eyc,
          allowableBP:
            CombinationThreeTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP,
        };
      } else if (case4 >= Math.max(case1, case2, case3)) {
        return {
          UnityRatio:
            case4 /
            (CombinationFourTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP),
          maxBearing: Math.max(q1d, q2d, q3d, q4d),
          criticalCase: caseNameFour,
          q1: q1d > 0 ? q1d : 0,
          q2: q2d > 0 ? q2d : 0,
          q3: q3d > 0 ? q3d : 0,
          q4: q4d > 0 ? q4d : 0,
          ex: exd,
          ey: eyd,
          allowableBP:
            CombinationFourTransient.transient === 'True'
              ? allowableBP_Temp
              : allowableBP,
        };
      } else {
        return {
          UnityRatio: 0,
          maxBearing: 0,
          criticalCase: 0,
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          ex: 0,
          ey: 0,
          allowableBP: 0,
        };
      }
    }
  }
};

export const Critical_Sliding2 = (
  case1,
  case2,
  case3,
  case4,
  case1Resistance,
  case2Resistance,
  case3Resistance,
  case4Resistance,
  caseNameOne,
  caseNameTwo,
  caseNameThree,
  caseNameFour
) => {
  if (case1 >= Math.max(case2, case3, case4)) {
    return {
      UnityRatio: case1 / case1Resistance,
      maxSliding: case1,
      criticalCase: caseNameOne,
      resistance: case1Resistance,
    };
  } else if (case2 >= Math.max(case1, case3, case4)) {
    return {
      UnityRatio: case2 / case2Resistance,
      maxSliding: case2,
      criticalCase: caseNameTwo,
      resistance: case2Resistance,
    };
  } else if (case3 >= Math.max(case1, case2, case4)) {
    return {
      UnityRatio: case3 / case3Resistance,
      maxSliding: case3,
      criticalCase: caseNameThree,
      resistance: case3Resistance,
    };
  } else if (case4 >= Math.max(case1, case2, case3)) {
    return {
      UnityRatio: case4 / case4Resistance,
      maxSliding: case4,
      criticalCase: caseNameFour,
      resistance: case4Resistance,
    };
  } else {
    return {
      UnityRatio: 0,
      maxSliding: 0,
      criticalCase: 0,
      resistance: 0,
    };
  }
};
