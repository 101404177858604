import React from 'react';
import Props from './Props';

const View: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <table className="table-fixed w-full border-collapse mt-2 ">
        <thead>
          <tr className="border border-gray-300">
            <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
              Pad Properties
            </th>
            <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              Y<sub>conc</sub> - Concrete Density
            </td>
            <td className="w-1/5 pl-2 p-1.5">
              {props.concreteDensity} kN/m<sup>3</sup>
            </td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              L<sub>pad</sub> - Pad Length{' '}
            </td>
            <td className="w-1/5 pl-2 p-1.5">{props.padLength} m</td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              W<sub>pad</sub> - Pad Width
            </td>
            <td className="w-1/5 pl-2 p-1.5">{props.padWidth} m</td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              d<sub>pad</sub> - Pad Depth
            </td>
            <td className="w-1/5 pl-2 p-1.5">{props.padDepth} m</td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              G<sub>k, Surcharge</sub> - Dead Surcharge
            </td>
            <td className="w-1/5 pl-2 p-1.5">
              {props.deadSurcharge} kN/m<sup>2</sup>
            </td>
          </tr>
          <tr className="border border-gray-300">
            <td className="w-1/5 pl-2 p-1.5">
              Q<sub>k, Surcharge</sub> - Imposed
            </td>
            <td className="w-1/5 pl-2 p-1.5">
              {props.imposedSurcharge} kN/m<sup>2</sup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default View;
