import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getPadDetails } from './Store/Selectors';
// import { getProject } from '../../Project/Current/Store/Selectors';
import Form from './Form';
import {
  SET_CONCRETE_DENSITY,
  SET_PAD_LENGTH,
  SET_PAD_WIDTH,
  SET_PAD_DEPTH,
  SET_DEAD_SURCHARGE,
  SET_IMPOSED_SURCHARGE,
  RESET_STATE,
  fetchPadProperties,
} from './Store/Actions';

const mapStateToProps = (state: RootState) => ({
  ...getPadDetails(state),
  // projectId: getProject(state).projectId,
});

const mapDispatchToProps = {
  setConcreteDensity: (n: string) => ({
    type: SET_CONCRETE_DENSITY,
    payload: n,
  }),
  setPadLength: (t: string) => ({
    type: SET_PAD_LENGTH,
    payload: t,
  }),
  setPadWidth: (t: string) => ({
    type: SET_PAD_WIDTH,
    payload: t,
  }),
  setPadDepth: (t: string) => ({
    type: SET_PAD_DEPTH,
    payload: t,
  }),
  setDeadSurcharge: (t: string) => ({
    type: SET_DEAD_SURCHARGE,
    payload: t,
  }),
  setImposedSurcharge: (t: string) => ({
    type: SET_IMPOSED_SURCHARGE,
    payload: t,
  }),
  resetReduxState: () => ({ type: RESET_STATE }),
  fetchPadProperties: fetchPadProperties,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Form);
