export const SET_YG = 'CURRENT_PROJECT/DESIGN_PARAMETERS/SET_YG';
export const TOGGLE_ACCORD = 'CURRENT_PROJECT/DESIGN_PARAMETERS/TOGGLE_ACCORD';
export const SET_YQ = 'CURRENT_PROJECT/DESIGN_PARAMETERS/SET_YQ';

interface SetYgAction {
  type: typeof SET_YG;
  payload: string;
}

interface ToggleActiveAction {
  type: typeof TOGGLE_ACCORD;
}

interface SetYqAction {
  type: typeof SET_YQ;
  payload: string;
}

export type Action = SetYgAction | ToggleActiveAction | SetYqAction;
