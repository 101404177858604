import { combineReducers } from 'redux';
// import allProjectsReducer from '../Project/All/Store/Reducers';
// import projectReducer from '../Project/Current/Store/Reducers';
import projectDetailsReducer from '../Inputs/ProjectDetails/Store/Reducers';
import designParamsReducer from '../Inputs/DesignParams/Store/Reducers';
import columnLoadsReducer from '../Inputs/ColumnLoads/Store/Reducers';
import padDetailsReducer from '../Inputs/PadDetails/Store/Reducers';
import columnDetailsReducer from '../Inputs/ColumnDetails/Store/Reducers';
import soilPropertiesReducer from '../Inputs/SoilProperties/Store/Reducers';
import loadCasesReducer from '../Inputs/LoadCases/Store/Reducers';

const rootReducer = combineReducers({
  // allProjects: allProjectsReducer,
  currentProject: combineReducers({
    // project: projectReducer,
    details: projectDetailsReducer,
    designParams: designParamsReducer,
    columnLoads: columnLoadsReducer,
    padDetails: padDetailsReducer,
    columnDetailS: columnDetailsReducer,
    soilProperties: soilPropertiesReducer,
    loadCases: loadCasesReducer,
  }),
});

export default rootReducer;
