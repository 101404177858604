import { Dispatch } from 'redux';

export const SET_DEFAULT = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_DEFAULT';
export const SET_LOADCASES = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_LOAD_CASES';

export const SET_CASE1_A = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE1_A';
export const SET_CASE1_B = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE1_B';
export const SET_CASE1_C = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE1_C';
export const SET_CASE1_D = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE1_D';

export const SET_CASE2_A = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE2_A';
export const SET_CASE2_B = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE2_B';
export const SET_CASE2_C = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE2_C';
export const SET_CASE2_D = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE2_D';

export const SET_CASE3_A = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE3_A';
export const SET_CASE3_B = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE3_B';
export const SET_CASE3_C = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE3_C';
export const SET_CASE3_D = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE3_D';

export const SET_CASE4_A = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE4_A';
export const SET_CASE4_B = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE4_B';
export const SET_CASE4_C = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE4_C';
export const SET_CASE4_D = 'CURRENT_PROJECT/LOADCOMBINATIONS/SET_CASE4_D';

export const TOGGLE_ACCORD = 'CURRENT_PROJECT/LOADCOMBINATIONS/TOGGLE_ACCORD';
export const RESET_STATE = 'CURRENT_PROJECT/LOADCOMBINATIONS/RESET_STATE';
export const FETCH = 'CURRENT_PROJECT/LOADCOMBINATIONS/FETCH';

interface set_default {
  type: typeof SET_DEFAULT;
  payload: boolean;
}
interface set_loadcases {
  type: typeof SET_LOADCASES;
  payload: number;
}
interface SetCase1_A {
  type: typeof SET_CASE1_A;
  payload: boolean;
}
interface SetCase1_B {
  type: typeof SET_CASE1_B;
  payload: boolean;
}
interface SetCase1_C {
  type: typeof SET_CASE1_C;
  payload: boolean;
}
interface SetCase1_D {
  type: typeof SET_CASE1_D;
  payload: boolean;
}

interface SetCase2_A {
  type: typeof SET_CASE2_A;
  payload: boolean;
}
interface SetCase2_B {
  type: typeof SET_CASE2_B;
  payload: boolean;
}
interface SetCase2_C {
  type: typeof SET_CASE2_C;
  payload: boolean;
}
interface SetCase2_D {
  type: typeof SET_CASE2_D;
  payload: boolean;
}

interface SetCase3_A {
  type: typeof SET_CASE3_A;
  payload: boolean;
}
interface SetCase3_B {
  type: typeof SET_CASE3_B;
  payload: boolean;
}
interface SetCase3_C {
  type: typeof SET_CASE3_C;
  payload: boolean;
}
interface SetCase3_D {
  type: typeof SET_CASE3_D;
  payload: boolean;
}

interface SetCase4_A {
  type: typeof SET_CASE4_A;
  payload: boolean;
}
interface SetCase4_B {
  type: typeof SET_CASE4_B;
  payload: boolean;
}
interface SetCase4_C {
  type: typeof SET_CASE4_C;
  payload: boolean;
}
interface SetCase4_D {
  type: typeof SET_CASE4_D;
  payload: boolean;
}
interface ToggleActiveAction {
  type: typeof TOGGLE_ACCORD;
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

export const fetchLoadCases = () => {
  return (dispatch: Dispatch) => {
    //@ts-ignore
    const json = JSON.parse(localStorage.getItem('inputs'));
    //@ts-ignore
    //Case 1
    dispatch({
      type: SET_CASE1_A,
      payload: json.case1_a ?? false,
    });
    dispatch({
      type: SET_CASE1_B,
      payload: json.case1_b ?? false,
    });
    dispatch({
      type: SET_CASE1_C,
      payload: json.case1_c ?? false,
    });
    dispatch({ type: SET_CASE1_D, payload: json.case1_d ?? false });
    //Case 2
    dispatch({
      type: SET_CASE2_A,
      payload: json.case2_a ?? false,
    });
    dispatch({
      type: SET_CASE2_B,
      payload: json.case2_b ?? false,
    });
    dispatch({
      type: SET_CASE2_C,
      payload: json.case2_c ?? false,
    });
    dispatch({ type: SET_CASE2_D, payload: json.case2_d ?? false });
    //Case 3
    dispatch({
      type: SET_CASE3_A,
      payload: json.case3_a ?? false,
    });
    dispatch({
      type: SET_CASE3_B,
      payload: json.case3_b ?? false,
    });
    dispatch({
      type: SET_CASE3_C,
      payload: json.case3_c ?? false,
    });
    dispatch({ type: SET_CASE3_D, payload: json.case3_d ?? false });
    //Case 4
    dispatch({
      type: SET_CASE4_A,
      payload: json.case4_a ?? false,
    });
    dispatch({
      type: SET_CASE4_B,
      payload: json.case4_b ?? false,
    });
    dispatch({
      type: SET_CASE4_C,
      payload: json.case4_c ?? false,
    });
    dispatch({ type: SET_CASE4_D, payload: json.case4_d ?? false });
    return json;
  };
};

export type Action =
  | set_default
  | set_loadcases
  | SetCase1_A
  | SetCase1_B
  | SetCase1_C
  | SetCase1_D
  | SetCase2_A
  | SetCase2_B
  | SetCase2_C
  | SetCase2_D
  | SetCase3_A
  | SetCase3_B
  | SetCase3_C
  | SetCase3_D
  | SetCase4_A
  | SetCase4_B
  | SetCase4_C
  | SetCase4_D
  | ToggleActiveAction
  | ResetStateAction;
