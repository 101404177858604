import React from 'react';
import { nameSolver } from '../../Calculations/LoadCombinations';
import Props from './Props';

const View: React.FC<Props> = (props: Props) => {
  const CaseNameOne = nameSolver(
    props.case1_a,
    props.case1_b,
    props.case1_c,
    props.case1_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  const CaseNameTwo = nameSolver(
    props.case2_a,
    props.case2_b,
    props.case2_c,
    props.case2_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  const CaseNameThree = nameSolver(
    props.case3_a,
    props.case3_b,
    props.case3_c,
    props.case3_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  const CaseNameFour = nameSolver(
    props.case4_a,
    props.case4_b,
    props.case4_c,
    props.case4_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  return (
    <div>
      {CaseNameOne.length ? (
        <table className="table-fixed w-full border-collapse mt-2 ">
          <thead>
            <tr className="border border-gray-300">
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
                Load Combinations
              </th>
              <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
                Combination Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border border-gray-300">
              <td className="w-1/5 pl-2 p-1.5">Combination 1</td>
              <td className="w-1/5 pl-2 p-1.5">{CaseNameOne}</td>
            </tr>
            {CaseNameTwo.length && CaseNameOne.length ? (
              <>
                <tr className="border border-gray-300">
                  <td className="w-1/5 pl-2 p-1.5">Combination 2</td>
                  <td className="w-1/5 pl-2 p-1.5">{CaseNameTwo}</td>
                </tr>
              </>
            ) : null}
            {CaseNameThree.length &&
            CaseNameTwo.length &&
            CaseNameOne.length ? (
              <>
                <tr className="border border-gray-300">
                  <td className="w-1/5 pl-2 p-1.5">Combination 3</td>
                  <td className="w-1/5 pl-2 p-1.5">{CaseNameThree}</td>
                </tr>
              </>
            ) : null}
            {CaseNameFour.length &&
            CaseNameThree.length &&
            CaseNameTwo.length &&
            CaseNameOne.length ? (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">Combination 4</td>
                <td className="w-1/5 pl-2 p-1.5">{CaseNameFour}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default View;

export interface props extends Props {
  caseOneName: string;
}
