import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Index';
import View from './View';
// // import { SET_ID } from './Store/Actions';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  // setId: (n: number | undefined) => ({ type: SET_ID, payload: n }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(View);
