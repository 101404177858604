import { connect } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getDesignParams } from './Store/Selectors';
import View from './View';
import Props from './Props';

const mapStateToProps = (state: RootState, ownProps: Props): Props => {
  return {
    ...getDesignParams(state),
    ...ownProps,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(View);
