import React from 'react';
import './CheckboxField.css';

const CheckboxField: React.FC<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <div className="mt-2">
        {props.loadCase} &nbsp;
        <label>
          <input
            checked={props.checked}
            type="checkbox"
            onChange={props.onChange}
            required
          />
          <span></span>
        </label>
      </div>
    </React.Fragment>
  );
};

export interface Props {
  loadCase: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export default CheckboxField;
