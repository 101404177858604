import React from 'react';

const InputField: React.FC<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <label className="block font-semibold mt-3">{props.label}</label>
      <input
        className="border-b-2 border-gray-200 rounded-md pb-1 w-full focus:outline-none focus:border-green-300"
        value={props.value}
        type="text"
        onChange={props.onChange}
        required
      />
    </React.Fragment>
  );
};

export interface Props {
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

}

export default InputField;
