import ReportDetails from '../Core/ReportDetails';
import TableHeader from '../Core/TableHeader';
import ReportTable from '../Core/ReportTable';
import { nameSolver } from '../Calculations/LoadCombinations';
import './ReportTable.css';

const SlidingReport = (props) => {
  const slidingData = props.slidingData;
  const columnLoads = props.columnLoads;
  const loadCases = props.loadCases;

  const Results = {
    criticalBearing: props.Results.criticalBearing,
    windUplift: props.Results.windUplift,
    UpliftResistance: props.Results.upliftResistance,
    criticalSliding_X: props.Results.criticalSliding_X,
    criticalSliding_Y: props.Results.criticalSliding_Y,
    upliftResistance: props.Results.upliftResistance,
    critSlidingX: props.Results.critSlidingX,
    critSlidingY: props.Results.critSlidingY,
  };

  const CaseNameOne = nameSolver(
    loadCases.case1_a,
    loadCases.case1_b,
    loadCases.case1_c,
    loadCases.case1_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  const CaseNameTwo = nameSolver(
    loadCases.case2_a,
    loadCases.case2_b,
    loadCases.case2_c,
    loadCases.case2_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  const CaseNameThree = nameSolver(
    loadCases.case3_a,
    loadCases.case3_b,
    loadCases.case3_c,
    loadCases.case3_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  const CaseNameFour = nameSolver(
    loadCases.case4_a,
    loadCases.case4_b,
    loadCases.case4_c,
    loadCases.case4_d,
    columnLoads.caseOneName,
    columnLoads.caseTwoName,
    columnLoads.caseThreeName,
    columnLoads.caseFourName
  );

  return (
    <div>
      {slidingData.hf_x &&
      slidingData.hf_y &&
      slidingData.hfs_1 &&
      slidingData.hfs_2 &&
      (Results.critSlidingX.maxSliding || Results.critSlidingY.maxSliding) ? (
        /* Sliding Report Calcs */
        <div style={{ marginLeft: '10px' }}>
          <div>
            <h2 className="fs-525 fw-800 top-margin underline">
              Sliding Resistance Calculations
            </h2>
            <ReportTable>
              <TableHeader title="Sliding Design Parameters" />
              <ReportDetails
                displayCheck="true"
                calcName="Passive Coefficent"
                formula={<>kp = (1 + sin(φ)) / (1 - sin(φ)) = &nbsp;</>}
                answer={slidingData.k}
              />
            </ReportTable>

            <ReportTable>
              <TableHeader title="Sliding Resistance - X Axis" />

              <ReportDetails
                displayCheck="true"
                calcName="Friction on Base"
                formula={
                  <>
                    F<sub>fric</sub> = G<sub>k</sub> * tan(φ) = &nbsp;
                  </>
                }
                answer={`${slidingData.friction.toFixed(2)} kN`}
              />
              <ReportDetails
                displayCheck="true"
                calcName="Passive due to Soil"
                formula={
                  <>
                    H<sub>fs</sub> = kp * (Y<sub>s</sub> * d<sub>found</sub> + Y
                    <sub>s</sub>* (d<sub>found</sub> + d)) = &nbsp;
                  </>
                }
                answer={
                  <>
                    {(slidingData.hfs_1 + slidingData.hfs_2).toFixed(2)} kN/m
                    <sup>2</sup>
                  </>
                }
              />
              <ReportDetails
                displayCheck="true"
                calcName="Sliding Resistance - X Axis"
                formula={
                  <>
                    H<sub>fx</sub> = ((H<sub>fs</sub> * d * L) / 2) + F
                    <sub>fric</sub> = &nbsp;
                  </>
                }
                answer={`${(slidingData.hf_x + slidingData.friction).toFixed(
                  2
                )} kN`}
              />
            </ReportTable>

            <ReportTable>
              <TableHeader title="Sliding Resistance - Y Axis" />

              <ReportDetails
                displayCheck="true"
                calcName="Friction on Base"
                formula={
                  <>
                    F<sub>fric</sub> = G<sub>k</sub> * tan(φ) = &nbsp;
                  </>
                }
                answer={`${slidingData.friction.toFixed(2)} kN`}
              />
              <ReportDetails
                displayCheck="true"
                calcName="Passive due to Soil"
                formula={
                  <>
                    H<sub>fs</sub> = kp * (Y<sub>s</sub> * d<sub>found</sub> + Y
                    <sub>s</sub> * (d<sub>found</sub> + d)) = &nbsp;
                  </>
                }
                answer={
                  <>
                    {(slidingData.hfs_1 + slidingData.hfs_2).toFixed(2)} kN/m
                    <sup>2</sup>
                  </>
                }
              />
              <ReportDetails
                displayCheck="true"
                calcName="Sliding Resistance - Y Axis"
                formula={
                  <>
                    H<sub>fy</sub> = ((H<sub>fs</sub> * d * W) / 2) + F
                    <sub>fric</sub> = &nbsp;
                  </>
                }
                answer={`${(slidingData.hf_y + slidingData.friction).toFixed(
                  2
                )} kN`}
              />
            </ReportTable>

            {CaseNameOne ? (
              <div>
                <h2 className="font-bold mt-2 text-lg">
                  {`Load Combination 1 - ${CaseNameOne}`}
                </h2>

                <ReportTable>
                  <TableHeader title="Total Horizontal Force" />
                  <ReportDetails
                    displayCheck={props.slidingData.case1hx}
                    calcName="Horizontal Forces - X Direction"
                    formula={
                      <>
                        F<sub>x</sub> = G<sub>hx</sub> * Y<sub>g,u</sub> + Q
                        <sub>hx</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case1hx.toFixed(2)} kN`}
                  />
                  <ReportDetails
                    displayCheck={props.slidingData.case1hy}
                    calcName="Horizontal Forces - Y Direction"
                    formula={
                      <>
                        F<sub>y</sub> = G<sub>hy</sub> * Y<sub>g,u</sub> + Q
                        <sub>hy</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case1hy.toFixed(2)} kN`}
                  />
                </ReportTable>
              </div>
            ) : null}

            {CaseNameTwo ? (
              <div>
                <h2 className="font-bold mt-2 text-lg">
                  {`Load Combination 2 - ${CaseNameTwo}`}
                </h2>

                <ReportTable>
                  <TableHeader title="Total Horizontal Force" />
                  <ReportDetails
                    displayCheck={props.slidingData.case2hx}
                    calcName="Horizontal Forces - X Direction"
                    formula={
                      <>
                        F<sub>x</sub> = G<sub>hx</sub> * Y<sub>g,u</sub> + Q
                        <sub>hx</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case2hx.toFixed(2)} kN`}
                  />
                  <ReportDetails
                    displayCheck={props.slidingData.case2hy}
                    calcName="Horizontal Forces - Y Direction"
                    formula={
                      <>
                        F<sub>y</sub> = G<sub>hy</sub> * Y<sub>g,u</sub> + Q
                        <sub>hy</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case2hy.toFixed(2)} kN`}
                  />
                </ReportTable>
              </div>
            ) : null}

            {CaseNameThree ? (
              <div>
                <h2 className="font-bold mt-2 text-lg">
                  {`Load Combination 3 - ${CaseNameThree}`}
                </h2>

                <ReportTable>
                  <TableHeader title="Total Horizontal Force" />
                  <ReportDetails
                    displayCheck={props.slidingData.case3hx}
                    calcName="Horizontal Forces - X Direction"
                    formula={
                      <>
                        F<sub>x</sub> = G<sub>hx</sub> * Y<sub>g,u</sub> + Q
                        <sub>hx</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case3hx.toFixed(2)} kN`}
                  />
                  <ReportDetails
                    displayCheck={props.slidingData.case3hy}
                    calcName="Horizontal Forces - Y Direction"
                    formula={
                      <>
                        F<sub>y</sub> = G<sub>hy</sub> * Y<sub>g,u</sub> + Q
                        <sub>hy</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case3hy.toFixed(2)} kN`}
                  />
                </ReportTable>
              </div>
            ) : null}

            {CaseNameFour ? (
              <div>
                <h2 className="font-bold mt-2 text-lg">
                  {`Load Combination 4 - ${CaseNameFour}`}
                </h2>

                <ReportTable>
                  <TableHeader title="Total Horizontal Force" />
                  <ReportDetails
                    displayCheck={props.slidingData.case4hx}
                    calcName="Horizontal Forces - X Direction"
                    formula={
                      <>
                        F<sub>x</sub> = G<sub>hx</sub> * Y<sub>g,u</sub> + Q
                        <sub>hx</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case4hx.toFixed(2)} kN`}
                  />
                  <ReportDetails
                    displayCheck={props.slidingData.case4hy}
                    calcName="Horizontal Forces - Y Direction"
                    formula={
                      <>
                        F<sub>y</sub> = G<sub>hy</sub> * Y<sub>g,u</sub> + Q
                        <sub>hy</sub> * Y<sub>q,u</sub> = &nbsp;
                      </>
                    }
                    answer={`${props.slidingData.case4hy.toFixed(2)} kN`}
                  />
                </ReportTable>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SlidingReport;
