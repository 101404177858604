import { Dispatch } from 'redux';

export const SET_NAME = 'CURRENT_PROJECT/DETAILS/SET_NAME';
export const SET_CALCULATION_TITLE =
  'CURRENT_PROJECT/DETAILS/SET_CALCULATION_TITLE';
export const SET_PROJECT_NUMBER = 'CURRENT_PROJECT/DETAILS/SET_PROJECT_NUMBER';
export const SET_DONE_BY = 'CURRENT_PROJECT/DETAILS/SET_DONE_BY';
export const SET_CHECKED_BY = 'CURRENT_PROJECT/DETAILS/SET_CHECKED_BY';
export const SET_APPROVED_BY = 'CURRENT_PROJECT/DETAILS/SET_APPROVED_BY';
export const SET_PROJECT_DATE = 'CURRENT_PROJECT/DETAILS/SET_PROJECT_DATE';
export const RESET_STATE = 'CURRENT_PROJECT/DETAILS/RESET_STATE';
export const SET_CREATED_BY = 'CURRENT_PROJECT/DETAILS/CREATED_BY';
export const FETCH = 'CURRENT_PROJECT/DETAILS/FETCH';
export const SET_CALC_ID = 'CURRENT_PROJECT/DETAILS/SET_CALC_ID';

interface SetCalcId {
  type: typeof SET_CALC_ID;
  payload: string;
}
interface SetNameAction {
  type: typeof SET_NAME;
  payload: string;
}
interface SetCalculationTitleAction {
  type: typeof SET_CALCULATION_TITLE;
  payload: string;
}
interface SetProjectNumberAction {
  type: typeof SET_PROJECT_NUMBER;
  payload: string;
}
interface SetDoneByAction {
  type: typeof SET_DONE_BY;
  payload: string;
}
interface SetCheckedByAction {
  type: typeof SET_CHECKED_BY;
  payload: string;
}
interface SetApprovedByAction {
  type: typeof SET_APPROVED_BY;
  payload: string;
}
interface SetProjectDateAction {
  type: typeof SET_PROJECT_DATE;
  payload: string;
}
interface SetCreatedBy {
  type: typeof SET_CREATED_BY;
  payload: string;
}
interface ResetStateAction {
  type: typeof RESET_STATE;
}

export const fetchProjectDetails = () => {
  return (dispatch: Dispatch) => {
    //@ts-ignore
    const json = JSON.parse(localStorage.getItem('inputs'));
    //@ts-ignore
    dispatch({ type: SET_NAME, payload: json.project_name ?? '' });
    dispatch({
      type: SET_CALCULATION_TITLE,
      payload: json.calculation_title ?? '',
    });
    dispatch({
      type: SET_PROJECT_NUMBER,
      payload: json.project_number ?? '',
    });
    dispatch({ type: SET_DONE_BY, payload: json.done_by ?? '' });
    dispatch({ type: SET_CHECKED_BY, payload: json.checked_by ?? '' });
    dispatch({ type: SET_APPROVED_BY, payload: json.approved_by ?? '' });
    dispatch({
      type: SET_PROJECT_DATE,
      payload: json.project_date ?? '',
    });
    dispatch({ type: SET_CREATED_BY, payload: json.created_by ?? '' });
    return json;
  };
};

export type Action =
  | SetCalcId
  | SetNameAction
  | SetCalculationTitleAction
  | SetProjectNumberAction
  | SetDoneByAction
  | SetCheckedByAction
  | SetApprovedByAction
  | SetProjectDateAction
  | SetCreatedBy
  | ResetStateAction;
