import React from 'react';
import Props from './Props';

const View: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <table className="table-fixed w-full border-collapse mt-2 ">
        <thead>
          <tr className="border border-gray-300 ">
            <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">
              Column Details
            </th>
            <th className="p-1.5 text-left w-1/2 bg-gray-100 pl-2">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border border-gray-300 ">
            <td className="w-1/5 pl-2 p-1.5">
              L<sub>bp</sub> x W<sub>bp</sub> - Baseplate Size
            </td>
            <td className="w-1/5 pl-2 p-1.5">
              {props.baseplateLength} x {props.baseplateWidth} mm
            </td>
          </tr>
          {!props.eccX ||
            (props.eccX !== '0' && (
              <tr className="border border-gray-300 ">
                <td className="w-1/5 pl-2 p-1.5">
                  Ecc<sub>x</sub> - X offset
                </td>
                <td className="w-1/5 pl-2 p-1.5">{props.eccX} mm</td>
              </tr>
            ))}
          {!props.eccY ||
            (props.eccY !== '0' && (
              <tr className="border border-gray-300">
                <td className="w-1/5 pl-2 p-1.5">
                  Ecc<sub>y</sub> - Y offset
                </td>
                <td className="w-1/5 pl-2 p-1.5">{props.eccY} mm</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default View;
