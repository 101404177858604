import React, { useEffect } from 'react';
import { PropsFromRedux } from './FormContainer';
import { InputField, AccordianForm } from '../../Core';

import styled from 'styled-components';

const StyledNote = styled.p`
  font-weight: bold;
  margin-top: 10px;
`;

const ColumnDetailsInput: React.FC<Props> = (PropsFromRedux: Props) => {
  // const [change, setChange] = useState(false);

  useEffect(() => {
    const { fetchColumnProperties, resetReduxState } = PropsFromRedux;
    const previousProps = PropsFromRedux;
    //@ts-ignore
    const data = JSON.parse(localStorage.getItem('inputs'));
    if (data) {
      if (previousProps.baseplateLength !== data.baseplateLength) {
        fetchColumnProperties();
        // setChange(!change);
      } else {
        resetReduxState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {}, [[change]]);

  const props = { ...PropsFromRedux };

  const handleBaseplateLengthInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setBpLength(e.target.value);
  };
  const handleBaseplateWidthInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setBpWidth(e.target.value);
  };
  const handleEccXInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEccX(e.target.value);
  };
  const handleEccYInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEccY(e.target.value);
  };

  return (
    <AccordianForm
      title="Column Properties"
      children={
        <React.Fragment>
          <InputField
            label="Baseplate Length"
            value={props.baseplateLength}
            onChange={handleBaseplateLengthInput}
          />
          <InputField
            label="Baseplate Width"
            value={props.baseplateWidth}
            onChange={handleBaseplateWidthInput}
          />
          <StyledNote>
            Note - Eccentricity is relevative about the centre of the column
          </StyledNote>
          <InputField
            label="Eccentricty X"
            value={props.eccX}
            onChange={handleEccXInput}
          />
          <InputField
            label="Eccentricity Y"
            value={props.eccY}
            onChange={handleEccYInput}
          />
        </React.Fragment>
      }
    />
  );
};

export interface Props extends PropsFromRedux {}

export interface State {}

export default ColumnDetailsInput;
