import State from './State';
import {
  Action,
  SET_CONCRETE_DENSITY,
  SET_PAD_LENGTH,
  SET_PAD_WIDTH,
  SET_PAD_DEPTH,
  SET_DEAD_SURCHARGE,
  SET_IMPOSED_SURCHARGE,
  RESET_STATE,
  TOGGLE_ACCORD,
} from './Actions';

const initialState: State = {
  concreteDensity: '25',
  padLength: '2',
  padWidth: '2',
  padDepth: '1',
  deadSurcharge: '',
  imposedSurcharge: '',
  padInputActive: false,
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_CONCRETE_DENSITY: {
      return {
        ...state,
        concreteDensity: action.payload,
      };
    }
    case SET_PAD_LENGTH: {
      return {
        ...state,
        padLength: action.payload,
      };
    }
    case SET_PAD_WIDTH: {
      return {
        ...state,
        padWidth: action.payload,
      };
    }
    case SET_PAD_DEPTH: {
      return {
        ...state,
        padDepth: action.payload,
      };
    }
    case SET_DEAD_SURCHARGE: {
      return {
        ...state,
        deadSurcharge: action.payload,
      };
    }
    case SET_IMPOSED_SURCHARGE: {
      return {
        ...state,
        imposedSurcharge: action.payload,
      };
    }
    case TOGGLE_ACCORD: {
      return {
        ...state,
        padInputActive: !state.padInputActive,
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
