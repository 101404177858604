import { React } from 'react';
import { Link } from 'react-router-dom';
import logo from './newlogo.png';
import './header.css';

const Header = () => {
  return (
    <>
      <div className="flex align-items justify-between header-text header-border">
        <Link to="/">
          <img src={logo} className="logo-size" alt="subsolve" />
        </Link>

        <div>
          {/* User is NOT logged in. Mobile Display Nav*/}

          <div>
            <div className=" dropdown">
              <button type="button" className="show-hamburger">
                <div className="hamburger-icon"></div>
                <div className="hamburger-icon"></div>
                <div className="hamburger-icon"></div>
              </button>
              <div className="dropdown-menu dropdown-transition">
                <div className="bg-white fs-500 dropdown-box">
                  <div className="dropdown-items-padding">
                    <Link to="/massfill" className="hover-items left-margin">
                      Calculations
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* User is NOT logged in. Full Display Nav*/}
          <div className="show-header-items">
            {/* <Link to="login" className="hover-items left-margin"> */}
            <Link to="/massfill" className="hover-items left-margin">
              Calculations
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
