import React from 'react';
import { View as DesignParamsSummary } from './Inputs/DesignParams';
import { View as ColumnLoadsSummary } from './Inputs/ColumnLoads';
import { View as PadDetailsSummary } from './Inputs/PadDetails';
import { View as ColumnDetailsSummary } from './Inputs/ColumnDetails';
import { View as SoilPropertiesSummary } from './Inputs/SoilProperties';
import { View as LoadCaseSummary } from './Inputs/LoadCases';

const InputSummary = () => {
  return (
    <div className="mt-4" style={{ marginLeft: '10px' }}>
      <SoilPropertiesSummary />
      <br></br>
      <ColumnDetailsSummary />
      <br></br>
      <PadDetailsSummary />
      <br></br>
      <ColumnLoadsSummary />
      <br></br>
      <LoadCaseSummary />
      <br></br>
      <DesignParamsSummary />
      <br></br>
    </div>
  );
};

export default InputSummary;
