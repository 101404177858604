import { Dispatch } from 'redux';

export const SET_SOIL_DENSITY =
  'CURRENT_PROJECT/SOIL_PROPERTIES/SET_SOIL_DENSITY';
export const SET_ANGLE_OF_FRICTION =
  'CURRENT_PROJECT/SOIL_PROPERTIES/SET_ANGLE_OF_FRICTION';
export const SET_ALLOWABLE_BP =
  'CURRENT_PROJECT/SOIL_PROPERTIES/SET_ALLOWABLE_BP';
export const SET_FOUND_DEPTH =
  'CURRENT_PROJECT/SOIL_PROPERTIES/SET_FOUND_DEPTH';
export const TOGGLE_ACCORD = 'CURRENT_PROJECT/SOIL_PROPERTIES/TOGGLE_ACCORD';
export const RESET_STATE = 'CURRENT_PROJECT/SOIL_PROPERTIES/RESET_STATE';
export const FETCH = 'CURRENT_PROJECT/SOIL_PROPERTIES/FETCH';

interface SetSoilDensityAction {
  type: typeof SET_SOIL_DENSITY;
  payload: string;
}
interface SetAngleOfFictionAction {
  type: typeof SET_ANGLE_OF_FRICTION;
  payload: string;
}
interface SetAllowableBPAction {
  type: typeof SET_ALLOWABLE_BP;
  payload: string;
}
interface SetFoundDepthAction {
  type: typeof SET_FOUND_DEPTH;
  payload: string;
}

interface ToggleActiveAction {
  type: typeof TOGGLE_ACCORD;
}
interface ResetStateAction {
  type: typeof RESET_STATE;
}

export const fetchSoilProperties = () => {
  return (dispatch: Dispatch) => {
    //@ts-ignore
    const json = JSON.parse(localStorage.getItem('inputs'));
    //@ts-ignore
    dispatch({ type: SET_SOIL_DENSITY, payload: json.soil_density ?? '' });
    dispatch({
      type: SET_ANGLE_OF_FRICTION,
      payload: json.angleoffriction ?? '',
    });
    dispatch({
      type: SET_ALLOWABLE_BP,
      payload: json.allowable_bearing ?? '',
    });
    dispatch({ type: SET_FOUND_DEPTH, payload: json.found_depth ?? '' });
    return json;
  };
};

export type Action =
  | SetSoilDensityAction
  | SetAngleOfFictionAction
  | SetAllowableBPAction
  | SetFoundDepthAction
  | ToggleActiveAction
  | ResetStateAction;
