import React from 'react';

const UpwardAccordianComponent: React.FC<Props> = (props: Props) => {
  return (
    <div className="flex flex-row cursor-pointer">
      <svg
        className="mt-5"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 24 24"
      >
        <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
      </svg>
      <div
        className="p-3 font-semibold"
        onClick={props.onClick}
        children={props.label}
      />
    </div>
  );
};

export interface Props {
  label: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

export default UpwardAccordianComponent;
