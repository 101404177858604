import React, { useEffect } from 'react';
import './Form.css';

import { PropsFromRedux } from './FormContainer';
import { AccordianForm } from '../../Core';
import CheckboxField from '../../Core/CheckboxField';
import MiniAccordianForm from '../../Core/MiniAccordianForm';
import { nameSolver } from '../../Calculations/LoadCombinations';

const LoadCasesInput: React.FC<Props> = (PropsFromRedux: Props) => {
  // const [change, setChange] = useState(false);

  useEffect(() => {
    const { fetchLoadCases, resetReduxState } = PropsFromRedux;
    const previousProps = PropsFromRedux;
    //@ts-ignore
    const data = JSON.parse(localStorage.getItem('inputs'));
    if (data) {
      if (previousProps.case1_a !== data.case1_a) {
        fetchLoadCases();
        // setChange(!change);
      } else {
        resetReduxState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = { ...PropsFromRedux };

  const loadCaseTypes = {
    case1: props.caseOneName,
    case2: props.caseTwoName,
    case3: props.caseThreeName,
    case4: props.caseFourName,
  };

  const handleCase1_a = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case1_a(e.target.checked);
  };
  const handleCase1_b = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case1_b(e.target.checked);
  };

  const handleCase1_c = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case1_c(e.target.checked);
  };
  const handleCase1_d = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case1_d(e.target.checked);
  };

  const handleCase2_a = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case2_a(e.target.checked);
  };
  const handleCase2_b = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case2_b(e.target.checked);
  };
  const handleCase2_c = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case2_c(e.target.checked);
  };
  const handleCase2_d = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case2_d(e.target.checked);
  };

  const handleCase3_a = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case3_a(e.target.checked);
  };
  const handleCase3_b = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case3_b(e.target.checked);
  };
  const handleCase3_c = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case3_c(e.target.checked);
  };
  const handleCase3_d = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case3_d(e.target.checked);
  };

  const handleCase4_a = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case4_a(e.target.checked);
  };
  const handleCase4_b = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case4_b(e.target.checked);
  };
  const handleCase4_c = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case4_c(e.target.checked);
  };
  const handleCase4_d = (e: React.ChangeEvent<HTMLInputElement>) => {
    props._case4_d(e.target.checked);
  };

  const CaseNameOne = nameSolver(
    props.case1_a,
    props.case1_b,
    props.case1_c,
    props.case1_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  const CaseNameTwo = nameSolver(
    props.case2_a,
    props.case2_b,
    props.case2_c,
    props.case2_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  const CaseNameThree = nameSolver(
    props.case3_a,
    props.case3_b,
    props.case3_c,
    props.case3_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  const CaseNameFour = nameSolver(
    props.case4_a,
    props.case4_b,
    props.case4_c,
    props.case4_d,
    props.caseOneName,
    props.caseTwoName,
    props.caseThreeName,
    props.caseFourName
  );

  return (
    <div>
      <AccordianForm
        title="Load Combinations
        "
        children={
          <React.Fragment>
            <div>
              <div>
                <MiniAccordianForm
                  title={CaseNameOne ? CaseNameOne : 'Combination 1'}
                  children={
                    <div>
                      {props.caseOneName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case1}
                            checked={props.case1_a}
                            onChange={handleCase1_a}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseTwoName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case2}
                            checked={props.case1_b}
                            onChange={handleCase1_b}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseThreeName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case3}
                            checked={props.case1_c}
                            onChange={handleCase1_c}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseFourName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case4}
                            checked={props.case1_d}
                            onChange={handleCase1_d}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                />

                <MiniAccordianForm
                  title={CaseNameTwo ? CaseNameTwo : 'Combination 2'}
                  children={
                    <div>
                      {props.caseOneName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case1}
                            checked={props.case2_a}
                            onChange={handleCase2_a}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseTwoName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case2}
                            checked={props.case2_b}
                            onChange={handleCase2_b}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseThreeName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case3}
                            checked={props.case2_c}
                            onChange={handleCase2_c}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseFourName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case4}
                            checked={props.case2_d}
                            onChange={handleCase2_d}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                />

                <MiniAccordianForm
                  title={CaseNameThree ? CaseNameThree : 'Combination 3'}
                  children={
                    <div>
                      {props.caseOneName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case1}
                            checked={props.case3_a}
                            onChange={handleCase3_a}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseTwoName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case2}
                            checked={props.case3_b}
                            onChange={handleCase3_b}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseThreeName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case3}
                            checked={props.case3_c}
                            onChange={handleCase3_c}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseFourName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case4}
                            checked={props.case3_d}
                            onChange={handleCase3_d}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                />

                <MiniAccordianForm
                  title={CaseNameFour ? CaseNameFour : 'Combination 4'}
                  children={
                    <div>
                      {props.caseOneName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case1}
                            checked={props.case4_a}
                            onChange={handleCase4_a}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseTwoName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case2}
                            checked={props.case4_b}
                            onChange={handleCase4_b}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseThreeName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case3}
                            checked={props.case4_c}
                            onChange={handleCase4_c}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {props.caseFourName ? (
                        <>
                          <CheckboxField
                            loadCase={loadCaseTypes.case4}
                            checked={props.case4_d}
                            onChange={handleCase4_d}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </React.Fragment>
        }
      />
    </div>
  );
};

export interface Props extends PropsFromRedux {}

export interface State {
  loadCombinations: number;
}

export default LoadCasesInput;
