import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/Index';
import { getLoadCases } from './Store/Selectors';
// import { getProject } from '../../Project/Current/Store/Selectors';
import Form from './Form';
import {
  SET_DEFAULT,
  SET_LOADCASES,
  SET_CASE1_A,
  SET_CASE1_B,
  SET_CASE1_C,
  SET_CASE1_D,
  SET_CASE2_A,
  SET_CASE2_B,
  SET_CASE2_C,
  SET_CASE2_D,
  SET_CASE3_A,
  SET_CASE3_B,
  SET_CASE3_C,
  SET_CASE3_D,
  SET_CASE4_A,
  SET_CASE4_B,
  SET_CASE4_C,
  SET_CASE4_D,
  TOGGLE_ACCORD,
  RESET_STATE,
  fetchLoadCases,
} from './Store/Actions';
import { getColumnLoads } from '../ColumnLoads/Store/Selectors';

const mapStateToProps = (state: RootState) => ({
  ...getLoadCases(state),
  ...getColumnLoads(state),
  // projectId: getProject(state).projectId,
});

const mapDispatchToProps = {
  _case_default: (n: boolean) => ({ type: SET_DEFAULT, payload: n }),
  _loadCases: (n: number) => ({ type: SET_LOADCASES, payload: n }),

  _case1_a: (n: boolean) => ({ type: SET_CASE1_A, payload: n }),
  _case1_b: (t: boolean) => ({ type: SET_CASE1_B, payload: t }),
  _case1_c: (n: boolean) => ({ type: SET_CASE1_C, payload: n }),
  _case1_d: (n: boolean) => ({ type: SET_CASE1_D, payload: n }),

  _case2_a: (n: boolean) => ({ type: SET_CASE2_A, payload: n }),
  _case2_b: (t: boolean) => ({ type: SET_CASE2_B, payload: t }),
  _case2_c: (n: boolean) => ({ type: SET_CASE2_C, payload: n }),
  _case2_d: (n: boolean) => ({ type: SET_CASE2_D, payload: n }),

  _case3_a: (n: boolean) => ({ type: SET_CASE3_A, payload: n }),
  _case3_b: (t: boolean) => ({ type: SET_CASE3_B, payload: t }),
  _case3_c: (n: boolean) => ({ type: SET_CASE3_C, payload: n }),
  _case3_d: (n: boolean) => ({ type: SET_CASE3_D, payload: n }),

  _case4_a: (n: boolean) => ({ type: SET_CASE4_A, payload: n }),
  _case4_b: (t: boolean) => ({ type: SET_CASE4_B, payload: t }),
  _case4_c: (n: boolean) => ({ type: SET_CASE4_C, payload: n }),
  _case4_d: (n: boolean) => ({ type: SET_CASE4_D, payload: n }),

  toggleActive: () => ({ type: TOGGLE_ACCORD }),
  resetReduxState: () => ({ type: RESET_STATE }),
  fetchLoadCases: fetchLoadCases,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Form);
