import React, { useEffect, useState } from 'react';
import {
  Form as ProjectDetailsInput,
  View as ProjectDetailsView,
} from '../../Inputs/ProjectDetails';
import { Form as SoilPropertiesInput } from '../../Inputs/SoilProperties';
import { Form as PadPropertiesInput } from '../../Inputs/PadDetails';
import { Form as ColumnLoadsInput } from '../../Inputs/ColumnLoads';
import { Form as ColumnPropertiesInput } from '../../Inputs/ColumnDetails';
import { Form as LoadCaseInput } from '../../Inputs/LoadCases';
import { View as Results } from '../../Results';
import { PropsFromRedux } from './ViewContainer';
import { View as ViewHelper } from '../../Helpers';
import './View.css';

const View: React.FC<Props> = (props: Props, state: State) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [width]);

  return (
    <div>
      <div className="pageborder"></div>
      <div className="noprint">
        <div className="mt-4 ml-4">
          <div className="flex flex-col md:absolute center-inputs pb-4">
            <ViewHelper />
            <ProjectDetailsInput />
            <SoilPropertiesInput />
            <PadPropertiesInput />
            <ColumnPropertiesInput />
            <ColumnLoadsInput />
            <LoadCaseInput />
          </div>
          <div className="pl-1 pr-1 md:ml-64 flex flex-col mr-4 print">
            <ProjectDetailsView />
            <Results />
          </div>
        </div>
      </div>
    </div>
  );
};

export interface Props extends PropsFromRedux {
  projectId?: number;
}

interface State {}

export default View;
