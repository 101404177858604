import State from './State';
import {
  Action,
  SET_BP_LENGTH,
  SET_BP_WIDTH,
  SET_ECCX,
  SET_ECCY,
  RESET_STATE,
  TOGGLE_ACCORD,
} from './Actions';

const initialState: State = {
  baseplateLength: '300',
  baseplateWidth: '300',
  eccX: '0',
  eccY: '0',
  columnInputActive: false,
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_BP_LENGTH: {
      return {
        ...state,
        baseplateLength: action.payload,
      };
    }
    case SET_BP_WIDTH: {
      return {
        ...state,
        baseplateWidth: action.payload,
      };
    }
    case SET_ECCX: {
      return {
        ...state,
        eccX: action.payload,
      };
    }
    case SET_ECCY: {
      return {
        ...state,
        eccY: action.payload,
      };
    }
    case TOGGLE_ACCORD: {
      return {
        ...state,
        columnInputActive: !state.columnInputActive,
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
