export const UpliftCondition = (
  case1Axial,
  caseNameOne,
  case2Axial,
  caseNameTwo,
  case3Axial,
  caseNameThree,
  case4Axial,
  caseNameFour
) => {
  if (case1Axial < 0) {
    return {
      case: caseNameOne,
      uplift: case1Axial,
    };
  } else if (case2Axial < 0) {
    return {
      case: caseNameTwo,
      uplift: case2Axial,
    };
  } else if (case3Axial < 0) {
    return {
      case: caseNameThree,
      uplift: case3Axial,
    };
  } else if (case4Axial < 0) {
    return {
      case: caseNameFour,
      uplift: case4Axial,
    };
  } else {
    return {
      case: 0,
      uplift: 0,
    };
  }
};

export const Uplift_Calculation = (swPad, swSoil, upliftCase, upliftValue) => {
  const upliftResistance = (swPad + swSoil) / 1.5;

  if (upliftValue && upliftResistance) {
    return {
      upliftResistance,
      case: upliftCase,
      uplift: upliftValue,
      unitRatio: Math.abs(upliftValue) / upliftResistance,
    };
  } else {
    return {
      upliftResistance: 0,
      case: 0,
      uplift: 0,
      unitRatio: 0,
    };
  }
};

/*   UPLIFT CHECKS
      USE DESIGN APPROACH 1
      NOT YET COMPLETE - NEED TO FIGURE IT OUT. 
      TYPICAL OFFICE PRACTICES ARE USED AS BELOW - PROBABLY SUFFICIENT
  */
